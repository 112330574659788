import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { TextField, Select, MenuItem } from '@mui/material';
import get from 'lodash/get';
import { CUSTOMISE_STEPS, MEASUREMENT_SCREEN_TYPE, OTHER_MEASUREMENTS_UNIT, MenuProps, HEIGHT_UNIT, WEIGHT_UNIT } from 'utils/constants';
import { getUnitMultiplicationFactor } from 'utils';
import BottomBar from 'components/BottomBar/BottomBar';
import FloatingButtonBar from 'components/FloatingButtonBar';
import Button from 'components/Button';
import BustMeasurementImg from 'assets/images/BustMeasurement.svg';
import AboveImg from 'assets/images/Above.svg';
import SlopedImg from 'assets/images/Sloped.svg';
import ShallowImg from 'assets/images/Shallow.svg';
import Regular from 'assets/images/Regular.svg';
import Wide from 'assets/images/Wide.svg';
import Narrow from 'assets/images/Narrow.svg';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import BackButton from 'components/BackButton';
import { PlayIcon } from '@heroicons/react/solid';
import { color } from 'framer-motion';

const MEASUREMENT_CONFIG = {
    underBust: {
        name: "Underbust",
        key: 'underBust',
        [OTHER_MEASUREMENTS_UNIT.CM]: {
            min: 60.96,
            max: 106.68,
        },
        [OTHER_MEASUREMENTS_UNIT.IN]: {
            min: 24,
            max: 42,
        }
    },
    overBust: {
        name: "Overbust",
        key: 'overBust',
        [OTHER_MEASUREMENTS_UNIT.CM]: {
            min: 76.2,
            max: 139.7,
        },
        [OTHER_MEASUREMENTS_UNIT.IN]: {
            min: 30,
            max: 55,
        }
    },
};

const STEP = CUSTOMISE_STEPS[MEASUREMENT_SCREEN_TYPE.BUST_MEASUREMENT];

const STEP_LABELS = {
    [STEP.BUST_MEASUREMENT]: {
        heading: '',
        text: "",
        icon: BustMeasurementImg,
        listItem: [],
    },
    [STEP.BUST_SHAPE_SELECTION]: {
        heading: "Select Your Bust Type",
        listItem: [{

            label: 'Fabulously Full',
            value: "A",
            img: AboveImg,
            text: 'The bust is fuller above the apex line and rounded at the bottom',
        }, {
            label: 'Bottom Happy',
            img: SlopedImg,
            value: "B",
            text: 'The bust is slopped till the apex level. And rounded at the bottom',
        }, {
            label: 'Bright and Perky',
            value: "C",
            img: ShallowImg,
            text: 'The bust is shallow at the top and slightly rounded just above the apex. ',
        }]
    },
    [STEP.BUST_PLACEMENT_SELECTION]: {
        heading: 'How are your breast placed?',
        text: "",
        listItem: [
            {
                value: 'A',
                label: 'Wide',
                img: Wide,
                text: 'My breasts are four fingers width apart',
            },
            {
                value: 'B',
                label: 'Regular',
                img: Regular,
                text: 'My breasts are one or two fingers width apart',
            }, {
                value: 'C',
                label: "Narrow",
                img: Narrow,
                text: 'My breasts are touching each other ',
            }]
    },
    // [STEP.SUMMARY]: {
    //     heading: 'Summary',
    //     icon: BustMeasurementImg,
    // },
}

const mapStateToProps = state => {
    const imageUrl = get(state, "avatarReducer.imageUrl", "");
    const selfie = get(state, "avatarReducer.selfie", "");
    const isDefaultAvatar = get(state, "avatarReducer.isDefaultAvatar", false);
    const defaultUserProfile = get(state, 'avatarReducer.defaultUserProfile');
    const uploadLoader = get(state, 'avatarReducer.uploadLoader', false);
    const product = get(state, "avatarReducer.product", {});
    const loadedScene = get(state, "avatarReducer.loadedScene");
    return {
        imageUrl,
        selfie,
        userProfile: get(state, "avatarReducer.userProfile", {}),
        isDefaultAvatar,
        defaultUserProfile,
        uploadLoader,
        product,
        loadedScene
    };
};

function BustMeasurement(props) {
    const rootStyles = getComputedStyle(document.body);
    const selectBG = rootStyles.getPropertyValue('--selectBG').trim();
    const textColor = rootStyles.getPropertyValue('--textColor').trim();
    const [step, setStep] = useState(STEP.BUST_MEASUREMENT);
    const [nextDisabled, setNextDisabled] = useState(false);
    const { actionType, backHandler, setActiveStepIndex, loader } = props;
    const { imageUrl, uploadLoader, userProfile, selfie, isDefaultAvatar, defaultUserProfile, product, loadedScene } = useSelector(mapStateToProps);
    const { userAvatar = {} } = isDefaultAvatar ? defaultUserProfile : userProfile;
    const { underBust, overBust, bustPosition, bustShape } = userAvatar;
    const [selectedBustShape, setSelectedBustShape] = useState(bustPosition ? STEP_LABELS[STEP.BUST_SHAPE_SELECTION].listItem.find(item => item.value === bustPosition) : STEP_LABELS[STEP.BUST_SHAPE_SELECTION].listItem[0]);
    const [selectedPlacement, setSelectedPlacement] = useState(bustShape ? STEP_LABELS[STEP.BUST_PLACEMENT_SELECTION].listItem.find(item => item.value === bustShape) : STEP_LABELS[STEP.BUST_PLACEMENT_SELECTION].listItem[0]);
    const defaultMeasurements = {
        underBust: {
            ...MEASUREMENT_CONFIG.underBust,
            unit: OTHER_MEASUREMENTS_UNIT.CM,
            value: underBust || 81.28,
            error: '',
        },
        overBust: {
            ...MEASUREMENT_CONFIG.overBust,
            unit: OTHER_MEASUREMENTS_UNIT.CM,
            value: overBust || 91.44,
            error: '',
        },
    };
    const [measurements, setMeasurements] = useState(defaultMeasurements);

    const checkFormValidity = useCallback(() => {
        let isInputValid = false;
        if (step === STEP.BUST_MEASUREMENT) {
            const { overBust, underBust } = measurements;
            isInputValid = !(overBust?.error || underBust?.error);
        }
        setNextDisabled(!isInputValid);
    }, [measurements, step]);

    useEffect(() => {
        if (measurements) {
            checkFormValidity(measurements);
        }
    }, [checkFormValidity, measurements])

    const onTextFieldChangeHandler = (val, key, error) => {
        if (!loader) {
            // setErrorMessage('');
            let updatedMeasurements = { ...measurements };
            updatedMeasurements[key].value = Number(val);
            updatedMeasurements[key].error = error;
            setMeasurements(updatedMeasurements);
        }
    };

    const onChangeHandler = (val, key, measurementObj) => {
        const { unit, name } = measurementObj;
        const { min, max } = measurementObj[unit];
        let error = '';
        if (val > max || val < min) {
            error = `${name} must be between ${min}${unit} to ${max}${unit}`;
        }
        onTextFieldChangeHandler(val, key, error);
    }

    const onUnitChange = (unit, key) => {
        if (!loader) {
            let updatedMeasurements = { ...measurements };
            const prevUnit = measurements[key].unit;
            updatedMeasurements[key].unit = unit;
            const multiplicationFactor = getUnitMultiplicationFactor(prevUnit);
            let updatedValue = Math.round(multiplicationFactor * updatedMeasurements[key].value);
            onChangeHandler(updatedValue, key, updatedMeasurements[key]);
        }
    }

    const nextHandler = () => {
        switch (step) {
            case STEP.BUST_MEASUREMENT:
                setStep(STEP.BUST_SHAPE_SELECTION);
                setActiveStepIndex(1);
                return;
            case STEP.BUST_SHAPE_SELECTION:
                setStep(STEP.BUST_PLACEMENT_SELECTION);
                setActiveStepIndex(2);
                return;
            case STEP.BUST_PLACEMENT_SELECTION:
                // setStep(STEP.SUMMARY);
                // setActiveStepIndex(3);
                const { gender, name = "", height, weight, userAvatar = {} } = defaultUserProfile;
                let measurementPayload = {};
                Object.keys(measurements).forEach(key => {
                    const { value, unit } = measurements[key];
                    const multiplicationFactor = (unit === OTHER_MEASUREMENTS_UNIT.IN || unit === HEIGHT_UNIT.IN || unit === WEIGHT_UNIT.LB) ? getUnitMultiplicationFactor(unit) : 1;
                    measurementPayload[key] = Math.round(value * multiplicationFactor);
                });
                const payload = {
                    ...measurementPayload,
                    height,
                    weight,
                    gender,
                    bustShape: selectedBustShape.value,
                    bustPosition: selectedPlacement.value,
                }
                props.nextHandler(payload);
                return;
            // case STEP.SUMMARY:
            //     props.nextHandler();
            //     return;
            default:
                return;
        }
    }

    const skipHandler = () => {
        switch (step) {
            case STEP.BUST_MEASUREMENT:
                backHandler();
                return;
            case STEP.BUST_SHAPE_SELECTION:
                setStep(STEP.BUST_MEASUREMENT);
                setActiveStepIndex(0);
                return;
            case STEP.BUST_PLACEMENT_SELECTION:
                setStep(STEP.BUST_SHAPE_SELECTION);
                setActiveStepIndex(1);
                return;
            // case STEP.SUMMARY:
            //     setStep(STEP.BUST_PLACEMENT_SELECTION);
            //     setActiveStepIndex(2);
            //     return;
            default:
                return;
        }
    }

    const renderSelection = (step, selectedItem, setSelectedItem) => {
        const { listItem = [], heading } = STEP_LABELS[step];
        return <>
            <div className='BustSelections'>
                <div className='BustSelections__HeadingContainer'>
                    <div style={{ padding: '0 1em', position: 'absolute', zIndex: 10 }}>
                        <BackButton onClick={() => {
                            MixPanel.buttonClicked(EVENT_CATEGORIES.SHAPE_SELECTION, EVENT_ACTIONS.BACK_BUTTON, { actionType });
                            skipHandler();
                        }} />
                    </div>
                    <div className='Dopplr_Customize__Heading'>{heading}</div>
                </div>
                <div className='BustSelections__ShapeContainer'>
                    {listItem.map(item => {
                        return <div className={`BustSelections__ShapeContainer__Shape ${item?.value === selectedItem?.value ? 'selected' : ''}`} key={item.value} onClick={() => {
                            MixPanel.buttonClicked(EVENT_CATEGORIES.BUST_SHAPE_SELECTION, EVENT_ACTIONS.SELECT_BUST_SHAPE, { selectedBustShape: item.value, actionType });
                            setSelectedItem(item)
                        }}>
                            <div className={`BustSelections__ShapeContainer__Img`}>
                                <img src={item.img} alt={item.value} />
                            </div>
                            <div className='BustSelections__ShapeContainer__Text'>{item.text}</div>
                        </div>
                    })}
                </div>
            </div>
            <FloatingButtonBar style={{ background: 'none' }}>
                <Button type='black-white' onClick={nextHandler} disabled={!selectedItem} isLoading={loader}>
                    Next
                </Button>
            </FloatingButtonBar>
        </>
    }

    const renderSteps = () => {
        switch (step) {
            case STEP.BUST_MEASUREMENT:
                return <div className='Dopplr_Customize__BustMeasurementContainer'>
                    <div style={{ padding: '0.5em 1em', zIndex: 1 }}>
                        <BackButton onClick={skipHandler} />
                    </div>
                    {heading && <div className='Dopplr_Customize__Title'>
                        <div className='Dopplr_Customize__Heading'>{heading}</div>
                        <div className='Dopplr_Customize__Text'>{text}</div>
                    </div>}
                    {icon && <img src={icon} alt="" className='Dopplr_Customize__BustMeasurementContainer__Img' />}
                    {false && <div className="Dopplr_Customize__Video">
                        <PlayIcon width={20} />
                        See the video to learn how to measure
                    </div>}
                    <BottomBar keyName={step} className='Measurements'
                        style={{ paddingBottom: '3em' }}>
                        {renderMeasurementFields()}
                        <FloatingButtonBar style={{ background: 'none' }}>
                            {/* {step !== STEP.HEIGHT_WEIGHT && <Button type='white-black' onClick={skipHandler}>
                        Don't Know?
                    </Button>} */}
                            <Button type='black-white' onClick={nextHandler} disabled={nextDisabled} isLoading={loader}>
                                Next
                            </Button>
                        </FloatingButtonBar>
                    </BottomBar>
                </div>
            case STEP.BUST_SHAPE_SELECTION:
                return renderSelection(STEP.BUST_SHAPE_SELECTION, selectedBustShape, setSelectedBustShape);
            case STEP.BUST_PLACEMENT_SELECTION:
                return renderSelection(STEP.BUST_PLACEMENT_SELECTION, selectedPlacement, setSelectedPlacement);
            // case STEP.SUMMARY:
            //     return renderSummary();
            default:
                return <div>Invalid</div>;
        }
    }

    const renderMeasurementFields = () => {
        const { underBust, overBust } = measurements;
        return <div style={{ padding: '1em' }}>
            {[underBust, overBust].map(measurement => {
                const { unit, value, key, name } = measurement;
                return <div className='Measurements__FormInput' key={key}>
                    <div style={{
                        display: 'flex',
                        flex: '1'
                    }}>
                        <TextField
                            label={name}
                            size="small"
                            type="number"
                            className='Measurements__TextField'
                            InputLabelProps={{
                                style : {
                                    color: textColor,
                                },
                            }}
                            sx={{
                                flex: 1,
                                '& .MuiInputBase-root': {
                                    marginRight: '1em',
                                    borderRadius: '1em',
                                },
                                '.MuiFormHelperText-root.Mui-error': {
                                    position: 'absolute',
                                    bottom: '-20px'
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: textColor,
                                },
                            }}
                            error={!!measurement.error}
                            helperText={measurement?.error || ''}
                            value={value || ''}
                            onChange={(e) => onChangeHandler(e.target.value, key, measurement)}
                        />
                        <Select
                            MenuProps={MenuProps}
                            className="Measurements__UnitSelect"
                            size={'small'}
                            value={unit}
                            onChange={(e) => onUnitChange(e.target.value, key)}
                            sx={{
                                background: selectBG,
                                width: 80,
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: textColor,
                                },
                            }}
                        >
                            {Object.values(OTHER_MEASUREMENTS_UNIT).map((value) => {
                                return <MenuItem key={value} value={value}>{value}</MenuItem>
                            })}
                        </Select>
                    </div>
                </div>
            })}
        </div>
    }

    const { heading, text, icon } = STEP_LABELS[step];
    return renderSteps();
}

export default BustMeasurement
