import { Axis, Space } from "@babylonjs/core";
import { Vector3, StandardMaterial, Color3 } from "@babylonjs/core/Legacy/legacy";
import { GARMENT_TYPE } from "utils/constants";

export function rotateBone(skeleton, boneName, vector) {
    const bone = skeleton.bones[skeleton.getBoneIndexByName(boneName)];
    if (bone) {
        const node = bone.getTransformNode();
        node.rotate(Axis.X, vector.x * Math.PI / 180, Space.WORLD);
        node.rotate(Axis.Y, vector.y * Math.PI / 180, Space.WORLD);
        node.rotate(Axis.Z, vector.z * Math.PI / 180, Space.WORLD);
    }
}

export function addBonePosition(skeleton, boneName, position) {
    const bone = skeleton.bones[skeleton.getBoneIndexByName(boneName)];
    if (bone) {
        const node = bone.getTransformNode();
        node.position.addInPlace(position);
    }
}

export function scaleBone(skeleton, boneName, scale) {
    const bone = skeleton.bones[skeleton.getBoneIndexByName(boneName)];
    if (bone) {
        const node = bone.getTransformNode();
        node.scaling.copyFrom(scale);
    }
}
export const renderWireframeOver = (mesh) => {
    // const mesh = this.props.mesh;
    const scene = mesh.getScene();
    if (mesh.reservedDataStore && mesh.reservedDataStore.wireframeOver) {
        mesh.reservedDataStore.wireframeOver.dispose(false, true);
        mesh.reservedDataStore.wireframeOver = null;
        // this.forceUpdate();
        return;
    }
    var wireframeOver = mesh.clone();
    wireframeOver.reservedDataStore = { hidden: true };

    // Sets up the mesh to be attached to the parent.
    // So all neutral in local space.
    wireframeOver.parent = mesh;
    wireframeOver.position = Vector3.Zero();
    wireframeOver.scaling = new Vector3(1, 1, 1);
    wireframeOver.rotation = Vector3.Zero();
    wireframeOver.rotationQuaternion = null;

    var material = new StandardMaterial("wireframeOver", scene);
    material.reservedDataStore = { hidden: true };
    wireframeOver.material = material;
    material.zOffset = 1;
    material.disableLighting = true;
    material.backFaceCulling = false;
    material.emissiveColor = Color3.White();

    material.wireframe = true;

    if (!mesh.reservedDataStore) {
        mesh.reservedDataStore = {};
    }

    mesh.reservedDataStore.wireframeOver = wireframeOver;

    // this.forceUpdate();
}

export const isTopGarment = (garmentType) => {
    return garmentType === GARMENT_TYPE.TOP;
}
export const isBottomGarment = (garmentType) => {
    return garmentType === GARMENT_TYPE.BOTTOM;
};

export const isShoeProduct = (product) => {
    return product.primary === 'shoes';
}

export const isBraProduct = (product) => {
    return product?.primary === 'bra';
}
