import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import get from "lodash/get";
import IframeSection from "containers/dummyProduct/IframeSection";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import Button from "components/Button";
import A1 from "../../assets/images/adidas/ADIDAS_SWEATSHIRT_1.jpeg";
import A2 from "../../assets/images/adidas/ADIDAS_SWEATSHIRT_2.jpeg";
import A3 from "../../assets/images/adidas/ADIDAS_SWEATSHIRT_3.jpeg";
import B1 from "../../assets/images/adidas/ADIDAS_JOGGERS_1.jpeg";
import B2 from "../../assets/images/adidas/ADIDAS_JOGGERS_2.jpeg";
import B3 from "../../assets/images/adidas/ADIDAS_JOGGERS_3.jpeg";
import C1 from "../../assets/images/adidas/ColoredShirt.jpeg";
import C2 from "../../assets/images/adidas/WhiteShirt.jpeg";
import D1 from "../../assets/images/adidas/ColoredShort.jpeg";
import D2 from "../../assets/images/adidas/WhiteShort.jpeg";
import F1 from "../../assets/images/adidas/TankTopWhite.png";
import F2 from "../../assets/images/adidas/TankTopBlack.png";
import F3 from "../../assets/images/adidas/TankTopPink.png";
import F4 from "../../assets/images/adidas/TankTopGrey.png";
import F5 from "../../assets/images/adidas/TankTopYellow.png";

import E1 from '../../assets/images/abfrl/LPKPG0510RG.jpeg';
import E2 from '../../assets/images/abfrl/PESFG0205SL.jpeg';
import E3 from '../../assets/images/abfrl/LPTFG0043SL.jpeg';
import E4 from '../../assets/images/abfrl/Allen_Solly_Denim.png';
import E5 from '../../assets/images/abfrl/American_Eagle_T-shirt.png';
import E6 from '../../assets/images/abfrl/IMG_0055.jpeg'
import E7 from '../../assets/images/abfrl/IMG_0070.jpeg'

import AHDRG0845RG from '../../assets/images/abfrl/AHDRG0845RG.png';
import AHKBG0036RG from '../../assets/images/abfrl/AHKBG0036RG.png';
import LRSHG0131SL from '../../assets/images/abfrl/LRSHG0131SL.png';
import LYSFG0170SL from '../../assets/images/abfrl/LYSFG0170SL.png';
import VWDRG1150RG from '../../assets/images/abfrl/VWDRG1150RG.jpg';
import VWTSG1553RG from '../../assets/images/abfrl/VWTSG1553RG.jpg';
import ALKCG0224SG from '../../assets/images/abfrl/ALKCG0224SG.jpeg'
import VAPTG0010KPNJ from '../../assets/images/abfrl/VAPTG0010KPNJ.jpeg'
import VASRG0004RG from '../../assets/images/abfrl/VASRG0004RG.jpeg'
import PXSFG0041SS from '../../assets/images/abfrl/PXSFG0041SS.jpeg'
import LPTFG0154CR from '../../assets/images/abfrl/LPTFG0154CR.jpeg'
import AHKCG0014RG from '../../assets/images/abfrl/AHKCG0014RG.jpeg'
import AHKBG0141RG from '../../assets/images/abfrl/AHKBG0141RG.jpeg'
import AHCTG0395RG from '../../assets/images/abfrl/AHCTG0395RG.jpeg'
import AHCTG0238RG from '../../assets/images/abfrl/AHCTG0238RG.jpeg'
import AHDNG0177RX from '../../assets/images/abfrl/AHDNG0177RX.jpeg'
import AHCTG0260RG from '../../assets/images/abfrl/AHCTG0260RG.jpeg'
import VWSTG0025RG from '../../assets/images/abfrl/VWSTG0025RG.jpeg'

import VWJSG0019RG from '../../assets/images/abfrl/VWJSG0019RG.jpeg'
import AHDRG0889RG from '../../assets/images/abfrl/AHDRG0889RG.jpeg'


import Puma679460 from '../../assets/images/puma/679460.jpg'
import Puma677865_0 from '../../assets/images/puma/677865_0.png'
import Puma677865_1 from '../../assets/images/puma/677865_1.png'
import Puma677865_2 from '../../assets/images/puma/677865_2.png'
import Puma677867_0 from '../../assets/images/puma/677867_0.png'
import Puma677867_1 from '../../assets/images/puma/677867_1.png'
import Puma677867_2 from '../../assets/images/puma/677867_2.png'
import Puma679459 from '../../assets/images/puma/679459.jpg'


import OasisF1 from "../../assets/images/oasis/FemaleOutfit1.png";
import OasisF21 from "../../assets/images/oasis/FemaleOutfit21.png";
import OasisF22 from "../../assets/images/oasis/FemaleOutfit22.png";
import OasisF31 from "../../assets/images/oasis/FemaleOutfit31.png";
import OasisF32 from "../../assets/images/oasis/FemaleOutfit32.png";
import OasisM11 from "../../assets/images/oasis/MaleOutfit11.png";
import OasisM12 from "../../assets/images/oasis/MaleOutfit12.png";
import OasisM21 from "../../assets/images/oasis/MaleOutfit21.png";
import OasisM22 from "../../assets/images/oasis/MaleOutfit22.png";
import OasisM31 from "../../assets/images/oasis/MaleOutfit31.png";
import OasisM32 from "../../assets/images/oasis/MaleOutfit32.png";
import OasisnuIndianTop from "../../assets/images/oasis/nuIndianTop.png";
import OasisnuIndianBottom from "../../assets/images/oasis/nuIndianBottom.png";


import NicobarDress from "../../assets/images/nicobar/Nicobar_Dress.png";
import NicobarFunkeyMonkey from "../../assets/images/nicobar/Nicobar_Funkey_Monkey.png";


import Sculpt1 from '../../assets/images/cava/Sculpt1.jpg'
import Sculpt2 from '../../assets/images/cava/Sculpt2.jpg'
import Sculpt3 from '../../assets/images/cava/Sculpt3.jpg'
import Sculpt4 from '../../assets/images/cava/Sculpt4.jpg'

import Chase1 from '../../assets/images/cava/Chase1.jpg'
import Chase2 from '../../assets/images/cava/Chase2.jpg'
import Chase3 from '../../assets/images/cava/Chase3.jpg'

import Adjusted_Half1 from '../../assets/images/cava/Adjusted_Half1.jpg'
import Adjusted_Half2 from '../../assets/images/cava/Adjusted_Half2.jpg'

import Adjusted1 from '../../assets/images/cava/Adjusted1.jpg'
import Adjusted2 from '../../assets/images/cava/Adjusted2.jpg'

import Sweatshirt1 from '../../assets/images/cava/Sweatshirt1.jpeg'
import Sweatshirt2 from '../../assets/images/cava/Sweatshirt2.jpeg'
import Sweatshirt3 from '../../assets/images/cava/Sweatshirt3.jpg'
import Sweatshirt4 from '../../assets/images/cava/Sweatshirt4.jpg'
import Sweatshirt5 from '../../assets/images/cava/Sweatshirt5.jpeg'


import Punk1 from '../../assets/images/cava/Punk1.jpeg'
import Punk2 from '../../assets/images/cava/Punk2.jpeg'
import Punk3 from '../../assets/images/cava/Punk3.jpeg'
import Punk4 from '../../assets/images/cava/Punk4.jpeg'

import Reflex1 from '../../assets/images/cava/reflex1.jpg'
import Reflex2 from '../../assets/images/cava/reflex2.jpg'
import Reflex3 from '../../assets/images/cava/reflex3.jpg'

import ReflexWMS1 from '../../assets/images/cava/ReflexWMS1.jpg'
import ReflexWMS2 from '../../assets/images/cava/ReflexWMS2.jpg'
import ReflexWMS3 from '../../assets/images/cava/ReflexWMS3.jpg'

import Zipper0 from '../../assets/images/cava/Zipper_0.jpg'
import Zipper1 from '../../assets/images/cava/Zipper_1.jpg'
import Zipper2 from '../../assets/images/cava/Zipper_2.jpg'
import Zipper3 from '../../assets/images/cava/Zipper_3.jpg'
import EVE from '../../assets/images/virgio/EVE.jpg'

import SaveTheTreesMen from '../../assets/images/ccclub/Save_the_trees_men.jpg'
import ShakyMen from '../../assets/images/ccclub/SHAKY_TEE_MEN.jpg'
import BePoliteMen1 from '../../assets/images/ccclub/Be_Polite_Man.jpg'
import BePoliteMen2 from '../../assets/images/ccclub/Be_Polite_Man1.jpg'
import BSWYEMen from '../../assets/images/ccclub/BE_SURE_OF_WHAT_YOU_EATING_MEN.jpg'
import OceanMen from '../../assets/images/ccclub/OCEAN_MEN.jpg'
import HalioyMen from '../../assets/images/ccclub/Have_a_look_inside_of_you_men.jpg'

import SaveTheTreesWomen from '../../assets/images/ccclub/Save_the_trees_women.jpg'
import ShakyWomen from '../../assets/images/ccclub/Shaky_Tee_women.jpg'
import BePoliteWomen from '../../assets/images/ccclub/Be_Polite_women.jpg'
import BePoliteWomen1 from '../../assets/images/ccclub/Be_Polite_women1.jpg'
import BSWYEWomen from '../../assets/images/ccclub/Be_Sure_of_What_You_Eating_women.jpg'
import OceanWomen from '../../assets/images/ccclub/OCEAN_WOMEN.jpg'
import HalioyWomen from '../../assets/images/ccclub/Have_a_look_inside_of_you_women.jpg'

import ValentinoM1 from "../../assets/images/valentino/ValentinoM1.png";
import ValentinoF1 from "../../assets/images/valentino/ValentinoF1.png";
import ValentinoM2 from "../../assets/images/valentino/ValentinoM2.png";
import Logo from "../../assets/icons/Logo.png";
import "./AdidasProducts.scss";
import Login from "containers/login/Login.js";
import Milan from "../../assets/images/oasis/Milan.png";
import { actionsCreator } from "redux/actions/actionsCreator.js";
import LoginAction from "containers/babylon/LoginAction.js";
import bh1 from '../../assets/images/beinghuman/1.webp';
import bh2 from '../../assets/images/beinghuman/2.webp';
import bh4 from '../../assets/images/beinghuman/4.webp';
import bh5 from '../../assets/images/beinghuman/5.webp';
import bh6 from '../../assets/images/beinghuman/6.webp';
import bh7 from '../../assets/images/beinghuman/7.webp';
import bh8 from '../../assets/images/beinghuman/8.webp';
import bh9 from '../../assets/images/beinghuman/9.webp';
import bh10 from '../../assets/images/beinghuman/10.webp';
import bh12 from '../../assets/images/beinghuman/12.webp';
import bh14 from '../../assets/images/beinghuman/14.webp';
import bh15 from '../../assets/images/beinghuman/15.webp';
import bh16 from '../../assets/images/beinghuman/16.webp';
import bh19 from '../../assets/images/beinghuman/19.webp';
import bh20 from '../../assets/images/beinghuman/20.webp';
import bh21 from '../../assets/images/beinghuman/21.webp';
import bh22 from '../../assets/images/beinghuman/22.webp';
import bh23 from '../../assets/images/beinghuman/23.webp';
import bh24 from '../../assets/images/beinghuman/24.webp';
import bh25 from '../../assets/images/beinghuman/25.webp';
import bh26 from '../../assets/images/beinghuman/26.webp';
import bh27 from '../../assets/images/beinghuman/27.webp';
import bh28 from '../../assets/images/beinghuman/28.webp';
import bh29 from '../../assets/images/beinghuman/29.webp';
import bh30 from '../../assets/images/beinghuman/30.webp';
import bh31 from '../../assets/images/beinghuman/31.webp';
import bh32 from '../../assets/images/beinghuman/32.webp';
import bh34 from '../../assets/images/beinghuman/34.webp';
import bh35 from '../../assets/images/beinghuman/35.webp';
import bh36 from '../../assets/images/beinghuman/36.webp';
import bh37 from '../../assets/images/beinghuman/37.webp';
import bh42 from '../../assets/images/beinghuman/42.webp';
import bh43 from '../../assets/images/beinghuman/43.webp';
import bh44 from '../../assets/images/beinghuman/44.webp';
import bh45 from '../../assets/images/beinghuman/45.webp';
import bh46 from '../../assets/images/beinghuman/46.webp';
import bh47 from '../../assets/images/beinghuman/47.webp';
import bh48 from '../../assets/images/beinghuman/48.webp';
import bh49 from '../../assets/images/beinghuman/49.webp';
import bh50 from '../../assets/images/beinghuman/50.webp';
import bh51 from '../../assets/images/beinghuman/51.webp';
import bh52 from '../../assets/images/beinghuman/52.webp';
import bh53 from '../../assets/images/beinghuman/53.webp';
import bh54 from '../../assets/images/beinghuman/54.webp';
import bh55 from '../../assets/images/beinghuman/55.webp';
import bh56 from '../../assets/images/beinghuman/56.webp';
import bh57 from '../../assets/images/beinghuman/57.webp';
import bh58 from '../../assets/images/beinghuman/58.webp';
import bh59 from '../../assets/images/beinghuman/59.webp';
import bh60 from '../../assets/images/beinghuman/60.webp';
import bh61 from '../../assets/images/beinghuman/61.webp';
import bh62 from '../../assets/images/beinghuman/62.webp';
import bh63 from '../../assets/images/beinghuman/63.webp';
import bh64 from '../../assets/images/beinghuman/64.webp';
import bh65 from '../../assets/images/beinghuman/65.webp';
import bh66 from '../../assets/images/beinghuman/66.webp';
import bh67 from '../../assets/images/beinghuman/67.webp';
import bh68 from '../../assets/images/beinghuman/68.webp';
import bh69 from '../../assets/images/beinghuman/69.webp';
import bh70 from '../../assets/images/beinghuman/70.webp';
import bh71 from '../../assets/images/beinghuman/71.webp';
import bh72 from '../../assets/images/beinghuman/72.webp';
import bh73 from '../../assets/images/beinghuman/73.webp';
import bh74 from '../../assets/images/beinghuman/74.webp';
import bh75 from '../../assets/images/beinghuman/75.webp';
import bh76 from '../../assets/images/beinghuman/76.webp';
import bh80 from '../../assets/images/beinghuman/80.webp';
import bh82 from '../../assets/images/beinghuman/82.webp';
import bh87 from '../../assets/images/beinghuman/87.webp';
import bh88 from '../../assets/images/beinghuman/88.webp';
import bh89 from '../../assets/images/beinghuman/89.webp';
import bh90 from '../../assets/images/beinghuman/90.webp';
import bh91 from '../../assets/images/beinghuman/91.webp';
import bh92 from '../../assets/images/beinghuman/92.webp';
import bh93 from '../../assets/images/beinghuman/93.webp';
import bh94 from '../../assets/images/beinghuman/94.webp';
import bh95 from '../../assets/images/beinghuman/95.webp';
import bh96 from '../../assets/images/beinghuman/96.webp';
import bh97 from '../../assets/images/beinghuman/97.webp';
import bh98 from '../../assets/images/beinghuman/98.webp';
import bh99 from '../../assets/images/beinghuman/99.webp';
import bh100 from '../../assets/images/beinghuman/100.webp';
import bh101 from '../../assets/images/beinghuman/101.webp';
import bh102 from '../../assets/images/beinghuman/102.webp';
import bh103 from '../../assets/images/beinghuman/103.webp';
import bh104 from '../../assets/images/beinghuman/104.webp';
import bh105 from '../../assets/images/beinghuman/105.webp';
import bh106 from '../../assets/images/beinghuman/106.webp';
import bh107 from '../../assets/images/beinghuman/107.webp';
import bh108 from '../../assets/images/beinghuman/108.webp';
import bh109 from '../../assets/images/beinghuman/109.webp';
import bh110 from '../../assets/images/beinghuman/110.webp';
import bh111 from '../../assets/images/beinghuman/111.webp';
import bh113 from '../../assets/images/beinghuman/113.webp';
import bh114 from '../../assets/images/beinghuman/114.webp';
import bh115 from '../../assets/images/beinghuman/115.webp';
import bh116 from '../../assets/images/beinghuman/116.webp';
import bh117 from '../../assets/images/beinghuman/117.webp';
import bh118 from '../../assets/images/beinghuman/118.webp';
import bh119 from '../../assets/images/beinghuman/119.webp';
import bh120 from '../../assets/images/beinghuman/120.webp';
import bh121 from '../../assets/images/beinghuman/121.webp';

import FEFImage2 from "../../assets/images/AW22N10-1.webp"
import LineTribeImage from "../../assets/images/LineTribeImage.webp";


export const enamorProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'avatar': 'v22',
        'shoes': '',
        'primary': "bra",
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
        'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '8A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '9A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '10A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'primary': "bra",
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 11,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '11A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'enamor',
        'avatar': 'v22',
        'shoes': '',
        'productSizeTags': ['32B', '32C', '32D', '34B', '34C', '34D', '36B', '36C', '36D', '38B', '38C', '38D'],
        'productSizeValues': [
            [[68, 72], [84, 86]],
            [[68, 72], [86, 88]],
            [[68, 72], [88, 90]],
            [[73, 77], [89, 91]],
            [[73, 77], [91, 93]],
            [[73, 77], [91, 93]],
            [[78, 82], [94, 96]],
            [[78, 82], [96, 98]],
            [[78, 82], [98, 101]],
            [[83, 87], [99, 101]],
            [[83, 87], [101, 103]],
            [[83, 87], [103, 106]],
        ],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        "id": 12,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "12A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 13,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "13A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 14,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "14A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 15,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "15A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 16,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "16A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 17,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "17A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 18,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "18A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 19,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "19A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 20,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "20A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 21,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "21A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 22,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "22A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 23,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "23A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 24,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "24A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 25,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "25A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 26,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "26A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 27,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "27A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 28,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "28A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 29,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "29A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 30,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "30A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 31,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "31A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 32,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "32A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 33,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "33A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 34,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "34A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 35,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "35A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 36,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "36A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 37,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "37A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 38,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "38A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 39,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "39A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 40,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "40A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 41,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "41A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 42,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "42A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 43,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "43A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 44,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "44A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 45,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "45A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 46,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "46A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 47,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "47A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 48,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "48A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 49,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "49A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 50,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "50A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 51,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "51A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 52,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "52A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 53,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "53A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 54,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "54A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 55,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "55A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 56,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "56A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 57,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "57A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 58,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "58A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 59,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "59A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 60,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "60A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 61,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "61A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 62,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "62A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 63,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "63A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 64,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "64A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 65,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "65A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 66,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "66A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 67,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "67A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 68,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "68A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 69,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "69A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 70,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "70A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 71,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "71A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 72,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "72A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 73,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "73A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 74,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "74A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 75,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "75A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 76,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "76A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    },
    {
        "id": 77,
        "productSlug": "being-human-men-none-t-shirts-green-foam-8905271469155",
        "productCounterPartSlug": "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        "groupId": "BHTS24031",
        "product_path": "77A_0",
        "product_counter_part": "",
        "garment_type": "top",
        "gender": "female",
        "brand": "enamor",
        "primary": "bra",
        "avatar": "v22",
        "shoes": "",
        "productSizeTags": [
            "32B",
            "32C",
            "32D",
            "34B",
            "34C",
            "34D",
            "36B",
            "36C",
            "36D",
            "38B",
            "38C",
            "38D"
        ],
        "productSizeValues": [
            [
                [
                    68,
                    72
                ],
                [
                    84,
                    86
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    86,
                    88
                ]
            ],
            [
                [
                    68,
                    72
                ],
                [
                    88,
                    90
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    89,
                    91
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    73,
                    77
                ],
                [
                    91,
                    93
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    94,
                    96
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    96,
                    98
                ]
            ],
            [
                [
                    78,
                    82
                ],
                [
                    98,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    99,
                    101
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    101,
                    103
                ]
            ],
            [
                [
                    83,
                    87
                ],
                [
                    103,
                    106
                ]
            ]
        ],
        "productCounterPartSizeTags": [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        "productCounterPartSizeValues": [
            [
                83.82,
                98.6
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.6
            ],
            [
                91.44,
                107.57
            ],
            [
                96.52,
                113.56
            ],
            [
                104.14,
                122.53
            ],
            [
                109.22,
                128.5
            ],
            [
                114.3,
                134.47
            ]
        ],
        "productTitle": "Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039",
        "productImage": "https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023"
    }
]

export const burgerBaeProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'burgerbae',
        'avatar': 'v16',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg',
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'burgerbae',
        'avatar': 'v16',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg',
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'burgerbae',
        'avatar': 'v16',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg',
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'burgerbae',
        'avatar': 'v16',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg',
    },
];

export const rareismProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '7B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '8A_0',
        'product_counter_part': '8B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '9A_0',
        'product_counter_part': '9B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '10A_0',
        'product_counter_part': '10B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 11,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '11A_0',
        'product_counter_part': '11B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 12,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '12A_0',
        'product_counter_part': '12B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 13,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '13A_0',
        'product_counter_part': '13B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 14,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '14A_0',
        'product_counter_part': '14B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 15,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '15A_0',
        'product_counter_part': '15B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
];

export const superKicksProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '1',
        'primary': 'shoes',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
];

export const fefDemoProductList = [
    // {
    //     'id': 1,
    //     'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    //     'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    //     'groupId': 'BHTS24031',
    //     'product_path': '1A_0',
    //     'product_counter_part': '',
    //     'garment_type': 'top',
    //     'gender': 'female',
    //     'brand': 'fef',
    //     'avatar': 'v20',
    //     'shoes': 'flat1',
    //     'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
    //     'productSizeValues': [
    //         101.6,
    //         106.68,
    //         111.76,
    //         116.84,
    //         121.92,
    //         127.0
    //     ],
    //     'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
    //     'productCounterPartSizeValues': [[78.74, 89.6],
    //     [83.82, 94.6],
    //     [88.9, 99.06],
    //     [93.9, 104.6],
    //     [99.0, 109.5],],
    //     'productTitle': '',
    //     'productImage': 'https://'
    // },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v20',
        'shoes': 'female-white-sneaker',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'ZERAH DENIM SET',
        'description': 'A FRESH, CHIC SET WITH ENGINEERED SEAMS AND ZIPPER DETAIL ON THE SKIRT',
        'price': '₹ 15,000.00',
        // 'labels': "SS24000133",
        // 'bullet_description': [
        //     "Regular fit",
        //     "100% cotton",
        //     "Half Sleeves",
        //     "Ocassion: Casual",
        //     "Neck: Polo Neck",
        //     "Pattern: Stripe",
        //     "Color: White",
        //     "Collection: LY True Casual"
        // ],
        'productImage': LineTribeImage
    },
    // {
    //     'id': 3,
    //     'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    //     'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    //     'groupId': 'BHTS24031',
    //     'product_path': '3A_0',
    //     'product_counter_part': '3B_0',
    //     'garment_type': 'top',
    //     'gender': 'male',
    //     'brand': 'fef',
    //     'avatar': 'v15',
    //     'shoes': 'male-white-sneaker',
    //     'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
    //     'productSizeValues': [
    //         101.6,
    //         106.68,
    //         111.76,
    //         116.84,
    //         121.92,
    //         127.0
    //     ],
    //     'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
    //     'productCounterPartSizeValues': [[78.74, 89.6],
    //     [83.82, 94.6],
    //     [88.9, 99.06],
    //     [93.9, 104.6],
    //     [99.0, 109.5],],
    //     'productTitle': 'Being Human Men None T-Shirts-Green Foam',
    //     'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    // },
    // {
    //     'id': 4,
    //     'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    //     'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    //     'groupId': 'BHTS24031',
    //     'product_path': '4A_0',
    //     'product_counter_part': '4B_0',
    //     'garment_type': 'top',
    //     'gender': 'female',
    //     'brand': 'fef',
    //     'avatar': 'v21',
    //     'shoes': 'flat2',
    //     'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
    //     'productSizeValues': [
    //         101.6,
    //         106.68,
    //         111.76,
    //         116.84,
    //         121.92,
    //         127.0
    //     ],
    //     'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
    //     'productCounterPartSizeValues': [[78.74, 89.6],
    //     [83.82, 94.6],
    //     [88.9, 99.06],
    //     [93.9, 104.6],
    //     [99.0, 109.5],],
    //     'productTitle': 'Being Human Men None T-Shirts-Green Foam',
    //     'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    // },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'fef',
        'avatar': 'v15',
        'shoes': 'loafer',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'TARANG',
        'labels': 'Ombre Dyed green textured open bandi.',
        'productImage': FEFImage2,
        'price': 'Rs. 24,500'
    },
    // {
    //     'id': 6,
    //     'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    //     'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    //     'groupId': 'BHTS24031',
    //     'product_path': '6A_0',
    //     'product_counter_part': '6B_0',
    //     'garment_type': 'top',
    //     'gender': 'female',
    //     'brand': 'fef',
    //     'avatar': 'v21',
    //     'shoes': 'flat2',
    //     'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
    //     'productSizeValues': [
    //         101.6,
    //         106.68,
    //         111.76,
    //         116.84,
    //         121.92,
    //         127.0
    //     ],
    //     'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
    //     'productCounterPartSizeValues': [[78.74, 89.6],
    //     [83.82, 94.6],
    //     [88.9, 99.06],
    //     [93.9, 104.6],
    //     [99.0, 109.5],],
    //     'productTitle': 'Being Human Men None T-Shirts-Green Foam',
    //     'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    // },
    // {
    //     'id': 7,
    //     'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    //     'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    //     'groupId': 'BHTS24031',
    //     'product_path': '7A_0',
    //     'product_counter_part': '7B_0',
    //     'garment_type': 'top',
    //     'gender': 'female',
    //     'brand': 'fef',
    //     'avatar': 'v21',
    //     'shoes': 'flat2',
    //     'primary': 'shoes',
    //     'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
    //     'productSizeValues': [
    //         101.6,
    //         106.68,
    //         111.76,
    //         116.84,
    //         121.92,
    //         127.0
    //     ],
    //     'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
    //     'productCounterPartSizeValues': [[78.74, 89.6],
    //     [83.82, 94.6],
    //     [88.9, 99.06],
    //     [93.9, 104.6],
    //     [99.0, 109.5],],
    //     'productTitle': 'Being Human Men None T-Shirts-Green Foam',
    //     'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    // },
];

export const fefProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v20',
        'shoes': 'flat1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg',
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v20',
        'shoes': 'female-white-sneaker',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'fef',
        'avatar': 'v15',
        'shoes': 'male-white-sneaker',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v21',
        'shoes': 'flat2',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'fef',
        'avatar': 'v15',
        'shoes': 'loafer',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v21',
        'shoes': 'flat2',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '7B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v21',
        'shoes': 'flat2',
        'primary': 'shoes',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
];

export const rarerabbitDemoList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men's Zavet-T Light Green Cotton Polyester Fabric Short Sleeves Crew Neck Oversized Fit Self Stripe Textured T-Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/ZAVET-TLIGHTGREEN__4_3b09be07-a397-4207-a0e0-3dc4e1e9e37d.jpg?v=1718173522',
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men\'s Crofty Blue Cotton Lycra Fabric Full Sleeves Collared Neck Regular Fit Plain Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/CROFTYBLUE06837.jpg?v=1720244301'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "RARE RABBIT GRAPHIC BACK PRINT OVERSIZED T-SHIRT MINT - OFF WHITE",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/Mintoffwhite__MINTOFFWHITE03150.jpg?v=1722577495'
        // 'productTitle': "RARE RABBIT COTTON STRETCH PLAIN TROUSERS POKE-2-WHITE",
        // 'productImage': 'https://thehouseofrare.com/cdn/shop/files/poke2white-3079.jpg?v=1689675599'
    },
];

export const lacosteDemoList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men's Zavet-T Light Green Cotton Polyester Fabric Short Sleeves Crew Neck Oversized Fit Self Stripe Textured T-Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/ZAVET-TLIGHTGREEN__4_3b09be07-a397-4207-a0e0-3dc4e1e9e37d.jpg?v=1718173522',
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men\'s Crofty Blue Cotton Lycra Fabric Full Sleeves Collared Neck Regular Fit Plain Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/CROFTYBLUE06837.jpg?v=1720244301'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "RARE RABBIT GRAPHIC BACK PRINT OVERSIZED T-SHIRT MINT - OFF WHITE",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/Mintoffwhite__MINTOFFWHITE03150.jpg?v=1722577495'
        // 'productTitle': "RARE RABBIT COTTON STRETCH PLAIN TROUSERS POKE-2-WHITE",
        // 'productImage': 'https://thehouseofrare.com/cdn/shop/files/poke2white-3079.jpg?v=1689675599'
    },
    {
        'id': 221,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271464167',
        'groupId': 'BHP24051',
        'product_path': '221A_0',
        'product_counter_part': '221B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-WHITE_1.jpg'
    },
    {
        id: 266,
        src: SaveTheTreesMen,
        productSlug: 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        product_path: "266A_0",
        product_counter_part: "266B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ],
        productImage: "https://pictures.kartmax.in/cover/live/600x800/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24029-POWDER%20PINK_6.jpg"
    },
];

export const rareRabbitProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '7B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '8A_0',
        'product_counter_part': '8B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '9A_0',
        'product_counter_part': '9B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '10A_0',
        'product_counter_part': '10B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 11,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '11A_0',
        'product_counter_part': '11B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 12,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '12A_0',
        'product_counter_part': '12B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 13,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '13A_0',
        'product_counter_part': '13B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 14,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '14A_0',
        'product_counter_part': '14B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 15,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '15A_0',
        'product_counter_part': '15B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 16,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '16A_0',
        'product_counter_part': '16B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 17,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '17A_0',
        'product_counter_part': '17B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 18,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '18A_0',
        'product_counter_part': '18B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 19,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '19A_0',
        'product_counter_part': '19B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 20,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '20A_0',
        'product_counter_part': '20B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 21,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '21A_0',
        'product_counter_part': '21B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 22,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '22A_0',
        'product_counter_part': '22B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 23,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '23A_0',
        'product_counter_part': '23B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 24,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '24A_0',
        'product_counter_part': '24B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 25,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '25A_0',
        'product_counter_part': '25B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 26,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '26A_0',
        'product_counter_part': '26B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 27,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '27A_0',
        'product_counter_part': '27B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 28,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '28A_0',
        'product_counter_part': '28B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 29,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '29A_0',
        'product_counter_part': '29B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 30,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '30A_0',
        'product_counter_part': '30B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 31,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '31A_0',
        'product_counter_part': '31B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 32,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '32A_0',
        'product_counter_part': '32B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 33,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '33A_0',
        'product_counter_part': '33B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 34,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '34A_0',
        'product_counter_part': '34B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 35,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '35A_0',
        'product_counter_part': '35B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 36,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '36A_0',
        'product_counter_part': '36B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 37,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '37A_0',
        'product_counter_part': '37B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 38,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '38A_0',
        'product_counter_part': '38B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 39,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '39A_0',
        'product_counter_part': '39B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 40,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '40A_0',
        'product_counter_part': '40B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 41,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '41A_0',
        'product_counter_part': '41B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 42,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '42A_0',
        'product_counter_part': '42B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 43,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '43A_0',
        'product_counter_part': '43B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 44,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '44A_0',
        'product_counter_part': '44B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 45,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '45A_0',
        'product_counter_part': '45B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 46,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '46A_0',
        'product_counter_part': '46B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 47,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '47A_0',
        'product_counter_part': '47B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 48,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '48A_0',
        'product_counter_part': '48B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 49,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '49A_0',
        'product_counter_part': '49B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 50,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '50A_0',
        'product_counter_part': '50B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 51,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '51A_0',
        'product_counter_part': '51B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 52,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '52A_0',
        'product_counter_part': '52B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 53,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '53A_0',
        'product_counter_part': '53B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 54,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '54A_0',
        'product_counter_part': '54B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 55,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '55A_0',
        'product_counter_part': '55B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 56,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '56A_0',
        'product_counter_part': '56B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 57,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '57A_0',
        'product_counter_part': '57B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 58,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '58A_0',
        'product_counter_part': '58B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 59,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '59A_0',
        'product_counter_part': '59B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 60,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '60A_0',
        'product_counter_part': '60B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 61,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '61A_0',
        'product_counter_part': '61B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 62,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '62A_0',
        'product_counter_part': '62B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 63,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '63A_0',
        'product_counter_part': '63B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 64,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '64A_0',
        'product_counter_part': '64B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 65,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '65A_0',
        'product_counter_part': '65B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 66,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '66A_0',
        'product_counter_part': '66B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 67,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '67A_0',
        'product_counter_part': '67B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 68,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '68A_0',
        'product_counter_part': '68B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 69,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '69A_0',
        'product_counter_part': '69B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 70,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '70A_0',
        'product_counter_part': '70B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 71,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '71A_0',
        'product_counter_part': '71B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 72,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '72A_0',
        'product_counter_part': '72B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 73,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '73A_0',
        'product_counter_part': '73B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 74,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '74A_0',
        'product_counter_part': '74B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 75,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '75A_0',
        'product_counter_part': '75B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 76,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '76A_0',
        'product_counter_part': '76B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 77,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '77A_0',
        'product_counter_part': '77B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 78,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '78A_0',
        'product_counter_part': '78B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 79,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '79A_0',
        'product_counter_part': '79B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 80,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '80A_0',
        'product_counter_part': '80B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 81,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '81A_0',
        'product_counter_part': '81B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 82,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '82A_0',
        'product_counter_part': '82B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 83,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '83A_0',
        'product_counter_part': '83B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 84,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '84A_0',
        'product_counter_part': '84B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 85,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '85A_0',
        'product_counter_part': '85B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 86,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '86A_0',
        'product_counter_part': '86B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 87,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '87A_0',
        'product_counter_part': '87B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 88,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '88A_0',
        'product_counter_part': '88B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 89,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '89A_0',
        'product_counter_part': '89B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 90,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '90A_0',
        'product_counter_part': '90B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 91,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '91A_0',
        'product_counter_part': '91B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 92,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '92A_0',
        'product_counter_part': '92B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 93,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '93A_0',
        'product_counter_part': '93B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 94,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '94A_0',
        'product_counter_part': '94B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 95,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '95A_0',
        'product_counter_part': '95B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 96,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '96A_0',
        'product_counter_part': '96B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 97,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '97A_0',
        'product_counter_part': '97B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 98,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '98A_0',
        'product_counter_part': '98B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 99,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '99A_0',
        'product_counter_part': '99B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
]

export const fashorProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fashor',
        'avatar': 'v19',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fashor',
        'avatar': 'v19',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fashor',
        'avatar': 'v19',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fashor',
        'avatar': 'v19',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fashor',
        'avatar': 'v19',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
]

export const beingHumanProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': 'BHTS24031_0',
        'product_counter_part': 'BHDI24099_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 1001,
        'productSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'groupId': 'BHDI24099',
        'product_path': 'BHDI24099_0',
        'product_counter_part': 'BHTS24031_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productTitle': 'Being Human Men Skinnyfit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24099-MID TONE_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-jet-black-8905271445012',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-mid-tone-8905271476627',
        'groupId': 'BHTS24079',
        'product_path': 'BHT24079_0',
        'product_counter_part': 'BHDI24168_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men None T-Shirts-Jet Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24079-JET BLACK_1.jpg'
    },
    {
        'id': 1002,
        'productSlug': 'being-human-men-slim-fit-denim-mid-tone-8905271476627',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-jet-black-8905271445012',
        'groupId': 'BHDI24168',
        'product_path': 'BHDI24168_0',
        'product_counter_part': 'BHT24079_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productTitle': 'Being Human Men Slim Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24168-MID TONE_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271451495',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24020',
        'product_path': 'BHP24020_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24020-CHARCOAL_1.jpg'
    },
    {
        'id': 1004,
        'productSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271451495',
        'groupId': 'BHDSI24109',
        'product_path': 'BHDSI24109_0',
        'product_counter_part': 'BHP24020_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Shorts Fit Shorts-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDSI24109-MID TONE_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271451570',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24020',
        'product_path': 'BHP24020_1',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24020-NAVY_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271453178',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24028',
        'product_path': 'BHP24028_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24028-WHITE_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271453093',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24028',
        'product_path': 'BHP24028_1',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24028-BLACK_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271458098',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24035',
        'product_path': 'BHP24035_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24035-LT.GREY_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271451891',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24038',
        'product_path': 'BHP24038_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24038-LIGHT BLUE_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24046',
        'product_path': 'BHP24046_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Pastel Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24046-PASTEL PINK_1.jpg'
    },
    {
        'id': 12,
        'productSlug': 'being-human-men-slim-fit-shirts-deep-ocean-8905271459491',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24014',
        'product_path': 'BHMLS24014_0',
        'product_counter_part': 'BHDI24093_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24014-DEEP OCEAN_1.jpg'
    },
    {
        'id': 1012,
        'productSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-deep-ocean-8905271459491',
        'groupId': 'BHDI24093',
        'product_path': 'BHDI24093_0',
        'product_counter_part': 'BHMLS24014_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Jogger Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24093-LIGHT TONE_1.jpg'
    },
    {
        'id': 14,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271445876',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474401',
        'groupId': 'BHMLS24017',
        'product_path': 'BHMLS24017_0',
        'product_counter_part': 'BHDI24137_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[73.66, 86.66],
        [78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [96.52, 113.56],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24017-BLACK_1.jpg'
    },
    {
        'id': 1014,
        'productSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474401',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-black-8905271445876',
        'groupId': 'BHDI24137',
        'product_path': 'BHDI24137_0',
        'product_counter_part': 'BHMLS24017_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[73.66, 86.66],
        [78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [96.52, 113.56],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productTitle': 'Being Human Men Slim Straight Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24137-MID TONE_1.jpg'
    },
    {
        'id': 15,
        'productSlug': 'being-human-men-regular-fit-t-shirts-forest-green-8905271453499',
        'productCounterPartSlug': 'being-human-men-boot-cut-fit-denim-light-tone-8905271467366',
        'groupId': 'BHP24003',
        'product_path': 'BHP24003_0',
        'product_counter_part': 'BHDI24030_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Forest Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24003-FOREST GREEN_1.jpg'
    },
    {
        'id': 1015,
        'productSlug': 'being-human-men-boot-cut-fit-denim-light-tone-8905271467366',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-forest-green-8905271453499',
        'groupId': 'BHDI24030',
        'product_path': 'BHDI24030_0',
        'product_counter_part': 'BHP24003_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[82.55, 97.13],
        [85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Boot Cut Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24030-LIGHT TONE_1.jpg'
    },
    {
        'id': 16,
        'productSlug': 'being-human-men-slim-fit-shirts-whitered-8905271459736',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24002',
        'product_path': 'BHMSS24002_0',
        'product_counter_part': 'BHDI24059_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24002-WHITE&RED_1.jpg'
    },
    {
        'id': 1016,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-whitered-8905271459736',
        'groupId': 'BHDI24059',
        'product_path': 'BHDI24059_0',
        'product_counter_part': 'BHMSS24002_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24059-MID TONE_1.jpg'
    },
    {
        'id': 17.0,
        'productSlug': 'being-human-men-slim-fit-shirts-ltbluecoral-8905271483243',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475392',
        'groupId': 'BHMSS24013',
        'product_path': 'BHMSS24013_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]]
    },
    {
        'id': 1017,
        'productSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475392',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-ltbluecoral-8905271483243',
        'groupId': 'BHDSI24109',
        'product_path': 'BHDSI24109_0',
        'product_counter_part': 'BHMSS24013_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productTitle': 'Being Human Men Shorts Fit Shorts-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDSI24109-MID TONE_1.jpg'
    },
    {
        'id': 18.0,
        'productSlug': 'being-human-men-slim-fit-shirts-chalkyellow-8905271483113',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475392',
        'groupId': 'BHMSS24013',
        'product_path': 'BHMSS24013_1',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]]
    },
    {
        'id': 19,
        'productSlug': 'being-human-men-slim-fit-shirts-greenblue-8905271446279',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-black-tone-8905271466130',
        'groupId': 'BHMSS24027',
        'product_path': 'BHMSS24027_0',
        'product_counter_part': 'BHDSI24195_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Green/Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24027-GREEN&BLUE_1.jpg'
    },
    {
        'id': 1019,
        'productSlug': 'being-human-men-shorts-fit-shorts-black-tone-8905271466130',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-greenblue-8905271446279',
        'groupId': 'BHDSI24195',
        'product_path': 'BHDSI24195_0',
        'product_counter_part': 'BHMSS24027_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productTitle': 'Being Human Men Shorts Fit Shorts-Black Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDSI24195-BLACK TONE_1.jpg'
    },
    {
        'id': 20,
        'productSlug': 'being-human-men-none-t-shirts-coffee-8905271442493',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24012',
        'product_path': '20A_0',
        'product_counter_part': '20B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Coffee',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24012-COFFEE_1.jpg'
    },
    {
        'id': 21,
        'productSlug': 'being-human-men-none-t-shirts-midnight-blue-8905271442400',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24012',
        'product_path': '21A_0',
        'product_counter_part': '21B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Midnight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24012-MIDNIGHT BLUE_1.jpg'
    },
    {
        'id': 22,
        'productSlug': 'being-human-men-none-t-shirts-charcoal-8905271442578',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24014',
        'product_path': '22A_0',
        'product_counter_part': '22B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24014-CHARCOAL_1.jpg'
    },
    {
        'id': 23,
        'productSlug': 'being-human-men-none-t-shirts-mineral-red-8905271442653',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24014',
        'product_path': '23A_0',
        'product_counter_part': '23B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24014-MINERAL RED_1.jpg'
    },
    {
        'id': 24,
        'productSlug': 'being-human-men-none-t-shirts-powder-pink-8905271444046',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24037',
        'product_path': '24A_0',
        'product_counter_part': '24B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [99.06, 101.6, 104.14, 109.22, 116.84, 121.92, 127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Powder Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24037-POWDER PINK_1.jpg'
    },
    {
        'id': 25,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271444114',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24037',
        'product_path': '25A_0',
        'product_counter_part': '25B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [99.06, 101.6, 104.14, 109.22, 116.84, 121.92, 127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24037-WHITE_1.jpg'
    },
    {
        'id': 26,
        'productSlug': 'being-human-men-slim-fit-t-shirts-chalk-8905271469902',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24006',
        'product_path': '26A_0',
        'product_counter_part': '26B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24006-CHALK_1.jpg'
    },
    {
        'id': 27,
        'productSlug': 'being-human-men-slim-fit-t-shirts-black-8905271470076',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24009',
        'product_path': '27A_0',
        'product_counter_part': '27B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24009-BLACK_1.jpg'
    },
    {
        'id': 28,
        'productSlug': 'being-human-men-slim-fit-t-shirts-green-lake-8905271470151',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24009',
        'product_path': '28A_0',
        'product_counter_part': '28B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Green Lake',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24009-GREEN LAKE_6.jpg'
    },
    {
        'id': 29,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271444770',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24057',
        'product_path': '29A_0',
        'product_counter_part': '29B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24057-NAVY_1.jpg'
    },
    {
        'id': 30,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271444695',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24057',
        'product_path': '30A_0',
        'product_counter_part': '30B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24057-DEEP OCEAN_6.jpg'
    },
    {
        'id': 31,
        'productSlug': 'being-human-men-none-t-shirts-chalk-8905271442738',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24015',
        'product_path': '31A_0',
        'product_counter_part': '31B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24015-CHALK_1.jpg'
    },
    {
        'id': 32,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271442813',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24015',
        'product_path': '32A_0',
        'product_counter_part': '32B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24015-DEEP OCEAN_6.jpg'
    },
    {
        'id': 33,
        'productSlug': 'being-human-men-slim-fit-shirts-whiteyellow-8905271459873',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24002\n',
        'product_path': '33A_0',
        'product_counter_part': '33B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24002-WHITE&YELLOW_1.jpg'
    },
    {
        'id': 1033.0,
        'productSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-whiteyellow-8905271459873',
        'groupId': 'BHDI24143\n',
        'product_path': '33B_0',
        'product_counter_part': '33A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0]
    },
    {
        'id': 34,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271460213',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24014',
        'product_path': '34A_0',
        'product_counter_part': '34B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24014-BLACK_1.jpg'
    },
    {
        'id': 35,
        'productSlug': 'being-human-men-slim-fit-shirts-deep-ocean-8905271460299',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24014',
        'product_path': '35A_0',
        'product_counter_part': '35B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24014-DEEP OCEAN_6.jpg'
    },
    {
        'id': 36,
        'productSlug': 'being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24010',
        'product_path': '36A_0',
        'product_counter_part': '36B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Yellow/Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24010-YELLOW&CHARCOAL_1.jpg'
    },
    {
        'id': 37,
        'productSlug': 'being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24010',
        'product_path': '37A_0',
        'product_counter_part': '37B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Blue/Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24010-LT.BLUE&CHARCOAL_1.jpg'
    },
    {
        'id': 38,
        'productSlug': 'being-human-men-slim-fit-shirts-blackgrey-8905271446019',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24009',
        'product_path': '38A_0',
        'product_counter_part': '38B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black/Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24009-BLACK&GREY_1.jpg'
    },
    {
        'id': 39,
        'productSlug': 'being-human-men-slim-fit-shirts-chalkwhite-8905271446040',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24009',
        'product_path': '39A_0',
        'product_counter_part': '39B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Chalk/White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24009-CHALK&WHITE_1.jpg'
    },
    {
        'id': 40,
        'productSlug': 'being-human-men-slim-fit-shirts-chalk-8905271459972',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24006',
        'product_path': '40A_0',
        'product_counter_part': '40B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24006-CHALK_1.jpg'
    },
    {
        'id': 41,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271459897',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24006',
        'product_path': '41A_0',
        'product_counter_part': '41B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24006-BLACK_1.jpg'
    },
    {
        'id': 42,
        'productSlug': 'being-human-men-slim-fit-shirts-chalknavy-8905271483267',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24016',
        'product_path': '42A_0',
        'product_counter_part': '42B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Chalk/Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24016-CHALK&NAVY_1.jpg'
    },
    {
        'id': 1042,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-chalknavy-8905271483267',
        'groupId': 'BHDI24049',
        'product_path': '42B_0',
        'product_counter_part': '42A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24049-MID TONE_1.jpg'
    },
    {
        'id': 43,
        'productSlug': 'being-human-men-slim-fit-shirts-navyolive-8905271483342',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24016',
        'product_path': '43A_0',
        'product_counter_part': '43B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy/Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24016-NAVY&OLIVE_1.jpg'
    },
    {
        'id': 44,
        'productSlug': 'being-human-men-none-t-shirts-mineral-red-8905271442325',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24011',
        'product_path': '44A_0',
        'product_counter_part': '44B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24011-MINERAL RED_1.jpg'
    },
    {
        'id': 45,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271442257',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24011',
        'product_path': '45A_0',
        'product_counter_part': '45B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24011-OLIVE_1.jpg'
    },
    {
        'id': 46,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271443476',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24025',
        'product_path': '46A_0',
        'product_counter_part': '46B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24025-BLACK_1.jpg'
    },
    {
        'id': 47,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271443391',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24025',
        'product_path': '47A_0',
        'product_counter_part': '47B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24025-WHITE_1.jpg'
    },
    {
        'id': 48,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271457534',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24012',
        'product_path': '48A_0',
        'product_counter_part': '48B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24012-LT.GREY_1.jpg'
    },
    {
        'id': 1048,
        'productSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271457534',
        'groupId': 'BHDI24051',
        'product_path': '48B_0',
        'product_counter_part': '48A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Cropped Fit Denim-Dark Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24051-DARK TONE_1.jpg'
    },
    {
        'id': 49,
        'productSlug': 'being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24012',
        'product_path': '49A_0',
        'product_counter_part': '49B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24012-DEEP OCEAN_1.jpg'
    },
    {
        'id': 50,
        'productSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271457619',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24013',
        'product_path': '50A_0',
        'product_counter_part': '50B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24013-CHARCOAL_1.jpg'
    },
    {
        'id': 51,
        'productSlug': 'being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24013',
        'product_path': '51A_0',
        'product_counter_part': '51B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24013-DEEP OCEAN_1.jpg'
    },
    {
        'id': 52,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271451655',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24022',
        'product_path': '52A_0',
        'product_counter_part': '52B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24022-LIGHT BLUE_1.jpg'
    },
    {
        'id': 53,
        'productSlug': 'being-human-men-regular-fit-t-shirts-snow-white-8905271451730',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24022',
        'product_path': '53A_0',
        'product_counter_part': '53B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Snow White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24022-SNOW WHITE_1.jpg'
    },
    {
        'id': 54,
        'productSlug': 'being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24024',
        'product_path': '54A_0',
        'product_counter_part': '54B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Midnight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24024-MIDNIGHT BLUE_1.jpg'
    },
    {
        'id': 55,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452133',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24041',
        'product_path': '55A_0',
        'product_counter_part': '55B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24041-WHITE_1.jpg'
    },
    {
        'id': 56,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271452058',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24041',
        'product_path': '56A_0',
        'product_counter_part': '56B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24041-NAVY_1.jpg'
    },
    {
        'id': 57,
        'productSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271453413',
        'productCounterPartSlug': 'being-human-men-boot-cut-fit-denim-light-tone-8905271467366',
        'groupId': 'BHP24003',
        'product_path': '57A_0',
        'product_counter_part': '57B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24003-CHARCOAL_1.jpg'
    },
    {
        'id': 58,
        'productSlug': 'being-human-men-slim-fit-shirts-whiteyellow-8905271456117',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24065',
        'product_path': '58A_0',
        'product_counter_part': '58B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24065-WHITE&YELLOW_1.jpg'
    },
    {
        'id': 59,
        'productSlug': 'being-human-men-slim-fit-shirts-whitenavy-8905271456261',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24066',
        'product_path': '59A_0',
        'product_counter_part': '59B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24066-WHITE&NAVY_1.jpg'
    },
    {
        'id': 60,
        'productSlug': 'being-human-men-slim-fit-shirts-navywhite-8905271456193',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24066',
        'product_path': '60A_0',
        'product_counter_part': '60B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy/White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24066-NAVY&WHITE_1.jpg'
    },
    {
        'id': 61,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271447795',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '61A_0',
        'product_counter_part': '61B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-BLACK_1.jpg'
    },
    {
        'id': 1061,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-black-8905271447795',
        'groupId': 'BHDI24175',
        'product_path': '61B_0',
        'product_counter_part': '61A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24175-MID TONE_1.jpg'
    },
    {
        'id': 62,
        'productSlug': 'being-human-men-regular-fit-t-shirts-blareney-8905271447870',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '62A_0',
        'product_counter_part': '62B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Blareney',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-BLARENEY_1.jpg'
    },
    {
        'id': 63,
        'productSlug': 'being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '63A_0',
        'product_counter_part': '63B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Bright Marigold',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-BRIGHT MARIGOLD_1.jpg'
    },
    {
        'id': 64,
        'productSlug': 'being-human-men-regular-fit-t-shirts-fiesta-8905271448129',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '64A_0',
        'product_counter_part': '64B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Fiesta',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-FIESTA_1.jpg'
    },
    {
        'id': 65,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271448181',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '65A_0',
        'product_counter_part': '65B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-NAVY_1.jpg'
    },
    {
        'id': 66,
        'productSlug': 'being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '66A_0',
        'product_counter_part': '66B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Peach Parfait',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-PEACH PARFAIT_1.jpg'
    },
    {
        'id': 67,
        'productSlug': 'being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '67A_0',
        'product_counter_part': '67B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Sachet Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-SACHET PINK_1.jpg'
    },
    {
        'id': 68,
        'productSlug': 'being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '68A_0',
        'product_counter_part': '68B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Vibrant Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-VIBRANT YELLOW_1.jpg'
    },
    {
        'id': 69,
        'productSlug': 'being-human-men-regular-fit-t-shirts-winery-8905271448587',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '69A_0',
        'product_counter_part': '69B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Winery',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-WINERY_1.jpg'
    },
    {
        'id': 70,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271446750',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '70A_0',
        'product_counter_part': '70B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            93.98,
            96.52,
            101.6,
            109.22,
            114.3,
            119.38,
            124.46],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-BLACK_1.jpg'
    },
    {
        'id': 71,
        'productSlug': 'being-human-men-regular-fit-t-shirts-marron-8905271446910',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '71A_0',
        'product_counter_part': '71B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Marron',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-MARRON_1.jpg'
    },
    {
        'id': 72,
        'productSlug': 'being-human-men-regular-fit-t-shirts-lake-blue-8905271446835',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '72A_0',
        'product_counter_part': '72B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lake Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-LAKE BLUE_1.jpg'
    },
    {
        'id': 73,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271447078',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '73A_0',
        'product_counter_part': '73B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-WHITE_1.jpg'
    },
    {
        'id': 74,
        'productSlug': 'being-human-men-regular-fit-t-shirts-rust-8905271446996',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '74A_0',
        'product_counter_part': '74B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Rust',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-RUST_1.jpg'
    },
    {
        'id': 75,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271453970',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24039',
        'product_path': '75A_0',
        'product_counter_part': '75B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24039-MINERAL RED_1.jpg'
    },
    {
        'id': 76,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271453895',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24039',
        'product_path': '76A_0',
        'product_counter_part': '76B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24039-LT.GREY_1.jpg'
    },
    {
        'id': 77,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450153',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '77A_0',
        'product_counter_part': '77B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24107-WHITE_1.jpg'
    },
    {
        'id': 78,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271439066',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '78A_0',
        'product_counter_part': '78B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-NAVY_1.jpg'
    },
    {
        'id': 79,
        'productSlug': 'being-human-men-slim-fit-shirts-beige-8905271438854',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '79A_0',
        'product_counter_part': '79B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Beige',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-BEIGE_1.jpg'
    },
    {
        'id': 80,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271482413',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24016',
        'product_path': '80A_0',
        'product_counter_part': '80B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [104.14, 109.22, 114.3, 121.92, 127.0, 132.08, 137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24016-BLACK_6.jpg'
    },
    {
        'id': 81,
        'productSlug': 'being-human-men-slim-fit-shirts-olive-8905271488842',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24009',
        'product_path': '81A_0',
        'product_counter_part': '81B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL'],
        'productSizeValues': [101.6, 106.68, 111.76, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24009-OLIVE_1.jpg'
    },
    {
        'id': 82,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450207',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24107',
        'product_path': '82A_0',
        'product_counter_part': '82B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24107-WHITE_1.jpg'
    },
    {
        'id': 87,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271482253',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '87A_0',
        'product_counter_part': '87B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-WHITE_1.jpg'
    },
    {
        'id': 1087,
        'productSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-white-8905271482253',
        'groupId': 'BHDI24060',
        'product_path': '87B_0',
        'product_counter_part': '87A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productTitle': 'Being Human Men Cropped Fit Denim-Dark Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24060-DARK TONE_1.jpg'
    },
    {
        'id': 88,
        'productSlug': 'being-human-men-slim-fit-shirts-sky-8905271482093',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '88A_0',
        'product_counter_part': '88B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Sky',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-SKY_1.jpg'
    },
    {
        'id': 89,
        'productSlug': 'being-human-men-slim-fit-shirts-mint-8905271482017',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '89A_0',
        'product_counter_part': '89B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Mint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-MINT_1.jpg'
    },
    {
        'id': 90,
        'productSlug': 'being-human-men-slim-fit-shirts-ltpink-8905271481935',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '90A_0',
        'product_counter_part': '90B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-LT.PINK_1.jpg'
    },
    {
        'id': 91,
        'productSlug': 'being-human-men-slim-fit-shirts-dkolive-8905271481850',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '91A_0',
        'product_counter_part': '91B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Dk.Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-DK.OLIVE_1.jpg'
    },
    {
        'id': 92,
        'productSlug': 'being-human-men-slim-fit-shirts-teal-8905271482178',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '92A_0',
        'product_counter_part': '92B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Teal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-TEAL_1.jpg'
    },
    {
        'id': 93,
        'productSlug': 'being-human-men-slim-fit-shirts-yellow-8905271482338',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '93A_0',
        'product_counter_part': '93B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-YELLOW_1.jpg'
    },
    {
        'id': 94,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271481775',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '94A_0',
        'product_counter_part': '94B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-BLACK_1.jpg'
    },
    {
        'id': 95,
        'productSlug': 'being-human-men-slim-fit-t-shirts-mineral-red-8905271470304',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24010',
        'product_path': '95A_0',
        'product_counter_part': '95B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24010-MINERAL RED_1.jpg'
    },
    {
        'id': 96,
        'productSlug': 'being-human-men-slim-fit-t-shirts-chalk-8905271470229',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24010',
        'product_path': '96A_0',
        'product_counter_part': '96B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24010-CHALK_1.jpg'
    },
    {
        'id': 97,
        'productSlug': 'being-human-men-slim-fit-t-shirts-olive-8905271470380',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24010',
        'product_path': '97A_0',
        'product_counter_part': '97B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24010-OLIVE_1.jpg'
    },
    {
        'id': 98,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271470465',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24020',
        'product_path': '98A_0',
        'product_counter_part': '98B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24020-WHITE_1.jpg'
    },
    {
        'id': 99,
        'productSlug': 'being-human-men-slim-fit-t-shirts-green-foam-8905271471110',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24034',
        'product_path': '99A_0',
        'product_counter_part': '99B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24034-GREEN FOAM_1.jpg'
    },
    {
        'id': 100,
        'productSlug': 'being-human-men-slim-fit-t-shirts-coral-8905271471035',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24034',
        'product_path': '100A_0',
        'product_counter_part': '100B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Coral',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24034-CORAL_1.jpg'
    },
    {
        'id': 101,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271468998',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467893',
        'groupId': 'BHTS24019',
        'product_path': '101A_0',
        'product_counter_part': '101B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24019-BLACK_1.jpg'
    },
    {
        'id': 1101.0,
        'productSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467893',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-black-8905271468998',
        'groupId': 'BHDI24099',
        'product_path': '101B_0',
        'product_counter_part': '101A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0]
    },
    {
        'id': 102,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469070',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467893',
        'groupId': 'BHTS24019',
        'product_path': '102A_0',
        'product_counter_part': '102B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24019-GREEN FOAM_1.jpg'
    },
    {
        'id': 103,
        'productSlug': 'being-human-men-none-t-shirts-pale-yellow-8905271442974',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24018',
        'product_path': '103A_0',
        'product_counter_part': '103B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Pale Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24018-PALE YELLOW_1.jpg'
    },
    {
        'id': 104,
        'productSlug': 'being-human-men-none-t-shirts-mint-8905271442899',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24018',
        'product_path': '104A_0',
        'product_counter_part': '104B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Mint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24018-MINT_1.jpg'
    },
    {
        'id': 105,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271443872',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24033',
        'product_path': '105A_0',
        'product_counter_part': '105B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24033-OLIVE_1.jpg'
    },
    {
        'id': 106,
        'productSlug': 'being-human-men-none-t-shirts-mineral-red-8905271443797',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24033',
        'product_path': '106A_0',
        'product_counter_part': '106B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24033-MINERAL RED_1.jpg'
    },
    {
        'id': 107,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271444282',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24041',
        'product_path': '107A_0',
        'product_counter_part': '107B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24041-BLACK_1.jpg'
    },
    {
        'id': 108,
        'productSlug': 'being-human-men-none-t-shirts-mauve-haze-8905271444190',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24041',
        'product_path': '108A_0',
        'product_counter_part': '108B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mauve Haze',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24041-MAUVE HAZE_1.jpg'
    },
    {
        'id': 109,
        'productSlug': 'being-human-men-slim-fit-t-shirts-mint-8905271470878',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24032',
        'product_path': '109A_0',
        'product_counter_part': '109B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Mint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24032-MINT_1.jpg'
    },
    {
        'id': 110,
        'productSlug': 'being-human-men-none-t-shirts-ltblue-8905271443629',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24030',
        'product_path': '110A_0',
        'product_counter_part': '110B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24030-LT.BLUE_1.jpg'
    },
    {
        'id': 111,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271443711',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24030',
        'product_path': '111A_0',
        'product_counter_part': '111B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24030-GREEN FOAM_1.jpg'
    },
    {
        'id': 112,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271469711',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24004',
        'product_path': '112A_0',
        'product_counter_part': '112B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24004-WHITE_1.jpg'
    },
    {
        'id': 113,
        'productSlug': 'being-human-men-slim-fit-t-shirts-black-8905271469988',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24007',
        'product_path': '113A_0',
        'product_counter_part': '113B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24007-BLACK_1.jpg'
    },
    {
        'id': 114,
        'productSlug': 'being-human-men-slim-fit-t-shirts-black-8905271469575',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24003',
        'product_path': '114A_0',
        'product_counter_part': '114B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24003-BLACK_1.jpg'
    },
    {
        'id': 115,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271470953',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24032',
        'product_path': '115A_0',
        'product_counter_part': '115B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24032-WHITE_1.jpg'
    },
    {
        'id': 116,
        'productSlug': 'being-human-men-slim-fit-shirts-bluepink-8905271446194',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24026',
        'product_path': '116A_0',
        'product_counter_part': '116B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Blue/Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24026-BLUE&PINK_1.jpg'
    },
    {
        'id': 117,
        'productSlug': 'being-human-men-slim-fit-shirts-bluegreen-8905271446118',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24026',
        'product_path': '117A_0',
        'product_counter_part': '117B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Blue/Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24026-BLUE&GREEN_1.jpg'
    },
    {
        'id': 118,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271450450',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24101',
        'product_path': '118A_0',
        'product_counter_part': '118B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24101-NAVY_1.jpg'
    },
    {
        'id': 1118,
        'productSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-navy-8905271450450',
        'groupId': 'BHDI24062',
        'product_path': '118B_0',
        'product_counter_part': '118A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productTitle': 'Being Human Men Cropped Fit Denim-Grey Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24062-GREY TONE_1.jpg'
    },
    {
        'id': 119,
        'productSlug': 'being-human-men-slim-fit-shirts-dk-grey-8905271450696',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24103',
        'product_path': '119A_0',
        'product_counter_part': '119B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Dk Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24103-DK GREY_1.jpg'
    },
    {
        'id': 120,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271450856',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24034',
        'product_path': '120A_0',
        'product_counter_part': '120B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24034-NAVY_1.jpg'
    },
    {
        'id': 121,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450931',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24034',
        'product_path': '121A_0',
        'product_counter_part': '121B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24034-WHITE_1.jpg'
    },
    {
        'id': 122,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271443124',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24021',
        'product_path': '122A_0',
        'product_counter_part': '122B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24021-NAVY_1.jpg'
    },
    {
        'id': 123,
        'productSlug': 'being-human-men-none-t-shirts-mauve-haze-8905271443155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24021',
        'product_path': '123A_0',
        'product_counter_part': '123B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Mauve Haze',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24021-MAUVE HAZE_1.jpg'
    },
    {
        'id': 124,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271437727',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24110',
        'product_path': '124A_0',
        'product_counter_part': '124B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24110-OLIVE_1.jpg'
    },
    {
        'id': 1124,
        'productSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-olive-8905271437727',
        'groupId': 'BHDI24003',
        'product_path': '124B_0',
        'product_counter_part': '124A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productTitle': 'Being Human Men Slim Fit Denim-Lt.Grey Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24003-LT.GREY TONE_1.jpg'
    },
    {
        'id': 125,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271440505',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS 24058',
        'product_path': '125A_0',
        'product_counter_part': '125B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24058-OLIVE_1.jpg'
    },
    {
        'id': 126,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271440598',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS 24058',
        'product_path': '126A_0',
        'product_counter_part': '126B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24058-WHITE_1.jpg'
    },
    {
        'id': 139,
        'productSlug': 'being-human-men-regular-fit-t-shirts-dusty-pink-8905271447375',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '139A_0',
        'product_counter_part': '139B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Dusty Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-DUSTY PINK_1.jpg'
    },
    {
        'id': 140,
        'productSlug': 'being-human-men-regular-fit-t-shirts-pale-yellow-8905271447603',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '140A_0',
        'product_counter_part': '140B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Pale Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-PALE YELLOW_1.jpg'
    },
    {
        'id': 141,
        'productSlug': 'being-human-men-regular-fit-t-shirts-aqua-8905271447207',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '141A_0',
        'product_counter_part': '141B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Aqua',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-AQUA_1.jpg'
    },
    {
        'id': 142,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271447290',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '142A_0',
        'product_counter_part': '142B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-BLACK_1.jpg'
    },
    {
        'id': 143,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271447535',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '143A_0',
        'product_counter_part': '143B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-NAVY_1.jpg'
    },
    {
        'id': 144,
        'productSlug': 'being-human-men-regular-fit-t-shirts-peppermint-8905271447696',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '144A_0',
        'product_counter_part': '144B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Peppermint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-PEPPERMINT_1.jpg'
    },
    {
        'id': 145,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271446576',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24001',
        'product_path': '145A_0',
        'product_counter_part': '145B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24001-LIGHT BLUE_1.jpg'
    },
    {
        'id': 146,
        'productSlug': 'being-human-men-regular-fit-t-shirts-green-foam-8905271446491',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24001',
        'product_path': '146A_0',
        'product_counter_part': '146B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24001-GREEN FOAM_1.jpg'
    },
    {
        'id': 147,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271438434',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '147A_0',
        'product_counter_part': '147B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-BLACK_1.jpg'
    },
    {
        'id': 148,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271438755',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '148A_0',
        'product_counter_part': '148B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-WHITE_1.jpg'
    },
    {
        'id': 149,
        'productSlug': 'being-human-men-none-t-shirts-sand-8905271438694',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '149A_0',
        'product_counter_part': '149B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Sand',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-SAND_1.jpg'
    },
    {
        'id': 150,
        'productSlug': 'being-human-men-none-t-shirts-coral-8905271438533',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '150A_0',
        'product_counter_part': '150B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Coral',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-CORAL_1.jpg'
    },
    {
        'id': 151,
        'productSlug': 'being-human-men-none-t-shirts-lemon-8905271438571',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '151A_0',
        'product_counter_part': '151B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Lemon',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-LEMON_1.jpg'
    },
    {
        'id': 152,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271438342',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24116',
        'product_path': '152A_0',
        'product_counter_part': '152B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24116-NAVY_1.jpg'
    },
    {
        'id': 153,
        'productSlug': 'being-human-men-none-t-shirts-coral-8905271438311',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24116',
        'product_path': '153A_0',
        'product_counter_part': '153B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Coral',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24116-CORAL_1.jpg'
    },
    {
        'id': 154,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271475958',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24065',
        'product_path': '154A_0',
        'product_counter_part': '154B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24065-BLACK_6.jpg'
    },
    {
        'id': 155,
        'productSlug': 'being-human-men-none-t-shirts-vivid-blue-8905271476047',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24065',
        'product_path': '155A_0',
        'product_counter_part': '155B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Vivid Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24065-VIVID BLUE_1.jpg'
    },
    {
        'id': 156,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271439721',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '156A_0',
        'product_counter_part': '156B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-WHITE_1.jpg'
    },
    {
        'id': 157,
        'productSlug': 'being-human-men-slim-fit-shirts-forest-green-8905271439479',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '157A_0',
        'product_counter_part': '157B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Forest Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-FOREST GREEN_1.jpg'
    },
    {
        'id': 158.0,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271439646',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '158A_0',
        'product_counter_part': '158B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]]
    },
    {
        'id': 159,
        'productSlug': 'being-human-men-slim-fit-shirts-camel-8905271439318',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '159A_0',
        'product_counter_part': '159B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Camel',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-CAMEL_1.jpg'
    },
    {
        'id': 160,
        'productSlug': 'being-human-men-slim-fit-shirts-coffee-8905271439394',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '160A_0',
        'product_counter_part': '160B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Coffee',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-COFFEE_1.jpg'
    },
    {
        'id': 161,
        'productSlug': 'being-human-men-slim-fit-shirts-midnight-blue-8905271439561',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '161A_0',
        'product_counter_part': '161B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Midnight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-MIDNIGHT BLUE_1.jpg'
    },
    {
        'id': 162,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271439233',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '162A_0',
        'product_counter_part': '162B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-BLACK_1.jpg'
    },
    {
        'id': 163,
        'productSlug': 'being-human-men-slim-fit-shirts-ltblue-8905271438991',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '163A_0',
        'product_counter_part': '163B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-LT.BLUE_1.jpg'
    },
    {
        'id': 164,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271438922',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '164A_0',
        'product_counter_part': '164B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-BLACK_1.jpg'
    },
    {
        'id': 174,
        'productSlug': 'being-human-men-regular-fit-t-shirts-chalk-8905271446651',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24002',
        'product_path': '174A_0',
        'product_counter_part': '174B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24002-CHALK_1.jpg'
    },
    {
        'id': 175,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271446736',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24002',
        'product_path': '175A_0',
        'product_counter_part': '175B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24002-LIGHT BLUE_1.jpg'
    },
    {
        'id': 176,
        'productSlug': 'being-human-men-regular-fit-t-shirts-yellow-8905271454502',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24043',
        'product_path': '176A_0',
        'product_counter_part': '176B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24043-YELLOW_1.jpg'
    },
    {
        'id': 177,
        'productSlug': 'being-human-men-regular-fit-t-shirts-blue-8905271454342',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24043',
        'product_path': '177A_0',
        'product_counter_part': '177B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24043-BLUE_1.jpg'
    },
    {
        'id': 178,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mauve-blue-8905271454410',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24043',
        'product_path': '178A_0',
        'product_counter_part': '178B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Mauve Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24043-MAUVE BLUE_6.jpg'
    },
    {
        'id': 179,
        'productSlug': 'being-human-men-semi-fit-t-shirts-powder-pink-8905271470687',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24022',
        'product_path': '179A_0',
        'product_counter_part': '179B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Semi Fit T-Shirts-Powder Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24022-POWDER PINK_1.jpg'
    },
    {
        'id': 1179,
        'productSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'productCounterPartSlug': 'being-human-men-semi-fit-t-shirts-powder-pink-8905271470687',
        'groupId': 'BHDI24099',
        'product_path': '179B_0',
        'product_counter_part': '179A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productTitle': 'Being Human Men Skinnyfit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24099-MID TONE_1.jpg'
    },
    {
        'id': 180,
        'productSlug': 'being-human-men-semi-fit-t-shirts-green-foam-8905271470601',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24022',
        'product_path': '180A_0',
        'product_counter_part': '180B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Semi Fit T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24022-GREEN FOAM_1.jpg'
    },
    {
        'id': 181,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271469872',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24005',
        'product_path': '181A_0',
        'product_counter_part': '181B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24005-WHITE_1.jpg'
    },
    {
        'id': 182,
        'productSlug': 'being-human-men-slim-fit-t-shirts-ltblue-8905271469773',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24005',
        'product_path': '182A_0',
        'product_counter_part': '182B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24005-LT.BLUE_6.jpg'
    },
    {
        'id': 183,
        'productSlug': 'being-human-men-none-t-shirts-dusty-pink-8905271468097',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '183A_0',
        'product_counter_part': '183B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-Dusty Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-DUSTY PINK_1.jpg'
    },
    {
        'id': 184,
        'productSlug': 'being-human-men-none-t-shirts-yellow-8905271468394',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '184A_0',
        'product_counter_part': '184B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-YELLOW_1.jpg'
    },
    {
        'id': 185,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271468325',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '185A_0',
        'product_counter_part': '185B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-WHITE_1.jpg'
    },
    {
        'id': 187,
        'productSlug': 'being-human-men-none-t-shirts-pista-8905271468240',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '187A_0',
        'product_counter_part': '187B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-Pista',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-PISTA_1.jpg'
    },
    {
        'id': 188,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271468172',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '188A_0',
        'product_counter_part': '188B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-NAVY_1.jpg'
    },
    {
        'id': 189,
        'productSlug': 'being-human-men-regular-fit-t-shirts-olive-8905271454113',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24005',
        'product_path': '189A_0',
        'product_counter_part': '189B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24005-OLIVE_1.jpg'
    },
    {
        'id': 190,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271452515',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24034',
        'product_path': '190A_0',
        'product_counter_part': '190B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24034-LIGHT BLUE_1.jpg'
    },
    {
        'id': 191,
        'productSlug': 'being-human-men-regular-fit-t-shirts-deep-ocean-8905271452430',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24034',
        'product_path': '191A_0',
        'product_counter_part': '191B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24034-DEEP OCEAN_1.jpg'
    },
    {
        'id': 192,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271453079',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24025',
        'product_path': '192A_0',
        'product_counter_part': '192B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24025-WHITE_1.jpg'
    },
    {
        'id': 193,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271453710',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24006',
        'product_path': '193A_0',
        'product_counter_part': '193B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24006-WHITE_1.jpg'
    },
    {
        'id': 194,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271453635',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24006',
        'product_path': '194A_0',
        'product_counter_part': '194B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24006-BLACK_1.jpg'
    },
    {
        'id': 195,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271488996',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24046',
        'product_path': '195A_0',
        'product_counter_part': '195B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24046-BLACK_1.jpg'
    },
    {
        'id': 196,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450122',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24072',
        'product_path': '196A_0',
        'product_counter_part': '196B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24072-WHITE_1.jpg'
    },
    {
        'id': 197,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271450047',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24072',
        'product_path': '197A_0',
        'product_counter_part': '197B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24072-BLACK_1.jpg'
    },
    {
        'id': 198,
        'productSlug': 'being-human-men-slim-fit-shirts-grey-8905271460619',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24110',
        'product_path': '198A_0',
        'product_counter_part': '198B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24110-GREY_1.jpg'
    },
    {
        'id': 199,
        'productSlug': 'being-human-men-slim-fit-shirts-green-8905271455776',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24037',
        'product_path': '200A_0',
        'product_counter_part': '200B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78,
            44.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24037-GREEN_1.jpg'
    },
    {
        'id': 200,
        'productSlug': 'being-human-men-slim-fit-shirts-blue-8905271455691',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'product_path': '199A_0',
        'product_counter_part': '199B_0',
        'groupId': 'BHMLS24037',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24037-BLUE_1.jpg'
    },
    {
        'id': 203,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271469360',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24040',
        'product_path': '203A_0',
        'product_counter_part': '203B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24040-DEEP OCEAN_6.jpg'
    },
    {
        'id': 204,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271469308',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24040',
        'product_path': '204A_0',
        'product_counter_part': '204B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24040-BLACK_1.jpg'
    },
    {
        'id': 205,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271443377',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24024',
        'product_path': '205A_0',
        'product_counter_part': '205B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24024-BLACK_1.jpg'
    },
    {
        'id': 206,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271443216',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24024',
        'product_path': '206A_0',
        'product_counter_part': '206B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24024-WHITE_1.jpg'
    },
    {
        'id': 207,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271444435',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24042',
        'product_path': '207A_0',
        'product_counter_part': '207B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24042-WHITE_1.jpg'
    },
    {
        'id': 208,
        'productSlug': 'being-human-men-none-t-shirts-charcoal-8905271444350',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24042',
        'product_path': '208A_0',
        'product_counter_part': '208B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24042-CHARCOAL_1.jpg'
    },
    {
        'id': 209,
        'productSlug': 'being-human-men-none-t-shirts-snow-white-8905271472360',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24358',
        'product_path': '209A_0',
        'product_counter_part': '209B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Snow White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24358-SNOW WHITE_6.jpg'
    },
    {
        'id': 210,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271440680',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24063',
        'product_path': '210A_0',
        'product_counter_part': '210B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24063-BLACK_1.jpg'
    },
    {
        'id': 211,
        'productSlug': 'being-human-men-none-t-shirts-wine-8905271440758',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24063',
        'product_path': '211A_0',
        'product_counter_part': '211B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None T-Shirts-Wine',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24063-WINE_6.jpg'
    },
    {
        'id': 212,
        'productSlug': 'being-human-men-none-crew-neck-black-8905271490111',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24064',
        'product_path': '212A_0',
        'product_counter_part': '212B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            134.62,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None Crew Neck-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24064-BLACK_1.jpg'
    },
    {
        'id': 213,
        'productSlug': 'being-human-men-none-crew-neck-black-8905271489870',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24043',
        'product_path': '213A_0',
        'product_counter_part': '213B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            134.62,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None Crew Neck-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24043-BLACK_1.jpg'
    },
    {
        'id': 214,
        'productSlug': 'being-human-men-none-t-shirts-ltblue-8905271443605',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS 24029',
        'product_path': '214A_0',
        'product_counter_part': '214B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24029-LT.BLUE_1.jpg'
    },
    {
        'id': 221,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271464167',
        'groupId': 'BHP24051',
        'product_path': '221A_0',
        'product_counter_part': '221B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-WHITE_1.jpg'
    },
    {
        'id': 1221,
        'productSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271464167',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        'groupId': 'BHDI24064',
        'product_path': '221B_0',
        'product_counter_part': '221A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24064-LIGHT TONE_1.jpg'
    },
    {
        'id': 222,
        'productSlug': 'being-human-men-regular-fit-t-shirts-yellow-8905271452737',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463863',
        'groupId': 'BHP24051',
        'product_path': '222A_0',
        'product_counter_part': '222B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-YELLOW_6.jpg'
    },
    {
        'id': 1222,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463863',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-yellow-8905271452737',
        'groupId': 'BHDI24056',
        'product_path': '222B_0',
        'product_counter_part': '222A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24056-MID TONE_1.jpg'
    },
    {
        'id': 223,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271452591',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24051',
        'product_path': '223A_0',
        'product_counter_part': '223B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-NAVY_1.jpg'
    },
    {
        'id': 224,
        'productSlug': 'being-human-men-regular-fit-t-shirts-midlight-blue-8905271458319',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271476276',
        'groupId': 'BHP24045',
        'product_path': '224A_0',
        'product_counter_part': '224B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Midlight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24045-MIDLIGHT BLUE_1.jpg'
    },
    {
        'id': 1224,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271476276',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-midlight-blue-8905271458319',
        'groupId': 'BHDI24163',
        'product_path': '224B_0',
        'product_counter_part': '224A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24163-MID TONE_1.jpg'
    },
    {
        'id': 225,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271458234',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477280',
        'groupId': 'BHP24045',
        'product_path': '225A_0',
        'product_counter_part': '225B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24045-LT.GREY_1.jpg'
    },
    {
        'id': 1225,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477280',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271458234',
        'groupId': 'BHDI24178',
        'product_path': '225B_0',
        'product_counter_part': '225A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24178-MID TONE_1.jpg'
    },
    {
        'id': 226,
        'productSlug': 'being-human-men-regular-fit-t-shirts-red-8905271458395',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24045',
        'product_path': '226A_0',
        'product_counter_part': '226B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24045-RED_1.jpg'
    },
    {
        'id': 227.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271476511',
        'groupId': 'BHP24008',
        'product_path': '227A_0',
        'product_counter_part': '227B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1227,
        'productSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271476511',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'groupId': 'BHDI24166',
        'product_path': '227B_0',
        'product_counter_part': '227A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24166-LIGHT TONE_1.jpg'
    },
    {
        'id': 228.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463023',
        'groupId': 'BHP24008',
        'product_path': '228A_0',
        'product_counter_part': '228B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1228,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463023',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'groupId': 'BHDI24020',
        'product_path': '228B_0',
        'product_counter_part': '228A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24020-MID TONE_1.jpg'
    },
    {
        'id': 231,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271437864',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24112',
        'product_path': '231A_0',
        'product_counter_part': '231B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24112-WHITE_6.jpg'
    },
    {
        'id': 232,
        'productSlug': 'being-human-men-none-t-shirts-snow-white-8905271471431',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24351',
        'product_path': '232A_0',
        'product_counter_part': '232B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Snow White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24351-SNOW WHITE_6.jpg'
    },
    {
        'id': 233,
        'productSlug': 'being-human-men-none-t-shirts-snowwhite-8905271471585',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24352',
        'product_path': '233A_0',
        'product_counter_part': '233B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Snowwhite',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24352-SNOWWHITE_6.jpg'
    },
    {
        'id': 234,
        'productSlug': 'being-human-men-none-t-shirts-black-beauty-8905271471554',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24352',
        'product_path': '234A_0',
        'product_counter_part': '234B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Black Beauty',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24352-BLACK BEAUTY_6.jpg'
    },
    {
        'id': 235,
        'productSlug': 'being-human-men-none-crew-neck-powder-pink-8905271483700',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24017',
        'product_path': '235A_0',
        'product_counter_part': '235B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            134.62,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None Crew Neck-Powder Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24017-POWDER PINK_6.jpg'
    },
    {
        'id': 236,
        'productSlug': 'being-human-men-none-crew-neck-black-8905271489887',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24050',
        'product_path': '236A_0',
        'product_counter_part': '236B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None Crew Neck-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24050-BLACK_1.jpg'
    },
    {
        'id': 238,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450436',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'groupId': 'BHMLS24117',
        'product_path': '238A_0',
        'product_counter_part': '238B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24117-WHITE_1.jpg'
    },
    {
        'id': 1238,
        'productSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-white-8905271450436',
        'groupId': 'BHDI24039',
        'product_path': '238B_0',
        'product_counter_part': '238A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Dark Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24039-DARK TONE_1.jpg'
    },
    {
        'id': 239,
        'productSlug': 'being-human-men-slim-fit-shirts-ltblue-8905271450283',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'groupId': 'BHMLS24117',
        'product_path': '239A_0',
        'product_counter_part': '239B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24117-LT.BLUE_1.jpg'
    },
    {
        'id': 240,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450436',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'groupId': 'BHMLS24117',
        'product_path': '240A_0',
        'product_counter_part': '240B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24117-WHITE_1.jpg'
    },
    {
        'id': 241.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475156',
        'groupId': 'BHP240101',
        'product_path': '241A_0',
        'product_counter_part': '241B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1241,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475156',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'groupId': 'BHDI24065',
        'product_path': '241B_0',
        'product_counter_part': '241A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24065-MID TONE_1.jpg'
    },
    {
        'id': 242.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-black-tone-8905271464099',
        'groupId': 'BHP240101',
        'product_path': '242A_0',
        'product_counter_part': '242B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1242,
        'productSlug': 'being-human-men-cropped-fit-denim-black-tone-8905271464099',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'groupId': 'BHDI24063',
        'product_path': '242B_0',
        'product_counter_part': '242A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Black Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24063-BLACK TONE_1.jpg'
    },
    {
        'id': 243,
        'productSlug': 'being-human-men-regular-fit-t-shirts-olive-8905271454663',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24052',
        'product_path': '243A_0',
        'product_counter_part': '243B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24052-OLIVE_1.jpg'
    },
    {
        'id': 244,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271454588',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24052',
        'product_path': '244A_0',
        'product_counter_part': '244B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24052-BLACK_1.jpg'
    },
    {
        'id': 245,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271440260',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24049',
        'product_path': '245A_0',
        'product_counter_part': '245B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24049-DEEP OCEAN_5.jpg'
    },
    {
        'id': 246.0,
        'productSlug': 'eing-human-men-none-t-shirts-yellow-8905271440413',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24049',
        'product_path': '246A_0',
        'product_counter_part': '246B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24049-YELLOW_1.jpg',
    }];
export const beingHumansProductList = [
    {
        id: 1,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469155",
        src: bh1,
        name: "being-human-men-none-t-shirts-green-foam-8905271469155",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24031",
        product_path: "BHTS24031_0",
        product_counter_part: "BHDI24099_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 2,
        productSlug: "being-human-men-none-t-shirts-jet-black-8905271445012",
        src: bh2,
        name: "being-human-men-none-t-shirts-jet-black-8905271445012",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-fit-denim-mid-tone-8905271476627",
        groupId: "BHTS24079",
        product_path: "BHT24079_0",
        product_counter_part: "BHDI24168_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 3,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24143_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000122",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 4,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        src: bh4,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 5,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        src: bh5,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 6,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        src: bh6,
        name: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 7,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        src: bh7,
        name: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 8,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        src: bh8,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24035",
        product_path: "BHP24035_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 9,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        src: bh9,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24038",
        product_path: "BHP24038_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 10,
        productSlug: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        src: bh10,
        name: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24046",
        product_path: "BHP24046_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 11,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002CO_0",
        product_counter_part: "BHD124059_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000384",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 12,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        src: bh12,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24014",
        product_path: "BHMLS24014_0",
        product_counter_part: "BHDI24093_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 13,
        src: SaveTheTreesMen,
        product_path: "BHFLS24001_0",
        product_counter_part: "BHKTP24005_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000128",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 14,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271445876",
        src: bh14,
        name: "being-human-men-slim-fit-shirts-black-8905271445876",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-straight-fit-denim-mid-tone-8905271474401",
        groupId: "BHMLS24017",
        product_path: "BHMLS24017_0",
        product_counter_part: "BHDI24137_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                73.66,
                86.66
            ],
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                96.52,
                113.55
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 15,
        productSlug: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        src: bh15,
        name: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "BHP24003_0",
        product_counter_part: "BHDI24030_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 16,
        productSlug: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        src: bh16,
        name: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24002",
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24059_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 17,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000380",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 18,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000550",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 19,
        productSlug: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        src: bh19,
        name: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-black-tone-8905271466130",
        groupId: "BHMSS24027",
        product_path: "BHMSS24027_0",
        product_counter_part: "BHDSI24195_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 20,
        productSlug: "being-human-men-none-t-shirts-coffee-8905271442493",
        src: bh20,
        name: "being-human-men-none-t-shirts-coffee-8905271442493",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "20A_0",
        product_counter_part: "20B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 21,
        productSlug: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        src: bh21,
        name: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "21A_0",
        product_counter_part: "21B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 22,
        productSlug: "being-human-men-none-t-shirts-charcoal-8905271442578",
        src: bh22,
        name: "being-human-men-none-t-shirts-charcoal-8905271442578",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "22A_0",
        product_counter_part: "22B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 23,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        src: bh23,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "23A_0",
        product_counter_part: "23B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 24,
        productSlug: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        src: bh24,
        name: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "24A_0",
        product_counter_part: "24B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 25,
        productSlug: "being-human-men-none-t-shirts-white-8905271444114",
        src: bh25,
        name: "being-human-men-none-t-shirts-white-8905271444114",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "25A_0",
        product_counter_part: "25B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 26,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        src: bh26,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24006",
        product_path: "26A_0",
        product_counter_part: "26B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 27,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        src: bh27,
        name: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "27A_0",
        product_counter_part: "27B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 28,
        productSlug: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        src: bh28,
        name: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "28A_0",
        product_counter_part: "28B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 29,
        productSlug: "being-human-men-none-t-shirts-navy-8905271444770",
        src: bh29,
        name: "being-human-men-none-t-shirts-navy-8905271444770",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "29A_0",
        product_counter_part: "29B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 30,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        src: bh30,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "30A_0",
        product_counter_part: "30B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 31,
        productSlug: "being-human-men-none-t-shirts-chalk-8905271442738",
        src: bh31,
        name: "being-human-men-none-t-shirts-chalk-8905271442738",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "31A_0",
        product_counter_part: "31B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 32,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        src: bh32,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "32A_0",
        product_counter_part: "32B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 33,
        src: SaveTheTreesMen,
        product_path: "33A_0",
        product_counter_part: "33B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 34,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271460213",
        src: bh34,
        name: "being-human-men-slim-fit-shirts-black-8905271460213",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "34A_0",
        product_counter_part: "34B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 35,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        src: bh35,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "35A_0",
        product_counter_part: "35B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 36,
        productSlug: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        src: bh36,
        name: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "36A_0",
        product_counter_part: "36B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 37,
        productSlug: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        src: bh37,
        name: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "37A_0",
        product_counter_part: "37B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 38,
        src: SaveTheTreesMen,
        product_path: "38A_0",
        product_counter_part: "38B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 39,
        src: SaveTheTreesMen,
        product_path: "39A_0",
        product_counter_part: "39B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 40,
        src: SaveTheTreesMen,
        product_path: "40A_0",
        product_counter_part: "40B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 41,
        src: SaveTheTreesMen,
        product_path: "41A_0",
        product_counter_part: "41B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 42,
        productSlug: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        src: bh42,
        name: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "42A_0",
        product_counter_part: "42B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 43,
        productSlug: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        src: bh43,
        name: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "43A_0",
        product_counter_part: "43B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 44,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        src: bh44,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "44A_0",
        product_counter_part: "44B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 45,
        productSlug: "being-human-men-none-t-shirts-olive-8905271442257",
        src: bh45,
        name: "being-human-men-none-t-shirts-olive-8905271442257",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "45A_0",
        product_counter_part: "45B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 46,
        productSlug: "being-human-men-none-t-shirts-black-8905271443476",
        src: bh46,
        name: "being-human-men-none-t-shirts-black-8905271443476",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "46A_0",
        product_counter_part: "46B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 47,
        productSlug: "being-human-men-none-t-shirts-white-8905271443391",
        src: bh47,
        name: "being-human-men-none-t-shirts-white-8905271443391",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "47A_0",
        product_counter_part: "47B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 48,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        src: bh48,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "48A_0",
        product_counter_part: "48B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 49,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        src: bh49,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "49A_0",
        product_counter_part: "49B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 50,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        src: bh50,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "50A_0",
        product_counter_part: "50B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 51,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        src: bh51,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "51A_0",
        product_counter_part: "51B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 52,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        src: bh52,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "52A_0",
        product_counter_part: "52B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 53,
        productSlug: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        src: bh53,
        name: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "53A_0",
        product_counter_part: "53B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 54,
        productSlug: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        src: bh54,
        name: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24024",
        product_path: "54A_0",
        product_counter_part: "54B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 55,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        src: bh55,
        name: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "55A_0",
        product_counter_part: "55B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 56,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        src: bh56,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "56A_0",
        product_counter_part: "56B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 57,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        src: bh57,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "57A_0",
        product_counter_part: "57B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 58,
        productSlug: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        src: bh58,
        name: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24065",
        product_path: "58A_0",
        product_counter_part: "58B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 59,
        productSlug: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        src: bh59,
        name: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "59A_0",
        product_counter_part: "59B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 60,
        productSlug: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        src: bh60,
        name: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "60A_0",
        product_counter_part: "60B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 61,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        src: bh61,
        name: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "61A_0",
        product_counter_part: "61B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 62,
        productSlug: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        src: bh62,
        name: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "62A_0",
        product_counter_part: "62B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 63,
        productSlug: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        src: bh63,
        name: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "63A_0",
        product_counter_part: "63B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 64,
        productSlug: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        src: bh64,
        name: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "64A_0",
        product_counter_part: "64B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 65,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        src: bh65,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "65A_0",
        product_counter_part: "65B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 66,
        productSlug: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        src: bh66,
        name: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "66A_0",
        product_counter_part: "66B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 67,
        productSlug: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        src: bh67,
        name: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "67A_0",
        product_counter_part: "67B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 68,
        productSlug: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        src: bh68,
        name: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "68A_0",
        product_counter_part: "68B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 69,
        productSlug: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        src: bh69,
        name: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "69A_0",
        product_counter_part: "69B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 70,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        src: bh70,
        name: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "70A_0",
        product_counter_part: "70B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            93.98,
            96.52,
            101.6,
            109.22,
            114.3,
            119.38,
            124.46
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 71,
        productSlug: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        src: bh71,
        name: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "71A_0",
        product_counter_part: "71B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 72,
        productSlug: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        src: bh72,
        name: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "72A_0",
        product_counter_part: "72B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 73,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        src: bh73,
        name: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "73A_0",
        product_counter_part: "73B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 74,
        productSlug: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        src: bh74,
        name: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "74A_0",
        product_counter_part: "74B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 75,
        productSlug: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        src: bh75,
        name: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "75A_0",
        product_counter_part: "75B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 76,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        src: bh76,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "76A_0",
        product_counter_part: "76B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 77,
        src: SaveTheTreesMen,
        product_path: "77A_0",
        product_counter_part: "77B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 78,
        src: SaveTheTreesMen,
        product_path: "78A_0",
        product_counter_part: "78B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 79,
        src: SaveTheTreesMen,
        product_path: "79A_0",
        product_counter_part: "79B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 80,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271482413",
        src: bh80,
        name: "being-human-men-slim-fit-shirts-black-8905271482413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24016",
        product_path: "80A_0",
        product_counter_part: "80B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 81,
        src: SaveTheTreesMen,
        product_path: "81A_0",
        product_counter_part: "81B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 82,
        src: SaveTheTreesMen,
        product_path: "82A_0",
        product_counter_part: "82B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 83,
        src: SaveTheTreesMen,
        product_path: "83A_0",
        product_counter_part: "83B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 84,
        src: SaveTheTreesMen,
        product_path: "84A_0",
        product_counter_part: "84B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 85,
        src: SaveTheTreesMen,
        product_path: "85A_0",
        product_counter_part: "85B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 86,
        src: SaveTheTreesMen,
        product_path: "86A_0",
        product_counter_part: "86B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 87,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271482253",
        src: bh87,
        name: "being-human-men-slim-fit-shirts-white-8905271482253",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "87A_0",
        product_counter_part: "87B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 88,
        productSlug: "being-human-men-slim-fit-shirts-sky-8905271482093",
        src: bh88,
        name: "being-human-men-slim-fit-shirts-sky-8905271482093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "88A_0",
        product_counter_part: "88B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 89,
        productSlug: "being-human-men-slim-fit-shirts-mint-8905271482017",
        src: bh89,
        name: "being-human-men-slim-fit-shirts-mint-8905271482017",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "89A_0",
        product_counter_part: "89B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 90,
        productSlug: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        src: bh90,
        name: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "90A_0",
        product_counter_part: "90B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 91,
        productSlug: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        src: bh91,
        name: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "91A_0",
        product_counter_part: "91B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 92,
        productSlug: "being-human-men-slim-fit-shirts-teal-8905271482178",
        src: bh92,
        name: "being-human-men-slim-fit-shirts-teal-8905271482178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "92A_0",
        product_counter_part: "92B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 93,
        productSlug: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        src: bh93,
        name: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "93A_0",
        product_counter_part: "93B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 94,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271481775",
        src: bh94,
        name: "being-human-men-slim-fit-shirts-black-8905271481775",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "94A_0",
        product_counter_part: "94B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 95,
        productSlug: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        src: bh95,
        name: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "95A_0",
        product_counter_part: "95B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 96,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        src: bh96,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "96A_0",
        product_counter_part: "96B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 97,
        productSlug: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        src: bh97,
        name: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "97A_0",
        product_counter_part: "97B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 98,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        src: bh98,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24020",
        product_path: "98A_0",
        product_counter_part: "98B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 99,
        productSlug: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        src: bh99,
        name: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "99A_0",
        product_counter_part: "99B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 100,
        productSlug: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        src: bh100,
        name: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "100A_0",
        product_counter_part: "100B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 101,
        productSlug: "being-human-men-none-t-shirts-black-8905271468998",
        src: bh101,
        name: "being-human-men-none-t-shirts-black-8905271468998",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "101A_0",
        product_counter_part: "101B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 102,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469070",
        src: bh102,
        name: "being-human-men-none-t-shirts-green-foam-8905271469070",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "102A_0",
        product_counter_part: "102B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 103,
        productSlug: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        src: bh103,
        name: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "103A_0",
        product_counter_part: "103B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 104,
        productSlug: "being-human-men-none-t-shirts-mint-8905271442899",
        src: bh104,
        name: "being-human-men-none-t-shirts-mint-8905271442899",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "104A_0",
        product_counter_part: "104B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 105,
        productSlug: "being-human-men-none-t-shirts-olive-8905271443872",
        src: bh105,
        name: "being-human-men-none-t-shirts-olive-8905271443872",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "105A_0",
        product_counter_part: "105B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 106,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        src: bh106,
        name: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "106A_0",
        product_counter_part: "106B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 107,
        productSlug: "being-human-men-none-t-shirts-black-8905271444282",
        src: bh107,
        name: "being-human-men-none-t-shirts-black-8905271444282",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "107A_0",
        product_counter_part: "107B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 108,
        productSlug: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        src: bh108,
        name: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "108A_0",
        product_counter_part: "108B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 109,
        productSlug: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        src: bh109,
        name: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "109A_0",
        product_counter_part: "109B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 110,
        productSlug: "being-human-men-none-t-shirts-ltblue-8905271443629",
        src: bh110,
        name: "being-human-men-none-t-shirts-ltblue-8905271443629",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "110A_0",
        product_counter_part: "110B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 111,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271443711",
        src: bh111,
        name: "being-human-men-none-t-shirts-green-foam-8905271443711",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "111A_0",
        product_counter_part: "111B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 112,
        src: SaveTheTreesMen,
        product_path: "112A_0",
        product_counter_part: "112B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 113,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        src: bh113,
        name: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24007",
        product_path: "113A_0",
        product_counter_part: "113B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 114,
        src: SaveTheTreesMen,
        product_path: "114A_0",
        product_counter_part: "114B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 115,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        src: bh115,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "115A_0",
        product_counter_part: "115B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 116,
        productSlug: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        src: bh116,
        name: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "116A_0",
        product_counter_part: "116B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 117,
        productSlug: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        src: bh117,
        name: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "117A_0",
        product_counter_part: "117B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 118,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450450",
        src: bh118,
        name: "being-human-men-slim-fit-shirts-navy-8905271450450",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24101",
        product_path: "118A_0",
        product_counter_part: "118B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 119,
        productSlug: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        src: bh119,
        name: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24103",
        product_path: "119A_0",
        product_counter_part: "119B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 120,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450856",
        src: bh120,
        name: "being-human-men-slim-fit-shirts-navy-8905271450856",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "120A_0",
        product_counter_part: "120B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 121,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271450931",
        src: bh121,
        name: "being-human-men-slim-fit-shirts-white-8905271450931",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "121A_0",
        product_counter_part: "121B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 122,
        src: SaveTheTreesMen,
        product_path: "122A_0",
        product_counter_part: "122B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 123,
        src: SaveTheTreesMen,
        product_path: "123A_0",
        product_counter_part: "123B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 124,
        src: SaveTheTreesMen,
        product_path: "124A_0",
        product_counter_part: "124B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 125,
        src: SaveTheTreesMen,
        product_path: "125A_0",
        product_counter_part: "125B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 126,
        src: SaveTheTreesMen,
        product_path: "126A_0",
        product_counter_part: "126B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 127,
        src: SaveTheTreesMen,
        product_path: "127A_0",
        product_counter_part: "127B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 128,
        src: SaveTheTreesMen,
        product_path: "128A_0",
        product_counter_part: "128B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 129,
        src: SaveTheTreesMen,
        product_path: "129A_0",
        product_counter_part: "129B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 130,
        src: SaveTheTreesMen,
        product_path: "130A_0",
        product_counter_part: "130B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 131,
        src: SaveTheTreesMen,
        product_path: "131A_0",
        product_counter_part: "131B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 132,
        src: SaveTheTreesMen,
        product_path: "132A_0",
        product_counter_part: "132B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 133,
        src: SaveTheTreesMen,
        product_path: "133A_0",
        product_counter_part: "133B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 134,
        src: SaveTheTreesMen,
        product_path: "134A_0",
        product_counter_part: "134B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 135,
        src: SaveTheTreesMen,
        product_path: "135A_0",
        product_counter_part: "135B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 136,
        src: SaveTheTreesMen,
        product_path: "136A_0",
        product_counter_part: "136B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 137,
        src: SaveTheTreesMen,
        product_path: "137A_0",
        product_counter_part: "137B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 138,
        src: SaveTheTreesMen,
        product_path: "138A_0",
        product_counter_part: "138B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 139,
        src: SaveTheTreesMen,
        product_path: "139A_0",
        product_counter_part: "139B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 140,
        src: SaveTheTreesMen,
        product_path: "140A_0",
        product_counter_part: "140B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 141,
        src: SaveTheTreesMen,
        product_path: "141A_0",
        product_counter_part: "141B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 142,
        src: SaveTheTreesMen,
        product_path: "142A_0",
        product_counter_part: "142B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 143,
        src: SaveTheTreesMen,
        product_path: "143A_0",
        product_counter_part: "143B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 144,
        src: SaveTheTreesMen,
        product_path: "144A_0",
        product_counter_part: "144B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 145,
        src: SaveTheTreesMen,
        product_path: "145A_0",
        product_counter_part: "145B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 146,
        src: SaveTheTreesMen,
        product_path: "146A_0",
        product_counter_part: "146B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 147,
        src: SaveTheTreesMen,
        product_path: "147A_0",
        product_counter_part: "147B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 148,
        src: SaveTheTreesMen,
        product_path: "148A_0",
        product_counter_part: "148B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 149,
        src: SaveTheTreesMen,
        product_path: "149A_0",
        product_counter_part: "149B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 150,
        src: SaveTheTreesMen,
        product_path: "150A_0",
        product_counter_part: "150B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 151,
        src: SaveTheTreesMen,
        product_path: "151A_0",
        product_counter_part: "151B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 152,
        src: SaveTheTreesMen,
        product_path: "152A_0",
        product_counter_part: "152B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 153,
        src: SaveTheTreesMen,
        product_path: "153A_0",
        product_counter_part: "153B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 154,
        src: SaveTheTreesMen,
        product_path: "154A_0",
        product_counter_part: "154B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 155,
        src: SaveTheTreesMen,
        product_path: "155A_0",
        product_counter_part: "155B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 156,
        src: SaveTheTreesMen,
        product_path: "156A_0",
        product_counter_part: "156B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 157,
        src: SaveTheTreesMen,
        product_path: "157A_0",
        product_counter_part: "157B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 158,
        src: SaveTheTreesMen,
        product_path: "158A_0",
        product_counter_part: "158B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 159,
        src: SaveTheTreesMen,
        product_path: "159A_0",
        product_counter_part: "159B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 160,
        src: SaveTheTreesMen,
        product_path: "160A_0",
        product_counter_part: "160B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 161,
        src: SaveTheTreesMen,
        product_path: "161A_0",
        product_counter_part: "161B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 162,
        src: SaveTheTreesMen,
        product_path: "162A_0",
        product_counter_part: "162B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 163,
        src: SaveTheTreesMen,
        product_path: "163A_0",
        product_counter_part: "163B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 164,
        src: SaveTheTreesMen,
        product_path: "164A_0",
        product_counter_part: "164B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 165,
        src: SaveTheTreesMen,
        product_path: "165A_0",
        product_counter_part: "165B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 166,
        src: SaveTheTreesMen,
        product_path: "166A_0",
        product_counter_part: "166B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 167,
        src: SaveTheTreesMen,
        product_path: "167A_0",
        product_counter_part: "167B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 168,
        src: SaveTheTreesMen,
        product_path: "168A_0",
        product_counter_part: "168B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 169,
        src: SaveTheTreesMen,
        product_path: "169A_0",
        product_counter_part: "169B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 170,
        src: SaveTheTreesMen,
        product_path: "170A_0",
        product_counter_part: "170B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 171,
        src: SaveTheTreesMen,
        product_path: "171A_0",
        product_counter_part: "171B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 172,
        src: SaveTheTreesMen,
        product_path: "172A_0",
        product_counter_part: "172B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 173,
        src: SaveTheTreesMen,
        product_path: "173A_0",
        product_counter_part: "173B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 174,
        src: SaveTheTreesMen,
        product_path: "174A_0",
        product_counter_part: "174B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 175,
        src: SaveTheTreesMen,
        product_path: "175A_0",
        product_counter_part: "175B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 176,
        src: SaveTheTreesMen,
        product_path: "176A_0",
        product_counter_part: "176B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 177,
        src: SaveTheTreesMen,
        product_path: "177A_0",
        product_counter_part: "177B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 178,
        src: SaveTheTreesMen,
        product_path: "178A_0",
        product_counter_part: "178B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 179,
        src: SaveTheTreesMen,
        product_path: "179A_0",
        product_counter_part: "179B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 180,
        src: SaveTheTreesMen,
        product_path: "180A_0",
        product_counter_part: "180B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 181,
        src: SaveTheTreesMen,
        product_path: "181A_0",
        product_counter_part: "181B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 182,
        src: SaveTheTreesMen,
        product_path: "182A_0",
        product_counter_part: "182B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 183,
        src: SaveTheTreesMen,
        product_path: "183A_0",
        product_counter_part: "183B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 184,
        src: SaveTheTreesMen,
        product_path: "184A_0",
        product_counter_part: "184B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 185,
        src: SaveTheTreesMen,
        product_path: "185A_0",
        product_counter_part: "185B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 186,
        src: SaveTheTreesMen,
        product_path: "186A_0",
        product_counter_part: "186B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 187,
        src: SaveTheTreesMen,
        product_path: "187A_0",
        product_counter_part: "187B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 188,
        src: SaveTheTreesMen,
        product_path: "188A_0",
        product_counter_part: "188B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 189,
        src: SaveTheTreesMen,
        product_path: "189A_0",
        product_counter_part: "189B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 190,
        src: SaveTheTreesMen,
        product_path: "190A_0",
        product_counter_part: "190B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 191,
        src: SaveTheTreesMen,
        product_path: "191A_0",
        product_counter_part: "191B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 192,
        src: SaveTheTreesMen,
        product_path: "192A_0",
        product_counter_part: "192B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 193,
        src: SaveTheTreesMen,
        product_path: "193A_0",
        product_counter_part: "193B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 194,
        src: SaveTheTreesMen,
        product_path: "194A_0",
        product_counter_part: "194B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 195,
        src: SaveTheTreesMen,
        product_path: "195A_0",
        product_counter_part: "195B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 196,
        src: SaveTheTreesMen,
        product_path: "196A_0",
        product_counter_part: "196B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 197,
        src: SaveTheTreesMen,
        product_path: "197A_0",
        product_counter_part: "197B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 198,
        src: SaveTheTreesMen,
        product_path: "198A_0",
        product_counter_part: "198B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 199,
        src: SaveTheTreesMen,
        product_path: "199A_0",
        product_counter_part: "199B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 200,
        src: SaveTheTreesMen,
        product_path: "200A_0",
        product_counter_part: "200B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 201,
        src: SaveTheTreesMen,
        product_path: "201A_0",
        product_counter_part: "201B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 202,
        src: SaveTheTreesMen,
        product_path: "202A_0",
        product_counter_part: "202B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 203,
        src: SaveTheTreesMen,
        product_path: "203A_0",
        product_counter_part: "203B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 204,
        src: SaveTheTreesMen,
        product_path: "204A_0",
        product_counter_part: "204B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 205,
        src: SaveTheTreesMen,
        product_path: "205A_0",
        product_counter_part: "205B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 206,
        src: SaveTheTreesMen,
        product_path: "206A_0",
        product_counter_part: "206B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 207,
        src: SaveTheTreesMen,
        product_path: "207A_0",
        product_counter_part: "207B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 208,
        src: SaveTheTreesMen,
        product_path: "208A_0",
        product_counter_part: "208B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 209,
        src: SaveTheTreesMen,
        product_path: "209A_0",
        product_counter_part: "209B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 210,
        src: SaveTheTreesMen,
        product_path: "210A_0",
        product_counter_part: "210B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 211,
        src: SaveTheTreesMen,
        product_path: "211A_0",
        product_counter_part: "211B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 212,
        src: SaveTheTreesMen,
        product_path: "212A_0",
        product_counter_part: "212B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 213,
        src: SaveTheTreesMen,
        product_path: "213A_0",
        product_counter_part: "213B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 214,
        src: SaveTheTreesMen,
        product_path: "214A_0",
        product_counter_part: "214B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 215,
        src: SaveTheTreesMen,
        product_path: "215A_0",
        product_counter_part: "215B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 216,
        src: SaveTheTreesMen,
        product_path: "216A_0",
        product_counter_part: "216B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 217,
        src: SaveTheTreesMen,
        product_path: "217A_0",
        product_counter_part: "217B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 218,
        src: SaveTheTreesMen,
        product_path: "218A_0",
        product_counter_part: "218B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 219,
        src: SaveTheTreesMen,
        product_path: "219A_0",
        product_counter_part: "219B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 220,
        src: SaveTheTreesMen,
        product_path: "220A_0",
        product_counter_part: "220B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 221,
        src: SaveTheTreesMen,
        product_path: "221A_0",
        product_counter_part: "221B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 222,
        src: SaveTheTreesMen,
        product_path: "222A_0",
        product_counter_part: "222B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 223,
        src: SaveTheTreesMen,
        product_path: "223A_0",
        product_counter_part: "223B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 224,
        src: SaveTheTreesMen,
        product_path: "224A_0",
        product_counter_part: "224B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 225,
        src: SaveTheTreesMen,
        product_path: "225A_0",
        product_counter_part: "225B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 226,
        src: SaveTheTreesMen,
        product_path: "226A_0",
        product_counter_part: "226B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 227,
        src: SaveTheTreesMen,
        product_path: "227A_0",
        product_counter_part: "227B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 228,
        src: SaveTheTreesMen,
        product_path: "228A_0",
        product_counter_part: "228B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 229,
        src: SaveTheTreesMen,
        product_path: "229A_0",
        product_counter_part: "229B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 230,
        src: SaveTheTreesMen,
        product_path: "230A_0",
        product_counter_part: "230B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 231,
        src: SaveTheTreesMen,
        product_path: "231A_0",
        product_counter_part: "231B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 232,
        src: SaveTheTreesMen,
        product_path: "232A_0",
        product_counter_part: "232B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 233,
        src: SaveTheTreesMen,
        product_path: "233A_0",
        product_counter_part: "233B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 234,
        src: SaveTheTreesMen,
        product_path: "234A_0",
        product_counter_part: "234B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 235,
        src: SaveTheTreesMen,
        product_path: "235A_0",
        product_counter_part: "235B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 236,
        src: SaveTheTreesMen,
        product_path: "236A_0",
        product_counter_part: "236B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 237,
        src: SaveTheTreesMen,
        product_path: "237A_0",
        product_counter_part: "237B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 238,
        src: SaveTheTreesMen,
        product_path: "238A_0",
        product_counter_part: "238B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 239,
        src: SaveTheTreesMen,
        product_path: "239A_0",
        product_counter_part: "239B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 240,
        src: SaveTheTreesMen,
        product_path: "240A_0",
        product_counter_part: "240B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 241,
        src: SaveTheTreesMen,
        product_path: "241A_0",
        product_counter_part: "241B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 242,
        src: SaveTheTreesMen,
        product_path: "242A_0",
        product_counter_part: "242B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 243,
        src: SaveTheTreesMen,
        product_path: "243A_0",
        product_counter_part: "243B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 244,
        src: SaveTheTreesMen,
        product_path: "244A_0",
        product_counter_part: "244B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 245,
        src: SaveTheTreesMen,
        product_path: "245A_0",
        product_counter_part: "245B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 246,
        src: SaveTheTreesMen,
        product_path: "246A_0",
        product_counter_part: "246B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 247,
        src: SaveTheTreesMen,
        product_path: "247A_0",
        product_counter_part: "247B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 248,
        src: SaveTheTreesMen,
        product_path: "248A_0",
        product_counter_part: "248B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 249,
        src: SaveTheTreesMen,
        product_path: "249A_0",
        product_counter_part: "249B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 250,
        src: SaveTheTreesMen,
        product_path: "250A_0",
        product_counter_part: "250B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 251,
        src: SaveTheTreesMen,
        product_path: "251A_0",
        product_counter_part: "251B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 252,
        src: SaveTheTreesMen,
        product_path: "252A_0",
        product_counter_part: "252B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 253,
        src: SaveTheTreesMen,
        product_path: "253A_0",
        product_counter_part: "253B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 254,
        src: SaveTheTreesMen,
        product_path: "254A_0",
        product_counter_part: "254B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 255,
        src: SaveTheTreesMen,
        product_path: "255A_0",
        product_counter_part: "255B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 256,
        src: SaveTheTreesMen,
        product_path: "256A_0",
        product_counter_part: "256B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 257,
        src: SaveTheTreesMen,
        product_path: "257A_0",
        product_counter_part: "257B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 258,
        src: SaveTheTreesMen,
        product_path: "258A_0",
        product_counter_part: "258B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 259,
        src: SaveTheTreesMen,
        product_path: "259A_0",
        product_counter_part: "259B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 260,
        src: SaveTheTreesMen,
        product_path: "260A_0",
        product_counter_part: "260B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 261,
        src: SaveTheTreesMen,
        product_path: "261A_0",
        product_counter_part: "261B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 262,
        src: SaveTheTreesMen,
        product_path: "262A_0",
        product_counter_part: "262B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 263,
        src: SaveTheTreesMen,
        product_path: "263A_0",
        product_counter_part: "263B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 264,
        src: SaveTheTreesMen,
        product_path: "264A_0",
        product_counter_part: "264B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 265,
        src: SaveTheTreesMen,
        product_path: "265A_0",
        product_counter_part: "265B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 266,
        src: SaveTheTreesMen,
        product_path: "266A_0",
        product_counter_part: "266B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 267,
        src: SaveTheTreesMen,
        product_path: "267A_0",
        product_counter_part: "267B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 268,
        src: SaveTheTreesMen,
        product_path: "268A_0",
        product_counter_part: "268B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 269,
        src: SaveTheTreesMen,
        product_path: "269A_0",
        product_counter_part: "269B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 270,
        src: SaveTheTreesMen,
        product_path: "270A_0",
        product_counter_part: "270B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 271,
        src: SaveTheTreesMen,
        product_path: "271A_0",
        product_counter_part: "271B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 272,
        src: SaveTheTreesMen,
        product_path: "272A_0",
        product_counter_part: "272B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 273,
        src: SaveTheTreesMen,
        product_path: "273A_0",
        product_counter_part: "273B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 274,
        src: SaveTheTreesMen,
        product_path: "274A_0",
        product_counter_part: "274B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 275,
        src: SaveTheTreesMen,
        product_path: "275A_0",
        product_counter_part: "275B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 276,
        src: SaveTheTreesMen,
        product_path: "276A_0",
        product_counter_part: "276B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 277,
        src: SaveTheTreesMen,
        product_path: "277A_0",
        product_counter_part: "277B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 278,
        src: SaveTheTreesMen,
        product_path: "278A_0",
        product_counter_part: "278B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 279,
        src: SaveTheTreesMen,
        product_path: "279A_0",
        product_counter_part: "279B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 280,
        src: SaveTheTreesMen,
        product_path: "280A_0",
        product_counter_part: "280B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 281,
        src: SaveTheTreesMen,
        product_path: "281A_0",
        product_counter_part: "281B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 282,
        src: SaveTheTreesMen,
        product_path: "282A_0",
        product_counter_part: "282B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 283,
        src: SaveTheTreesMen,
        product_path: "283A_0",
        product_counter_part: "283B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 284,
        src: SaveTheTreesMen,
        product_path: "284A_0",
        product_counter_part: "284B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 285,
        src: SaveTheTreesMen,
        product_path: "285A_0",
        product_counter_part: "285B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 286,
        src: SaveTheTreesMen,
        product_path: "286A_0",
        product_counter_part: "286B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 287,
        src: SaveTheTreesMen,
        product_path: "287A_0",
        product_counter_part: "287B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 288,
        src: SaveTheTreesMen,
        product_path: "288A_0",
        product_counter_part: "288B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 289,
        src: SaveTheTreesMen,
        product_path: "289A_0",
        product_counter_part: "289B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 290,
        src: SaveTheTreesMen,
        product_path: "290A_0",
        product_counter_part: "290B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 291,
        src: SaveTheTreesMen,
        product_path: "291A_0",
        product_counter_part: "291B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 292,
        src: SaveTheTreesMen,
        product_path: "292A_0",
        product_counter_part: "292B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 293,
        src: SaveTheTreesMen,
        product_path: "293A_0",
        product_counter_part: "293B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 294,
        src: SaveTheTreesMen,
        product_path: "294A_0",
        product_counter_part: "294B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 295,
        src: SaveTheTreesMen,
        product_path: "295A_0",
        product_counter_part: "295B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 296,
        src: SaveTheTreesMen,
        product_path: "296A_0",
        product_counter_part: "296B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 297,
        src: SaveTheTreesMen,
        product_path: "297A_0",
        product_counter_part: "297B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 298,
        src: SaveTheTreesMen,
        product_path: "298A_0",
        product_counter_part: "298B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 299,
        src: SaveTheTreesMen,
        product_path: "299A_0",
        product_counter_part: "299B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 300,
        src: SaveTheTreesMen,
        product_path: "300A_0",
        product_counter_part: "300B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 301,
        src: SaveTheTreesMen,
        product_path: "301A_0",
        product_counter_part: "301B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 302,
        src: SaveTheTreesMen,
        product_path: "302A_0",
        product_counter_part: "302B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 303,
        src: SaveTheTreesMen,
        product_path: "303A_0",
        product_counter_part: "303B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 304,
        src: SaveTheTreesMen,
        product_path: "304A_0",
        product_counter_part: "304B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 305,
        src: SaveTheTreesMen,
        product_path: "305A_0",
        product_counter_part: "305B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 306,
        src: SaveTheTreesMen,
        product_path: "306A_0",
        product_counter_part: "306B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 307,
        src: SaveTheTreesMen,
        product_path: "307A_0",
        product_counter_part: "307B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 308,
        src: SaveTheTreesMen,
        product_path: "308A_0",
        product_counter_part: "308B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 309,
        src: SaveTheTreesMen,
        product_path: "309A_0",
        product_counter_part: "309B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 310,
        src: SaveTheTreesMen,
        product_path: "310A_0",
        product_counter_part: "310B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 311,
        src: SaveTheTreesMen,
        product_path: "311A_0",
        product_counter_part: "311B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        fit: 'oversize',
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 312,
        src: SaveTheTreesMen,
        product_path: "312A_0",
        product_counter_part: "312B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 313,
        src: SaveTheTreesMen,
        product_path: "313A_0",
        product_counter_part: "313B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 314,
        src: SaveTheTreesMen,
        product_path: "314A_0",
        product_counter_part: "314B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 315,
        src: SaveTheTreesMen,
        product_path: "315A_0",
        product_counter_part: "315B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 316,
        src: SaveTheTreesMen,
        product_path: "316A_0",
        product_counter_part: "316B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 317,
        src: SaveTheTreesMen,
        product_path: "317A_0",
        product_counter_part: "317B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 318,
        src: SaveTheTreesMen,
        product_path: "318A_0",
        product_counter_part: "318B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 319,
        src: SaveTheTreesMen,
        product_path: "319A_0",
        product_counter_part: "319B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 320,
        src: SaveTheTreesMen,
        product_path: "320A_0",
        product_counter_part: "320B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 321,
        src: SaveTheTreesMen,
        product_path: "321A_0",
        product_counter_part: "321B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 322,
        src: SaveTheTreesMen,
        product_path: "322A_0",
        product_counter_part: "322B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 323,
        src: SaveTheTreesMen,
        product_path: "323A_0",
        product_counter_part: "323B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 324,
        src: SaveTheTreesMen,
        product_path: "324A_0",
        product_counter_part: "324B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 325,
        src: SaveTheTreesMen,
        product_path: "325A_0",
        product_counter_part: "325B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 326,
        src: SaveTheTreesMen,
        product_path: "326A_0",
        product_counter_part: "326B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 327,
        src: SaveTheTreesMen,
        product_path: "327A_0",
        product_counter_part: "327B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 328,
        src: SaveTheTreesMen,
        product_path: "328A_0",
        product_counter_part: "328B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 329,
        src: SaveTheTreesMen,
        product_path: "329A_0",
        product_counter_part: "329B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 330,
        src: SaveTheTreesMen,
        product_path: "330A_0",
        product_counter_part: "330B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 331,
        src: SaveTheTreesMen,
        product_path: "331A_0",
        product_counter_part: "331B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 332,
        src: SaveTheTreesMen,
        product_path: "332A_0",
        product_counter_part: "332B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 333,
        src: SaveTheTreesMen,
        product_path: "333A_0",
        product_counter_part: "333B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 334,
        src: SaveTheTreesMen,
        product_path: "334A_0",
        product_counter_part: "334B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 335,
        src: SaveTheTreesMen,
        product_path: "335A_0",
        product_counter_part: "335B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 336,
        src: SaveTheTreesMen,
        product_path: "336A_0",
        product_counter_part: "336B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 337,
        src: SaveTheTreesMen,
        product_path: "337A_0",
        product_counter_part: "337B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 338,
        src: SaveTheTreesMen,
        product_path: "338A_0",
        product_counter_part: "338B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 339,
        src: SaveTheTreesMen,
        product_path: "339A_0",
        product_counter_part: "339B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 340,
        src: SaveTheTreesMen,
        product_path: "340A_0",
        product_counter_part: "340B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 341,
        src: SaveTheTreesMen,
        product_path: "341A_0",
        product_counter_part: "341B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 342,
        src: SaveTheTreesMen,
        product_path: "342A_0",
        product_counter_part: "342B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 343,
        src: SaveTheTreesMen,
        product_path: "343A_0",
        product_counter_part: "343B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 344,
        src: SaveTheTreesMen,
        product_path: "344A_0",
        product_counter_part: "344B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 345,
        src: SaveTheTreesMen,
        product_path: "345A_0",
        product_counter_part: "345B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 346,
        src: SaveTheTreesMen,
        product_path: "346A_0",
        product_counter_part: "346B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 347,
        src: SaveTheTreesMen,
        product_path: "347A_0",
        product_counter_part: "347B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 348,
        src: SaveTheTreesMen,
        product_path: "348A_0",
        product_counter_part: "348B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 349,
        src: SaveTheTreesMen,
        product_path: "349A_0",
        product_counter_part: "349B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 350,
        src: SaveTheTreesMen,
        product_path: "350A_0",
        product_counter_part: "350B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 351,
        src: SaveTheTreesMen,
        product_path: "351A_0",
        product_counter_part: "351B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 352,
        src: SaveTheTreesMen,
        product_path: "352A_0",
        product_counter_part: "352B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 353,
        src: SaveTheTreesMen,
        product_path: "353A_0",
        product_counter_part: "353B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 354,
        src: SaveTheTreesMen,
        product_path: "354A_0",
        product_counter_part: "354B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 355,
        src: SaveTheTreesMen,
        product_path: "355A_0",
        product_counter_part: "355B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 356,
        src: SaveTheTreesMen,
        product_path: "356A_0",
        product_counter_part: "356B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 357,
        src: SaveTheTreesMen,
        product_path: "357A_0",
        product_counter_part: "357B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 358,
        src: SaveTheTreesMen,
        product_path: "358A_0",
        product_counter_part: "358B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 359,
        src: SaveTheTreesMen,
        product_path: "359A_0",
        product_counter_part: "359B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 360,
        src: SaveTheTreesMen,
        product_path: "360A_0",
        product_counter_part: "360B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 361,
        src: SaveTheTreesMen,
        product_path: "361A_0",
        product_counter_part: "361B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 362,
        src: SaveTheTreesMen,
        product_path: "362A_0",
        product_counter_part: "362B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 363,
        src: SaveTheTreesMen,
        product_path: "363A_0",
        product_counter_part: "363B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 364,
        src: SaveTheTreesMen,
        product_path: "364A_0",
        product_counter_part: "364B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 365,
        src: SaveTheTreesMen,
        product_path: "365A_0",
        product_counter_part: "365B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 366,
        src: SaveTheTreesMen,
        product_path: "366A_0",
        product_counter_part: "366B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    }
]

export const beingHumanProductListQA = [
    {
        id: 1,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469155",
        src: bh1,
        name: "being-human-men-none-t-shirts-green-foam-8905271469155",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24031",
        product_path: "BHTS24031_0",
        product_counter_part: "BHDI24099_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 2,
        productSlug: "being-human-men-none-t-shirts-jet-black-8905271445012",
        src: bh2,
        name: "being-human-men-none-t-shirts-jet-black-8905271445012",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-fit-denim-mid-tone-8905271476627",
        groupId: "BHTS24079",
        product_path: "BHT24079_0",
        product_counter_part: "BHDI24168_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 3,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24143_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000122",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 4,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        src: bh4,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 5,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        src: bh5,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 6,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        src: bh6,
        name: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 7,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        src: bh7,
        name: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 8,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        src: bh8,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24035",
        product_path: "BHP24035_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 9,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        src: bh9,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24038",
        product_path: "BHP24038_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 10,
        productSlug: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        src: bh10,
        name: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24046",
        product_path: "BHP24046_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 11,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002CO_0",
        product_counter_part: "BHD124059_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000384",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 12,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        src: bh12,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24014",
        product_path: "BHMLS24014_0",
        product_counter_part: "BHDI24093_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 13,
        src: SaveTheTreesMen,
        product_path: "BHFLS24001_0",
        product_counter_part: "BHKTP24005_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000128",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 14,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271445876",
        src: bh14,
        name: "being-human-men-slim-fit-shirts-black-8905271445876",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-straight-fit-denim-mid-tone-8905271474401",
        groupId: "BHMLS24017",
        product_path: "BHMLS24017_0",
        product_counter_part: "BHDI24137_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                73.66,
                86.66
            ],
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                96.52,
                113.55
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 15,
        productSlug: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        src: bh15,
        name: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "BHP24003_0",
        product_counter_part: "BHDI24030_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 16,
        productSlug: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        src: bh16,
        name: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24002",
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24059_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 17,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000380",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 18,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000550",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 19,
        productSlug: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        src: bh19,
        name: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-black-tone-8905271466130",
        groupId: "BHMSS24027",
        product_path: "BHMSS24027_0",
        product_counter_part: "BHDSI24195_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 20,
        productSlug: "being-human-men-none-t-shirts-coffee-8905271442493",
        src: bh20,
        name: "being-human-men-none-t-shirts-coffee-8905271442493",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "20A_0",
        product_counter_part: "20B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 21,
        productSlug: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        src: bh21,
        name: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "21A_0",
        product_counter_part: "21B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 22,
        productSlug: "being-human-men-none-t-shirts-charcoal-8905271442578",
        src: bh22,
        name: "being-human-men-none-t-shirts-charcoal-8905271442578",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "22A_0",
        product_counter_part: "22B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 23,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        src: bh23,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "23A_0",
        product_counter_part: "23B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 24,
        productSlug: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        src: bh24,
        name: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "24A_0",
        product_counter_part: "24B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 25,
        productSlug: "being-human-men-none-t-shirts-white-8905271444114",
        src: bh25,
        name: "being-human-men-none-t-shirts-white-8905271444114",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "25A_0",
        product_counter_part: "25B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 26,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        src: bh26,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24006",
        product_path: "26A_0",
        product_counter_part: "26B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 27,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        src: bh27,
        name: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "27A_0",
        product_counter_part: "27B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 28,
        productSlug: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        src: bh28,
        name: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "28A_0",
        product_counter_part: "28B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 29,
        productSlug: "being-human-men-none-t-shirts-navy-8905271444770",
        src: bh29,
        name: "being-human-men-none-t-shirts-navy-8905271444770",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "29A_0",
        product_counter_part: "29B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 30,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        src: bh30,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "30A_0",
        product_counter_part: "30B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 31,
        productSlug: "being-human-men-none-t-shirts-chalk-8905271442738",
        src: bh31,
        name: "being-human-men-none-t-shirts-chalk-8905271442738",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "31A_0",
        product_counter_part: "31B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 32,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        src: bh32,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "32A_0",
        product_counter_part: "32B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 33,
        src: SaveTheTreesMen,
        product_path: "33A_0",
        product_counter_part: "33B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 34,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271460213",
        src: bh34,
        name: "being-human-men-slim-fit-shirts-black-8905271460213",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "34A_0",
        product_counter_part: "34B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 35,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        src: bh35,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "35A_0",
        product_counter_part: "35B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 36,
        productSlug: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        src: bh36,
        name: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "36A_0",
        product_counter_part: "36B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 37,
        productSlug: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        src: bh37,
        name: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "37A_0",
        product_counter_part: "37B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 38,
        src: SaveTheTreesMen,
        product_path: "38A_0",
        product_counter_part: "38B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 39,
        src: SaveTheTreesMen,
        product_path: "39A_0",
        product_counter_part: "39B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 40,
        src: SaveTheTreesMen,
        product_path: "40A_0",
        product_counter_part: "40B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 41,
        src: SaveTheTreesMen,
        product_path: "41A_0",
        product_counter_part: "41B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 42,
        productSlug: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        src: bh42,
        name: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "42A_0",
        product_counter_part: "42B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 43,
        productSlug: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        src: bh43,
        name: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "43A_0",
        product_counter_part: "43B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 44,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        src: bh44,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "44A_0",
        product_counter_part: "44B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 45,
        productSlug: "being-human-men-none-t-shirts-olive-8905271442257",
        src: bh45,
        name: "being-human-men-none-t-shirts-olive-8905271442257",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "45A_0",
        product_counter_part: "45B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 46,
        productSlug: "being-human-men-none-t-shirts-black-8905271443476",
        src: bh46,
        name: "being-human-men-none-t-shirts-black-8905271443476",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "46A_0",
        product_counter_part: "46B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 47,
        productSlug: "being-human-men-none-t-shirts-white-8905271443391",
        src: bh47,
        name: "being-human-men-none-t-shirts-white-8905271443391",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "47A_0",
        product_counter_part: "47B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 48,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        src: bh48,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "48A_0",
        product_counter_part: "48B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 49,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        src: bh49,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "49A_0",
        product_counter_part: "49B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 50,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        src: bh50,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "50A_0",
        product_counter_part: "50B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 51,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        src: bh51,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "51A_0",
        product_counter_part: "51B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 52,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        src: bh52,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "52A_0",
        product_counter_part: "52B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 53,
        productSlug: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        src: bh53,
        name: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "53A_0",
        product_counter_part: "53B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 54,
        productSlug: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        src: bh54,
        name: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24024",
        product_path: "54A_0",
        product_counter_part: "54B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 55,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        src: bh55,
        name: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "55A_0",
        product_counter_part: "55B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 56,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        src: bh56,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "56A_0",
        product_counter_part: "56B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 57,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        src: bh57,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "57A_0",
        product_counter_part: "57B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 58,
        productSlug: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        src: bh58,
        name: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24065",
        product_path: "58A_0",
        product_counter_part: "58B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 59,
        productSlug: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        src: bh59,
        name: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "59A_0",
        product_counter_part: "59B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 60,
        productSlug: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        src: bh60,
        name: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "60A_0",
        product_counter_part: "60B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 61,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        src: bh61,
        name: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "61A_0",
        product_counter_part: "61B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 62,
        productSlug: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        src: bh62,
        name: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "62A_0",
        product_counter_part: "62B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 63,
        productSlug: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        src: bh63,
        name: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "63A_0",
        product_counter_part: "63B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 64,
        productSlug: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        src: bh64,
        name: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "64A_0",
        product_counter_part: "64B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 65,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        src: bh65,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "65A_0",
        product_counter_part: "65B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 66,
        productSlug: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        src: bh66,
        name: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "66A_0",
        product_counter_part: "66B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 67,
        productSlug: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        src: bh67,
        name: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "67A_0",
        product_counter_part: "67B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 68,
        productSlug: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        src: bh68,
        name: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "68A_0",
        product_counter_part: "68B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 69,
        productSlug: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        src: bh69,
        name: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "69A_0",
        product_counter_part: "69B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 70,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        src: bh70,
        name: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "70A_0",
        product_counter_part: "70B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            93.98,
            96.52,
            101.6,
            109.22,
            114.3,
            119.38,
            124.46
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 71,
        productSlug: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        src: bh71,
        name: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "71A_0",
        product_counter_part: "71B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 72,
        productSlug: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        src: bh72,
        name: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "72A_0",
        product_counter_part: "72B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 73,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        src: bh73,
        name: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "73A_0",
        product_counter_part: "73B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 74,
        productSlug: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        src: bh74,
        name: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "74A_0",
        product_counter_part: "74B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 75,
        productSlug: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        src: bh75,
        name: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "75A_0",
        product_counter_part: "75B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 76,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        src: bh76,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "76A_0",
        product_counter_part: "76B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 77,
        src: SaveTheTreesMen,
        product_path: "77A_0",
        product_counter_part: "77B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 78,
        src: SaveTheTreesMen,
        product_path: "78A_0",
        product_counter_part: "78B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 79,
        src: SaveTheTreesMen,
        product_path: "79A_0",
        product_counter_part: "79B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 80,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271482413",
        src: bh80,
        name: "being-human-men-slim-fit-shirts-black-8905271482413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24016",
        product_path: "80A_0",
        product_counter_part: "80B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 81,
        src: SaveTheTreesMen,
        product_path: "81A_0",
        product_counter_part: "81B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 82,
        src: SaveTheTreesMen,
        product_path: "82A_0",
        product_counter_part: "82B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 83,
        src: SaveTheTreesMen,
        product_path: "83A_0",
        product_counter_part: "83B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 84,
        src: SaveTheTreesMen,
        product_path: "84A_0",
        product_counter_part: "84B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 85,
        src: SaveTheTreesMen,
        product_path: "85A_0",
        product_counter_part: "85B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 86,
        src: SaveTheTreesMen,
        product_path: "86A_0",
        product_counter_part: "86B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 87,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271482253",
        src: bh87,
        name: "being-human-men-slim-fit-shirts-white-8905271482253",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "87A_0",
        product_counter_part: "87B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 88,
        productSlug: "being-human-men-slim-fit-shirts-sky-8905271482093",
        src: bh88,
        name: "being-human-men-slim-fit-shirts-sky-8905271482093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "88A_0",
        product_counter_part: "88B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 89,
        productSlug: "being-human-men-slim-fit-shirts-mint-8905271482017",
        src: bh89,
        name: "being-human-men-slim-fit-shirts-mint-8905271482017",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "89A_0",
        product_counter_part: "89B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 90,
        productSlug: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        src: bh90,
        name: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "90A_0",
        product_counter_part: "90B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 91,
        productSlug: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        src: bh91,
        name: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "91A_0",
        product_counter_part: "91B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 92,
        productSlug: "being-human-men-slim-fit-shirts-teal-8905271482178",
        src: bh92,
        name: "being-human-men-slim-fit-shirts-teal-8905271482178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "92A_0",
        product_counter_part: "92B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 93,
        productSlug: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        src: bh93,
        name: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "93A_0",
        product_counter_part: "93B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 94,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271481775",
        src: bh94,
        name: "being-human-men-slim-fit-shirts-black-8905271481775",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "94A_0",
        product_counter_part: "94B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 95,
        productSlug: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        src: bh95,
        name: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "95A_0",
        product_counter_part: "95B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 96,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        src: bh96,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "96A_0",
        product_counter_part: "96B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 97,
        productSlug: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        src: bh97,
        name: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "97A_0",
        product_counter_part: "97B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 98,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        src: bh98,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24020",
        product_path: "98A_0",
        product_counter_part: "98B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 99,
        productSlug: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        src: bh99,
        name: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "99A_0",
        product_counter_part: "99B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 100,
        productSlug: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        src: bh100,
        name: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "100A_0",
        product_counter_part: "100B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 101,
        productSlug: "being-human-men-none-t-shirts-black-8905271468998",
        src: bh101,
        name: "being-human-men-none-t-shirts-black-8905271468998",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "101A_0",
        product_counter_part: "101B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 102,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469070",
        src: bh102,
        name: "being-human-men-none-t-shirts-green-foam-8905271469070",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "102A_0",
        product_counter_part: "102B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 103,
        productSlug: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        src: bh103,
        name: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "103A_0",
        product_counter_part: "103B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 104,
        productSlug: "being-human-men-none-t-shirts-mint-8905271442899",
        src: bh104,
        name: "being-human-men-none-t-shirts-mint-8905271442899",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "104A_0",
        product_counter_part: "104B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 105,
        productSlug: "being-human-men-none-t-shirts-olive-8905271443872",
        src: bh105,
        name: "being-human-men-none-t-shirts-olive-8905271443872",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "105A_0",
        product_counter_part: "105B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 106,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        src: bh106,
        name: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "106A_0",
        product_counter_part: "106B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 107,
        productSlug: "being-human-men-none-t-shirts-black-8905271444282",
        src: bh107,
        name: "being-human-men-none-t-shirts-black-8905271444282",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "107A_0",
        product_counter_part: "107B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 108,
        productSlug: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        src: bh108,
        name: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "108A_0",
        product_counter_part: "108B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 109,
        productSlug: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        src: bh109,
        name: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "109A_0",
        product_counter_part: "109B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 110,
        productSlug: "being-human-men-none-t-shirts-ltblue-8905271443629",
        src: bh110,
        name: "being-human-men-none-t-shirts-ltblue-8905271443629",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "110A_0",
        product_counter_part: "110B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 111,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271443711",
        src: bh111,
        name: "being-human-men-none-t-shirts-green-foam-8905271443711",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "111A_0",
        product_counter_part: "111B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 112,
        src: SaveTheTreesMen,
        product_path: "112A_0",
        product_counter_part: "112B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 113,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        src: bh113,
        name: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24007",
        product_path: "113A_0",
        product_counter_part: "113B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 114,
        src: SaveTheTreesMen,
        product_path: "114A_0",
        product_counter_part: "114B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 115,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        src: bh115,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "115A_0",
        product_counter_part: "115B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 116,
        productSlug: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        src: bh116,
        name: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "116A_0",
        product_counter_part: "116B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 117,
        productSlug: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        src: bh117,
        name: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "117A_0",
        product_counter_part: "117B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 118,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450450",
        src: bh118,
        name: "being-human-men-slim-fit-shirts-navy-8905271450450",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24101",
        product_path: "118A_0",
        product_counter_part: "118B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 119,
        productSlug: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        src: bh119,
        name: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24103",
        product_path: "119A_0",
        product_counter_part: "119B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 120,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450856",
        src: bh120,
        name: "being-human-men-slim-fit-shirts-navy-8905271450856",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "120A_0",
        product_counter_part: "120B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 121,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271450931",
        src: bh121,
        name: "being-human-men-slim-fit-shirts-white-8905271450931",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "121A_0",
        product_counter_part: "121B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 122,
        src: SaveTheTreesMen,
        product_path: "122A_0",
        product_counter_part: "122B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 123,
        src: SaveTheTreesMen,
        product_path: "123A_0",
        product_counter_part: "123B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 124,
        src: SaveTheTreesMen,
        product_path: "124A_0",
        product_counter_part: "124B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 125,
        src: SaveTheTreesMen,
        product_path: "125A_0",
        product_counter_part: "125B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 126,
        src: SaveTheTreesMen,
        product_path: "126A_0",
        product_counter_part: "126B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 127,
        src: SaveTheTreesMen,
        product_path: "127A_0",
        product_counter_part: "127B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 128,
        src: SaveTheTreesMen,
        product_path: "128A_0",
        product_counter_part: "128B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 129,
        src: SaveTheTreesMen,
        product_path: "129A_0",
        product_counter_part: "129B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 130,
        src: SaveTheTreesMen,
        product_path: "130A_0",
        product_counter_part: "130B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 131,
        src: SaveTheTreesMen,
        product_path: "131A_0",
        product_counter_part: "131B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 132,
        src: SaveTheTreesMen,
        product_path: "132A_0",
        product_counter_part: "132B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 133,
        src: SaveTheTreesMen,
        product_path: "133A_0",
        product_counter_part: "133B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 134,
        src: SaveTheTreesMen,
        product_path: "134A_0",
        product_counter_part: "134B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 135,
        src: SaveTheTreesMen,
        product_path: "135A_0",
        product_counter_part: "135B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 136,
        src: SaveTheTreesMen,
        product_path: "136A_0",
        product_counter_part: "136B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 137,
        src: SaveTheTreesMen,
        product_path: "137A_0",
        product_counter_part: "137B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 138,
        src: SaveTheTreesMen,
        product_path: "138A_0",
        product_counter_part: "138B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 139,
        src: SaveTheTreesMen,
        product_path: "139A_0",
        product_counter_part: "139B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 140,
        src: SaveTheTreesMen,
        product_path: "140A_0",
        product_counter_part: "140B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 141,
        src: SaveTheTreesMen,
        product_path: "141A_0",
        product_counter_part: "141B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 142,
        src: SaveTheTreesMen,
        product_path: "142A_0",
        product_counter_part: "142B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 143,
        src: SaveTheTreesMen,
        product_path: "143A_0",
        product_counter_part: "143B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 144,
        src: SaveTheTreesMen,
        product_path: "144A_0",
        product_counter_part: "144B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 145,
        src: SaveTheTreesMen,
        product_path: "145A_0",
        product_counter_part: "145B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 146,
        src: SaveTheTreesMen,
        product_path: "146A_0",
        product_counter_part: "146B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 147,
        src: SaveTheTreesMen,
        product_path: "147A_0",
        product_counter_part: "147B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 148,
        src: SaveTheTreesMen,
        product_path: "148A_0",
        product_counter_part: "148B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 149,
        src: SaveTheTreesMen,
        product_path: "149A_0",
        product_counter_part: "149B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 150,
        src: SaveTheTreesMen,
        product_path: "150A_0",
        product_counter_part: "150B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 151,
        src: SaveTheTreesMen,
        product_path: "151A_0",
        product_counter_part: "151B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 152,
        src: SaveTheTreesMen,
        product_path: "152A_0",
        product_counter_part: "152B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 153,
        src: SaveTheTreesMen,
        product_path: "153A_0",
        product_counter_part: "153B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 154,
        src: SaveTheTreesMen,
        product_path: "154A_0",
        product_counter_part: "154B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 155,
        src: SaveTheTreesMen,
        product_path: "155A_0",
        product_counter_part: "155B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 156,
        src: SaveTheTreesMen,
        product_path: "156A_0",
        product_counter_part: "156B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 157,
        src: SaveTheTreesMen,
        product_path: "157A_0",
        product_counter_part: "157B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 158,
        src: SaveTheTreesMen,
        product_path: "158A_0",
        product_counter_part: "158B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 159,
        src: SaveTheTreesMen,
        product_path: "159A_0",
        product_counter_part: "159B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 160,
        src: SaveTheTreesMen,
        product_path: "160A_0",
        product_counter_part: "160B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 161,
        src: SaveTheTreesMen,
        product_path: "161A_0",
        product_counter_part: "161B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 162,
        src: SaveTheTreesMen,
        product_path: "162A_0",
        product_counter_part: "162B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 163,
        src: SaveTheTreesMen,
        product_path: "163A_0",
        product_counter_part: "163B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 164,
        src: SaveTheTreesMen,
        product_path: "164A_0",
        product_counter_part: "164B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 165,
        src: SaveTheTreesMen,
        product_path: "165A_0",
        product_counter_part: "165B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 166,
        src: SaveTheTreesMen,
        product_path: "166A_0",
        product_counter_part: "166B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 167,
        src: SaveTheTreesMen,
        product_path: "167A_0",
        product_counter_part: "167B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 168,
        src: SaveTheTreesMen,
        product_path: "168A_0",
        product_counter_part: "168B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 169,
        src: SaveTheTreesMen,
        product_path: "169A_0",
        product_counter_part: "169B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 170,
        src: SaveTheTreesMen,
        product_path: "170A_0",
        product_counter_part: "170B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 171,
        src: SaveTheTreesMen,
        product_path: "171A_0",
        product_counter_part: "171B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 172,
        src: SaveTheTreesMen,
        product_path: "172A_0",
        product_counter_part: "172B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 173,
        src: SaveTheTreesMen,
        product_path: "173A_0",
        product_counter_part: "173B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 174,
        src: SaveTheTreesMen,
        product_path: "174A_0",
        product_counter_part: "174B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 175,
        src: SaveTheTreesMen,
        product_path: "175A_0",
        product_counter_part: "175B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 176,
        src: SaveTheTreesMen,
        product_path: "176A_0",
        product_counter_part: "176B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 177,
        src: SaveTheTreesMen,
        product_path: "177A_0",
        product_counter_part: "177B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 178,
        src: SaveTheTreesMen,
        product_path: "178A_0",
        product_counter_part: "178B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 179,
        src: SaveTheTreesMen,
        product_path: "179A_0",
        product_counter_part: "179B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 180,
        src: SaveTheTreesMen,
        product_path: "180A_0",
        product_counter_part: "180B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 181,
        src: SaveTheTreesMen,
        product_path: "181A_0",
        product_counter_part: "181B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 182,
        src: SaveTheTreesMen,
        product_path: "182A_0",
        product_counter_part: "182B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 183,
        src: SaveTheTreesMen,
        product_path: "183A_0",
        product_counter_part: "183B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 184,
        src: SaveTheTreesMen,
        product_path: "184A_0",
        product_counter_part: "184B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 185,
        src: SaveTheTreesMen,
        product_path: "185A_0",
        product_counter_part: "185B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 186,
        src: SaveTheTreesMen,
        product_path: "186A_0",
        product_counter_part: "186B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 187,
        src: SaveTheTreesMen,
        product_path: "187A_0",
        product_counter_part: "187B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 188,
        src: SaveTheTreesMen,
        product_path: "188A_0",
        product_counter_part: "188B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 189,
        src: SaveTheTreesMen,
        product_path: "189A_0",
        product_counter_part: "189B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 190,
        src: SaveTheTreesMen,
        product_path: "190A_0",
        product_counter_part: "190B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 191,
        src: SaveTheTreesMen,
        product_path: "191A_0",
        product_counter_part: "191B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 192,
        src: SaveTheTreesMen,
        product_path: "192A_0",
        product_counter_part: "192B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 193,
        src: SaveTheTreesMen,
        product_path: "193A_0",
        product_counter_part: "193B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 194,
        src: SaveTheTreesMen,
        product_path: "194A_0",
        product_counter_part: "194B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 195,
        src: SaveTheTreesMen,
        product_path: "195A_0",
        product_counter_part: "195B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 196,
        src: SaveTheTreesMen,
        product_path: "196A_0",
        product_counter_part: "196B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 197,
        src: SaveTheTreesMen,
        product_path: "197A_0",
        product_counter_part: "197B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 198,
        src: SaveTheTreesMen,
        product_path: "198A_0",
        product_counter_part: "198B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 199,
        src: SaveTheTreesMen,
        product_path: "199A_0",
        product_counter_part: "199B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 200,
        src: SaveTheTreesMen,
        product_path: "200A_0",
        product_counter_part: "200B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 201,
        src: SaveTheTreesMen,
        product_path: "201A_0",
        product_counter_part: "201B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 202,
        src: SaveTheTreesMen,
        product_path: "202A_0",
        product_counter_part: "202B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 203,
        src: SaveTheTreesMen,
        product_path: "203A_0",
        product_counter_part: "203B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 204,
        src: SaveTheTreesMen,
        product_path: "204A_0",
        product_counter_part: "204B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 205,
        src: SaveTheTreesMen,
        product_path: "205A_0",
        product_counter_part: "205B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 206,
        src: SaveTheTreesMen,
        product_path: "206A_0",
        product_counter_part: "206B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 207,
        src: SaveTheTreesMen,
        product_path: "207A_0",
        product_counter_part: "207B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 208,
        src: SaveTheTreesMen,
        product_path: "208A_0",
        product_counter_part: "208B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 209,
        src: SaveTheTreesMen,
        product_path: "209A_0",
        product_counter_part: "209B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 210,
        src: SaveTheTreesMen,
        product_path: "210A_0",
        product_counter_part: "210B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 211,
        src: SaveTheTreesMen,
        product_path: "211A_0",
        product_counter_part: "211B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 212,
        src: SaveTheTreesMen,
        product_path: "212A_0",
        product_counter_part: "212B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 213,
        src: SaveTheTreesMen,
        product_path: "213A_0",
        product_counter_part: "213B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 214,
        src: SaveTheTreesMen,
        product_path: "214A_0",
        product_counter_part: "214B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 215,
        src: SaveTheTreesMen,
        product_path: "215A_0",
        product_counter_part: "215B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 216,
        src: SaveTheTreesMen,
        product_path: "216A_0",
        product_counter_part: "216B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 217,
        src: SaveTheTreesMen,
        product_path: "217A_0",
        product_counter_part: "217B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 218,
        src: SaveTheTreesMen,
        product_path: "218A_0",
        product_counter_part: "218B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 219,
        src: SaveTheTreesMen,
        product_path: "219A_0",
        product_counter_part: "219B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 220,
        src: SaveTheTreesMen,
        product_path: "220A_0",
        product_counter_part: "220B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 221,
        src: SaveTheTreesMen,
        product_path: "221A_0",
        product_counter_part: "221B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 222,
        src: SaveTheTreesMen,
        product_path: "222A_0",
        product_counter_part: "222B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 223,
        src: SaveTheTreesMen,
        product_path: "223A_0",
        product_counter_part: "223B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 224,
        src: SaveTheTreesMen,
        product_path: "224A_0",
        product_counter_part: "224B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 225,
        src: SaveTheTreesMen,
        product_path: "225A_0",
        product_counter_part: "225B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 226,
        src: SaveTheTreesMen,
        product_path: "226A_0",
        product_counter_part: "226B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 227,
        src: SaveTheTreesMen,
        product_path: "227A_0",
        product_counter_part: "227B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 228,
        src: SaveTheTreesMen,
        product_path: "228A_0",
        product_counter_part: "228B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 229,
        src: SaveTheTreesMen,
        product_path: "229A_0",
        product_counter_part: "229B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 230,
        src: SaveTheTreesMen,
        product_path: "230A_0",
        product_counter_part: "230B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 231,
        src: SaveTheTreesMen,
        product_path: "231A_0",
        product_counter_part: "231B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 232,
        src: SaveTheTreesMen,
        product_path: "232A_0",
        product_counter_part: "232B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 233,
        src: SaveTheTreesMen,
        product_path: "233A_0",
        product_counter_part: "233B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 234,
        src: SaveTheTreesMen,
        product_path: "234A_0",
        product_counter_part: "234B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 235,
        src: SaveTheTreesMen,
        product_path: "235A_0",
        product_counter_part: "235B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 236,
        src: SaveTheTreesMen,
        product_path: "236A_0",
        product_counter_part: "236B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 237,
        src: SaveTheTreesMen,
        product_path: "237A_0",
        product_counter_part: "237B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 238,
        src: SaveTheTreesMen,
        product_path: "238A_0",
        product_counter_part: "238B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 239,
        src: SaveTheTreesMen,
        product_path: "239A_0",
        product_counter_part: "239B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 240,
        src: SaveTheTreesMen,
        product_path: "240A_0",
        product_counter_part: "240B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 241,
        src: SaveTheTreesMen,
        product_path: "241A_0",
        product_counter_part: "241B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 242,
        src: SaveTheTreesMen,
        product_path: "242A_0",
        product_counter_part: "242B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 243,
        src: SaveTheTreesMen,
        product_path: "243A_0",
        product_counter_part: "243B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 244,
        src: SaveTheTreesMen,
        product_path: "244A_0",
        product_counter_part: "244B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 245,
        src: SaveTheTreesMen,
        product_path: "245A_0",
        product_counter_part: "245B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 246,
        src: SaveTheTreesMen,
        product_path: "246A_0",
        product_counter_part: "246B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 247,
        src: SaveTheTreesMen,
        product_path: "247A_0",
        product_counter_part: "247B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 248,
        src: SaveTheTreesMen,
        product_path: "248A_0",
        product_counter_part: "248B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 249,
        src: SaveTheTreesMen,
        product_path: "249A_0",
        product_counter_part: "249B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 250,
        src: SaveTheTreesMen,
        product_path: "250A_0",
        product_counter_part: "250B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 251,
        src: SaveTheTreesMen,
        product_path: "251A_0",
        product_counter_part: "251B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 252,
        src: SaveTheTreesMen,
        product_path: "252A_0",
        product_counter_part: "252B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 253,
        src: SaveTheTreesMen,
        product_path: "253A_0",
        product_counter_part: "253B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 254,
        src: SaveTheTreesMen,
        product_path: "254A_0",
        product_counter_part: "254B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 255,
        src: SaveTheTreesMen,
        product_path: "255A_0",
        product_counter_part: "255B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 256,
        src: SaveTheTreesMen,
        product_path: "256A_0",
        product_counter_part: "256B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 257,
        src: SaveTheTreesMen,
        product_path: "257A_0",
        product_counter_part: "257B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 258,
        src: SaveTheTreesMen,
        product_path: "258A_0",
        product_counter_part: "258B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 259,
        src: SaveTheTreesMen,
        product_path: "259A_0",
        product_counter_part: "259B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 260,
        src: SaveTheTreesMen,
        product_path: "260A_0",
        product_counter_part: "260B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 261,
        src: SaveTheTreesMen,
        product_path: "261A_0",
        product_counter_part: "261B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 262,
        src: SaveTheTreesMen,
        product_path: "262A_0",
        product_counter_part: "262B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 263,
        src: SaveTheTreesMen,
        product_path: "263A_0",
        product_counter_part: "263B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 264,
        src: SaveTheTreesMen,
        product_path: "264A_0",
        product_counter_part: "264B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 265,
        src: SaveTheTreesMen,
        product_path: "265A_0",
        product_counter_part: "265B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 266,
        src: SaveTheTreesMen,
        product_path: "266A_0",
        product_counter_part: "266B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 267,
        src: SaveTheTreesMen,
        product_path: "267A_0",
        product_counter_part: "267B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 268,
        src: SaveTheTreesMen,
        product_path: "268A_0",
        product_counter_part: "268B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 269,
        src: SaveTheTreesMen,
        product_path: "269A_0",
        product_counter_part: "269B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 270,
        src: SaveTheTreesMen,
        product_path: "270A_0",
        product_counter_part: "270B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 271,
        src: SaveTheTreesMen,
        product_path: "271A_0",
        product_counter_part: "271B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 272,
        src: SaveTheTreesMen,
        product_path: "272A_0",
        product_counter_part: "272B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 273,
        src: SaveTheTreesMen,
        product_path: "273A_0",
        product_counter_part: "273B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 274,
        src: SaveTheTreesMen,
        product_path: "274A_0",
        product_counter_part: "274B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 275,
        src: SaveTheTreesMen,
        product_path: "275A_0",
        product_counter_part: "275B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 276,
        src: SaveTheTreesMen,
        product_path: "276A_0",
        product_counter_part: "276B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 277,
        src: SaveTheTreesMen,
        product_path: "277A_0",
        product_counter_part: "277B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 278,
        src: SaveTheTreesMen,
        product_path: "278A_0",
        product_counter_part: "278B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 279,
        src: SaveTheTreesMen,
        product_path: "279A_0",
        product_counter_part: "279B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 280,
        src: SaveTheTreesMen,
        product_path: "280A_0",
        product_counter_part: "280B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 281,
        src: SaveTheTreesMen,
        product_path: "281A_0",
        product_counter_part: "281B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 282,
        src: SaveTheTreesMen,
        product_path: "282A_0",
        product_counter_part: "282B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 283,
        src: SaveTheTreesMen,
        product_path: "283A_0",
        product_counter_part: "283B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 284,
        src: SaveTheTreesMen,
        product_path: "284A_0",
        product_counter_part: "284B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 285,
        src: SaveTheTreesMen,
        product_path: "285A_0",
        product_counter_part: "285B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 286,
        src: SaveTheTreesMen,
        product_path: "286A_0",
        product_counter_part: "286B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 287,
        src: SaveTheTreesMen,
        product_path: "287A_0",
        product_counter_part: "287B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 288,
        src: SaveTheTreesMen,
        product_path: "288A_0",
        product_counter_part: "288B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 289,
        src: SaveTheTreesMen,
        product_path: "289A_0",
        product_counter_part: "289B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 290,
        src: SaveTheTreesMen,
        product_path: "290A_0",
        product_counter_part: "290B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 291,
        src: SaveTheTreesMen,
        product_path: "291A_0",
        product_counter_part: "291B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 292,
        src: SaveTheTreesMen,
        product_path: "292A_0",
        product_counter_part: "292B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 293,
        src: SaveTheTreesMen,
        product_path: "293A_0",
        product_counter_part: "293B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 294,
        src: SaveTheTreesMen,
        product_path: "294A_0",
        product_counter_part: "294B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 295,
        src: SaveTheTreesMen,
        product_path: "295A_0",
        product_counter_part: "295B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 296,
        src: SaveTheTreesMen,
        product_path: "296A_0",
        product_counter_part: "296B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 297,
        src: SaveTheTreesMen,
        product_path: "297A_0",
        product_counter_part: "297B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 298,
        src: SaveTheTreesMen,
        product_path: "298A_0",
        product_counter_part: "298B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 299,
        src: SaveTheTreesMen,
        product_path: "299A_0",
        product_counter_part: "299B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 300,
        src: SaveTheTreesMen,
        product_path: "300A_0",
        product_counter_part: "300B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 301,
        src: SaveTheTreesMen,
        product_path: "301A_0",
        product_counter_part: "301B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 302,
        src: SaveTheTreesMen,
        product_path: "302A_0",
        product_counter_part: "302B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 303,
        src: SaveTheTreesMen,
        product_path: "303A_0",
        product_counter_part: "303B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 304,
        src: SaveTheTreesMen,
        product_path: "304A_0",
        product_counter_part: "304B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 305,
        src: SaveTheTreesMen,
        product_path: "305A_0",
        product_counter_part: "305B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 306,
        src: SaveTheTreesMen,
        product_path: "306A_0",
        product_counter_part: "306B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 307,
        src: SaveTheTreesMen,
        product_path: "307A_0",
        product_counter_part: "307B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 308,
        src: SaveTheTreesMen,
        product_path: "308A_0",
        product_counter_part: "308B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 309,
        src: SaveTheTreesMen,
        product_path: "309A_0",
        product_counter_part: "309B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 310,
        src: SaveTheTreesMen,
        product_path: "310A_0",
        product_counter_part: "310B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 311,
        src: SaveTheTreesMen,
        product_path: "311A_0",
        product_counter_part: "311B_0",
        productSlug: 'being-human-men-none-t-shirts-green-foam-8905271469155',
        productCounterPartSlug: 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        fit: 'oversize',
        productSizeTags: [
            'XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        productSizeValues: [
            121.92,
            127.0,
            132.08,
            137.16,
            144.78,
            160.02,
            167.64,
            177.8,
            190.5,
            52.0],
        productCounterPartSizeTags: [
            '28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'
        ],
        productCounterPartSizeValues: [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [118.11, 138.96],
        [125.73, 147.93],
        [135.89, 159.87],
        [146.05, 171.83]],
    },
    {
        id: 312,
        src: SaveTheTreesMen,
        product_path: "312A_0",
        product_counter_part: "312B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 313,
        src: SaveTheTreesMen,
        product_path: "313A_0",
        product_counter_part: "313B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 314,
        src: SaveTheTreesMen,
        product_path: "314A_0",
        product_counter_part: "314B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 315,
        src: SaveTheTreesMen,
        product_path: "315A_0",
        product_counter_part: "315B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 316,
        src: SaveTheTreesMen,
        product_path: "316A_0",
        product_counter_part: "316B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 317,
        src: SaveTheTreesMen,
        product_path: "317A_0",
        product_counter_part: "317B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 318,
        src: SaveTheTreesMen,
        product_path: "318A_0",
        product_counter_part: "318B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 319,
        src: SaveTheTreesMen,
        product_path: "319A_0",
        product_counter_part: "319B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 320,
        src: SaveTheTreesMen,
        product_path: "320A_0",
        product_counter_part: "320B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 321,
        src: SaveTheTreesMen,
        product_path: "321A_0",
        product_counter_part: "321B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 322,
        src: SaveTheTreesMen,
        product_path: "322A_0",
        product_counter_part: "322B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 323,
        src: SaveTheTreesMen,
        product_path: "323A_0",
        product_counter_part: "323B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 324,
        src: SaveTheTreesMen,
        product_path: "324A_0",
        product_counter_part: "324B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 325,
        src: SaveTheTreesMen,
        product_path: "325A_0",
        product_counter_part: "325B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 326,
        src: SaveTheTreesMen,
        product_path: "326A_0",
        product_counter_part: "326B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 327,
        src: SaveTheTreesMen,
        product_path: "327A_0",
        product_counter_part: "327B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 328,
        src: SaveTheTreesMen,
        product_path: "328A_0",
        product_counter_part: "328B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 329,
        src: SaveTheTreesMen,
        product_path: "329A_0",
        product_counter_part: "329B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 330,
        src: SaveTheTreesMen,
        product_path: "330A_0",
        product_counter_part: "330B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 331,
        src: SaveTheTreesMen,
        product_path: "331A_0",
        product_counter_part: "331B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 332,
        src: SaveTheTreesMen,
        product_path: "332A_0",
        product_counter_part: "332B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 333,
        src: SaveTheTreesMen,
        product_path: "333A_0",
        product_counter_part: "333B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 334,
        src: SaveTheTreesMen,
        product_path: "334A_0",
        product_counter_part: "334B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 335,
        src: SaveTheTreesMen,
        product_path: "335A_0",
        product_counter_part: "335B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 336,
        src: SaveTheTreesMen,
        product_path: "336A_0",
        product_counter_part: "336B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 337,
        src: SaveTheTreesMen,
        product_path: "337A_0",
        product_counter_part: "337B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 338,
        src: SaveTheTreesMen,
        product_path: "338A_0",
        product_counter_part: "338B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 339,
        src: SaveTheTreesMen,
        product_path: "339A_0",
        product_counter_part: "339B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 340,
        src: SaveTheTreesMen,
        product_path: "340A_0",
        product_counter_part: "340B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 341,
        src: SaveTheTreesMen,
        product_path: "341A_0",
        product_counter_part: "341B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 342,
        src: SaveTheTreesMen,
        product_path: "342A_0",
        product_counter_part: "342B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 343,
        src: SaveTheTreesMen,
        product_path: "343A_0",
        product_counter_part: "343B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 344,
        src: SaveTheTreesMen,
        product_path: "344A_0",
        product_counter_part: "344B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 345,
        src: SaveTheTreesMen,
        product_path: "345A_0",
        product_counter_part: "345B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 346,
        src: SaveTheTreesMen,
        product_path: "346A_0",
        product_counter_part: "346B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 347,
        src: SaveTheTreesMen,
        product_path: "347A_0",
        product_counter_part: "347B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 348,
        src: SaveTheTreesMen,
        product_path: "348A_0",
        product_counter_part: "348B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 349,
        src: SaveTheTreesMen,
        product_path: "349A_0",
        product_counter_part: "349B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 350,
        src: SaveTheTreesMen,
        product_path: "350A_0",
        product_counter_part: "350B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 351,
        src: SaveTheTreesMen,
        product_path: "351A_0",
        product_counter_part: "351B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 352,
        src: SaveTheTreesMen,
        product_path: "352A_0",
        product_counter_part: "352B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 353,
        src: SaveTheTreesMen,
        product_path: "353A_0",
        product_counter_part: "353B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 354,
        src: SaveTheTreesMen,
        product_path: "354A_0",
        product_counter_part: "354B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 355,
        src: SaveTheTreesMen,
        product_path: "355A_0",
        product_counter_part: "355B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 356,
        src: SaveTheTreesMen,
        product_path: "356A_0",
        product_counter_part: "356B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 357,
        src: SaveTheTreesMen,
        product_path: "357A_0",
        product_counter_part: "357B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 358,
        src: SaveTheTreesMen,
        product_path: "358A_0",
        product_counter_part: "358B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 359,
        src: SaveTheTreesMen,
        product_path: "359A_0",
        product_counter_part: "359B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 360,
        src: SaveTheTreesMen,
        product_path: "360A_0",
        product_counter_part: "360B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 361,
        src: SaveTheTreesMen,
        product_path: "361A_0",
        product_counter_part: "361B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 362,
        src: SaveTheTreesMen,
        product_path: "362A_0",
        product_counter_part: "362B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 363,
        src: SaveTheTreesMen,
        product_path: "363A_0",
        product_counter_part: "363B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 364,
        src: SaveTheTreesMen,
        product_path: "364A_0",
        product_counter_part: "364B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 365,
        src: SaveTheTreesMen,
        product_path: "365A_0",
        product_counter_part: "365B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 366,
        src: SaveTheTreesMen,
        product_path: "366A_0",
        product_counter_part: "366B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    }
]

export const testProductList = [
    {
        id: 0,
        src: SaveTheTreesMen,
        product_path: "shtbottom1_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "test",
        avatar: "v14",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 1,
        src: Sculpt1,
        product_path: "scultbottom_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "test",
        avatar: "v18",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    }
]

export const forevernewProductList = [
    {
        id: 1,
        src: EVE,
        product_path: "eve_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Eve",
        labels: "WA_D607",
        description:
            "Breathable Cotton Tiered Dress With Pockets",
        bullet_description: [
            "Cinched Waist",
            "Sleeveless Midi Dress With Pockets",
            "100% cotton",
            "V-Neck",
        ],
        price: "$210",
        brand: "forevernew",
        avatar: "v18",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL"],
        productSizeValues: [[81, 66], [86, 71], [91, 76], [97, 81], [102, 86]],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: [],
        fabric: {
            top: "100% Cotton",
        }
    },
]

export const virgioProductList = [
    {
        id: 1,
        src: EVE,
        product_path: "eve_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Eve",
        labels: "WA_D607",
        description:
            "Breathable Cotton Tiered Dress With Pockets",
        bullet_description: [
            "Cinched Waist",
            "Sleeveless Midi Dress With Pockets",
            "100% cotton",
            "V-Neck",
        ],
        price: "$210",
        brand: "virgio",
        avatar: "v18",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL"],
        productSizeValues: [[81, 66], [86, 71], [91, 76], [97, 81], [102, 86]],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: [],
        fabric: {
            top: "100% Cotton",
        }
    },
]

export const iffProductList = [
    {
        id: 1,
        src: Sculpt1,
        product_path: "sculttop_0",
        product_counter_part: "scultbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 2,
        src: Adjusted2,
        product_path: "actopfull_1",
        product_counter_part: "acbottomfull_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "100% Cotton",
            bottom: "100% Cotton",
        },
        materialConfig: {
            "actopfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': "#265568",
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': "#265568",
                    'HIDE': '#265568',
                }
            },
            "acbottomfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': '#265568',
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': '#265568',
                }
            }
        },
        variations: {
            'detachConfig': {
                'actopfull_1': [
                    'HIDE',
                    'Material2054',
                    'Material1596011',
                    'Material1596004',
                ],
            },
        }
    },
    {
        id: 3,
        src: Sweatshirt4,
        product_path: "sweatshirt_3",
        product_counter_part: "jogger_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        },
        materialConfig: {
            'sweatshirt_3': {
                emissive: {
                    'sweatshirt_FRONT': "#16161D",
                    'Rib_2x1_FRONT': '#1A1A20',
                }
            },
            'jogger_3': {
                emissive: {
                    'Knit_Fleece_Terry_FRONT': '#16161D',
                    'Lace_FRONT_798774': '#16161D',
                }
            }
        },
    },
    {
        id: 4,
        src: Reflex1,
        product_path: "reflextop_0",
        product_counter_part: "reflexbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon, 10% Elastane",
            bottom: "90% Nylon, 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            }
        }
    },
    {
        id: 5,
        src: Puma679460,
        product_path: "679460_0",
        product_counter_part: "679464_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[78, 62], [83, 67], [88, 72], [95, 79], [103, 87], [111, 96], [119, 105], [127, 114],],
        productCounterPartSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[71, 58], [76, 62], [81, 66], [88, 72], [96, 79], [104, 87], [112, 95], [120, 103]]
    },
    {
        id: 6,
        src: NicobarDress,
        product_path: "WAD607_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v16",
        shoes: 'nicobar_wad607',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.28, 66.04], [86.33, 71.12], [91.45, 76.20], [96.5, 82.28], [101.6, 86.36], [111.75, 96.52], [122, 106.68],], productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 7,
        src: NicobarFunkeyMonkey,
        product_path: "MT31FItop_0",
        product_counter_part: "MT31FIbottom_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Top",
        labels: "MT31_FI_top",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v11",
        shoes: 'nicobar_mt31',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[92.71, 64.4], [97.79, 68.4], [102.87, 72.4], [107.95, 77.4], [113.03, 83.4], [123.19, 89.4], [133.35, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 8,
        src: BSWYEWomen,
        product_path: "bsof2top_0",
        product_counter_part: "bsofbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 9,
        src: Chase1,
        product_path: "chasetop_0",
        product_counter_part: "chasebottom_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 10,
        src: Puma677867_0,
        product_path: "677867_0",
        product_counter_part: "677866X_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 11,
        src: Puma677867_1,
        product_path: "677867_1",
        product_counter_part: "677866X_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 12,
        src: Puma677865_0,
        product_path: "677865_0",
        product_counter_part: "677866_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
]

export const ccclubProductList = [
    {
        id: 0,
        src: SaveTheTreesMen,
        product_path: "stttop1_0",
        product_counter_part: "sttbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 1,
        src: ShakyMen,
        product_path: "shttop1_0",
        product_counter_part: "shtbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: BePoliteMen1,
        product_path: "bptop1_0",
        product_counter_part: "bpbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 3,
        src: BePoliteMen2,
        product_path: "bptop1_1",
        product_counter_part: "bpbottom1_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 4,
        src: BSWYEMen,
        product_path: "bsof1_0",
        product_counter_part: "bsofbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 5,
        src: OceanMen,
        product_path: "oceant1_0",
        product_counter_part: "oceanbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 6,
        src: SaveTheTreesWomen,
        product_path: "stttop2_0",
        product_counter_part: "sttbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 7,
        src: ShakyWomen,
        product_path: "shttop2_0",
        product_counter_part: "shtbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 8,
        src: BePoliteWomen,
        product_path: "bptop2_0",
        product_counter_part: "bpbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 9,
        src: BePoliteWomen1,
        product_path: "bptop2_1",
        product_counter_part: "bpbottom2_1",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 10,
        src: BSWYEWomen,
        product_path: "bsof2top_0",
        product_counter_part: "bsofbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 11,
        src: OceanWomen,
        product_path: "oceant2_0",
        product_counter_part: "oceanbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 12,
        src: HalioyWomen,
        product_path: "halioytop_0",
        product_counter_part: "halioybottom_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CCC Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 13,
        src: HalioyMen,
        product_path: "halioytop2_0",
        product_counter_part: "halioybottom2_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CCC Tshirts",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
];

export const beyondExtremesProductList = [
    {
        id: 0,
        src: Chase1,
        product_path: "hydrotop_0",
        product_counter_part: "stadiumbottom_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beyondextremes",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
];

export const cavaProductList = [
    {
        id: 0,
        src: Chase1,
        product_path: "chasetop_0",
        product_counter_part: "chasebottom_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 1,
        src: Chase2,
        product_path: "chasetop_1",
        product_counter_part: "chasebottom_1",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 2,
        src: Chase3,
        product_path: "chasetop_2",
        product_counter_part: "chasebottom_2",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 5,
        src: Adjusted1,
        product_path: "actopfull_0",
        product_counter_part: "acbottomfull_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "100% Cotton",
            bottom: "100% Cotton",
        },
        variations: {
            'detachConfig': {
                'actopfull_0': [
                    'HIDE',
                    'Material2054',
                    'Material1596011',
                    'Material1596004',
                ],
            },
        },
    },
    {
        id: 6,
        src: Adjusted2,
        product_path: "actopfull_1",
        product_counter_part: "acbottomfull_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "100% Cotton",
            bottom: "100% Cotton",
        },
        materialConfig: {
            "actopfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': "#265568",
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': "#265568",
                    'HIDE': '#265568',
                }
            },
            "acbottomfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': '#265568',
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': '#265568',
                }
            }
        },
        variations: {
            'detachConfig': {
                'actopfull_1': [
                    'HIDE',
                    'Material2054',
                    'Material1596011',
                    'Material1596004',
                ],
            },
        }
    },
    {
        id: 7,
        src: Sculpt1,
        product_path: "sculttop_0",
        product_counter_part: "scultbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 8,
        src: Sculpt2,
        product_path: "sculttop_1",
        product_counter_part: "scultbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 9,
        src: Sculpt3,
        product_path: "sculttop_2",
        product_counter_part: "scultbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },
        materialConfig: {
            "sculttop_2": {
                emissive: {
                    'Main_FRONT': "#863069",
                    'PT_FABRIC_FRONT': "#863069",
                    'Material2034841': "#863069",
                    'Material2034989': "#863069",
                }
            },
            "scultbottom_2": {
                emissive: {
                    'Main_FRONT': "#863069",
                    'waistband_FRONT': "#863069",
                }
            }
        }
    },
    {
        id: 10,
        src: Sculpt4,
        product_path: "sculttop_3",
        product_counter_part: "scultbottom_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },
        materialConfig: {
            "sculttop_3": {
                emissive: {
                    'Main_FRONT': "#985337",
                    'PT_FABRIC_FRONT': "#985337",
                    'Material2034841': "#985337",
                    'Material2034989': "#985337",
                }
            },
            "scultbottom_3": {
                emissive: {
                    'Main_FRONT': '#985337',
                    'waistband_FRONT': '#985337',
                }
            }
        }
    },
    {
        id: 11,
        src: Sweatshirt1,
        product_path: "sweatshirt_0",
        product_counter_part: "jogger_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        }
    },
    {
        id: 13,
        src: Sweatshirt3,
        product_path: "sweatshirt_2",
        product_counter_part: "jogger_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        }
    },
    {
        id: 14,
        src: Sweatshirt4,
        product_path: "sweatshirt_3",
        product_counter_part: "jogger_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        },
        materialConfig: {
            'sweatshirt_3': {
                emissive: {
                    'sweatshirt_FRONT': "#16161D",
                    'Rib_2x1_FRONT': '#1A1A20',
                }
            },
            'jogger_3': {
                emissive: {
                    'Knit_Fleece_Terry_FRONT': '#16161D',
                    'Lace_FRONT_798774': '#16161D',
                }
            }
        },
    },
    {
        id: 15,
        src: Sweatshirt5,
        product_path: "sweatshirt_4",
        product_counter_part: "jogger_4",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        }
    },
    {
        id: 16,
        src: Punk1,
        product_path: "punktop_0",
        product_counter_part: "sssculptbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },

    },
    {
        id: 17,
        src: Punk2,
        product_path: "punktop_1",
        product_counter_part: "sssculptbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "60% Cotton, 40% Polyester",
        },
        materialConfig: {
            'sssculptbottom_1': {
                emissive: {
                    'Knit_Fleece_Terry_FRONT': "#3A7473",
                    'Lace_FRONT': '#3A7473',
                }
            }
        },
    },
    {
        id: 18,
        src: Punk3,
        product_path: "punktop_2",
        product_counter_part: "sssculptbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },
        materialConfig: {
            'sssculptbottom_2': {
                emissive: {
                    'Main_FRONT': "#863069",
                    'waistband_FRONT': '#863069',
                }
            }
        },
    },
    {
        id: 19,
        src: Punk4,
        product_path: "punktop_3",
        product_counter_part: "sssculptbottom_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 20,
        src: Reflex1,
        product_path: "reflextop_0",
        product_counter_part: "reflexbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon, 10% Elastane",
            bottom: "90% Nylon, 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            }
        }
    },
    {
        id: 21,
        src: Reflex2,
        product_path: "reflextop_1",
        product_counter_part: "reflexbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 22,
        src: Reflex3,
        product_path: "reflextop_2",
        product_counter_part: "reflexbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 23,
        src: Zipper0,
        product_path: "zippertop_0",
        product_counter_part: "bellbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 24,
        src: Zipper1,
        product_path: "zippertop_1",
        product_counter_part: "bellbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 25,
        src: Zipper2,
        product_path: "zippertop_2",
        product_counter_part: "bellbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 26,
        src: Zipper3,
        product_path: "zippertop_3",
        product_counter_part: "bellbottom_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
];
// export const ccclubProductList = [
//   {
//     id: 1,
//     src: NicobarDress,
//     product_path: "loverteetop_0",
//     product_counter_part: "loveislove_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 11,
//     src: NicobarDress,
//     product_path: "loveislove_0",
//     product_counter_part: "loverteetop_0",
//     garment_type: "bottom",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 2,
//     src: NicobarDress,
//     product_path: "rsbltop_0",
//     product_counter_part: "rsblbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 22,
//     src: NicobarDress,
//     product_path: "rsblbottom_0",
//     product_counter_part: "rsbltop_0",
//     garment_type: "bottom",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 3,
//     src: NicobarDress,
//     product_path: "stttop_0",
//     product_counter_part: "sttbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 4,
//     src: NicobarDress,
//     product_path: "ltccctop_0",
//     product_counter_part: "ltcccbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 5,
//     src: NicobarDress,
//     product_path: "sttop_0",
//     product_counter_part: "stbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 5,
//     src: NicobarDress,
//     product_path: "sttop_0",
//     product_counter_part: "stbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 6,
//     src: NicobarDress,
//     product_path: "bptop_0",
//     product_counter_part: "bpshorts_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 7,
//     src: NicobarDress,
//     product_path: "bptop_1",
//     product_counter_part: "bpshorts_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 8,
//     src: NicobarDress,
//     product_path: "sftop_0",
//     product_counter_part: "sfbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 9,
//     src: NicobarDress,
//     product_path: "aidtop_0",
//     product_counter_part: "aidbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 10,
//     src: NicobarDress,
//     product_path: "mcbhtop_0",
//     product_counter_part: "mcbhbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 11,
//     src: NicobarDress,
//     product_path: "meltdowntop_0",
//     product_counter_part: "meltdownbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 12,
//     src: NicobarDress,
//     product_path: "hctop_0",
//     product_counter_part: "hcbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 13,
//     src: NicobarDress,
//     product_path: "fwtop_0",
//     product_counter_part: "fwsitbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 14,
//     src: NicobarDress,
//     product_path: "tirtop_0",
//     product_counter_part: "tirbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 15,
//     src: NicobarDress,
//     product_path: "o14top_0",
//     product_counter_part: "o14bottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 16,
//     src: NicobarDress,
//     product_path: "bswye_0",
//     product_counter_part: "bswyebottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 17,
//     src: NicobarDress,
//     product_path: "hliy_0",
//     product_counter_part: "hliybottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
// ];

export const nicobarFastProductList = [
    {
        id: 1,
        src: NicobarDress,
        product_path: "WAD607_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar-test",
        avatar: "v16",
        shoes: 'nicobar_wad607',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: NicobarFunkeyMonkey,
        product_path: "MT31FItop_0",
        product_counter_part: "MT31FIbottom_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Top",
        labels: "MT31_FI_top",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar-test",
        avatar: "v11",
        shoes: 'nicobar_mt31',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
];

export const nicobarProductList = [
    {
        id: 1,
        src: NicobarDress,
        product_path: "WAD607_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v16",
        shoes: 'nicobar_wad607',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.28, 66.04], [86.33, 71.12], [91.45, 76.20], [96.5, 82.28], [101.6, 86.36], [111.75, 96.52], [122, 106.68],], productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: NicobarFunkeyMonkey,
        product_path: "MT31FItop_0",
        product_counter_part: "MT31FIbottom_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Top",
        labels: "MT31_FI_top",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v11",
        shoes: 'nicobar_mt31',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[92.71, 64.4], [97.79, 68.4], [102.87, 72.4], [107.95, 77.4], [113.03, 83.4], [123.19, 89.4], [133.35, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
];

export const pumaProductList = [
    {
        id: 3,
        src: Puma677865_0,
        product_path: "677865_0",
        product_counter_part: "677866_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 4,
        src: Puma677865_1,
        product_path: "677865_1",
        product_counter_part: "677866_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 5,
        src: Puma677865_2,
        product_path: "677865_2",
        product_counter_part: "677866_2",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 6,
        src: Puma677867_0,
        product_path: "677867_0",
        product_counter_part: "677866X_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 7,
        src: Puma677867_1,
        product_path: "677867_1",
        product_counter_part: "677866X_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 8,
        src: Puma677867_2,
        product_path: "677867_2",
        product_counter_part: "677866X_2",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 1,
        src: Puma679459,
        product_path: "679459_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[78, 62], [83, 67], [88, 72], [95, 79], [103, 87], [111, 96], [119, 105], [127, 114],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: Puma679460,
        product_path: "679460_0",
        product_counter_part: "679464_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[78, 62], [83, 67], [88, 72], [95, 79], [103, 87], [111, 96], [119, 105], [127, 114],],
        productCounterPartSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[71, 58], [76, 62], [81, 66], [88, 72], [96, 79], [104, 87], [112, 95], [120, 103]]
    },
]
export const abfrlV2ProductList = [
    // {
    //     id: 3,
    //     src: E5,
    //     product_path: "AE270223TS_0",
    //     product_counter_part: "AS270223DJ_0",
    //     garment_type: "top",
    //     gender: "female",
    //     productPath: "/abfrl/idrisTshirt.glb",
    //     environmentImage: "",
    //     name: "Louis Philippe Sport Tshirt ",
    //     labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //     description:
    //       "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //     bullet_description: [
    //       "Regular fit",
    //       "100% cotton",
    //       "Half Sleeves",
    //       "Ocassion: Casual",
    //       "Neck: Polo Neck",
    //       "Pattern: Stripe",
    //       "Color: White",
    //       "Collection: LY True Casual"
    //     ],
    //     price: "$210",
    //     brand: "abfrl",
    //     avatar: "v12",
    //     shoes: 'ae01'
    // },
]

export const abfrlProductList = [
    // {
    //   id: 0,
    //   src: E1,
    //   product_path: "v3/LPKPG0510RG_0",
    //   product_counter_part: "v3/LPTFG0043SLTS_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v4",
    // },
    // {
    //   id: 1,
    //   src: E2,
    //   product_path: "v3/PESFG0205SL_0",
    //   product_counter_part: "v3/LPTFG0043SL_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisShirt.glb",
    //   environmentImage: "/environment/pillars_1k.env",
    //   name: "Louis Philippe Casual Shirt ",
    //   labels: "Louis Philippe Jeans - LRSFCSLPW06094",
    //   description:
    //     "Showcase your refined sartorial taste in this Pink Check shirt from Louis Philippe Jeans at a Casual event. Tailored in Slim Fit , this shirt features a Regular Collar , Full Sleeves , and is crafted from exquisite 100% Cotton.",
    //   bullet_description: [
    //     "Slim fit",
    //     "100% cotton",
    //     "Full Sleeves",
    //     "Regular Cuff",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Check",
    //     "Color: Pink",
    //     "Collection: LR Collection"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v4",
    // },
    // {
    //   id: 2,
    //   src: E3,
    //   product_path: "v3/LPTFG0043SL_0",
    //   product_counter_part: "v3/PESFG0205SL_0",
    //   garment_Type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "/environment/blue_lagoon_04k.env",
    //   exposure: "0.6",
    //   name: "Louis Philippe  ",
    //   labels: "Louis Philippe Sport - LYTFACTPX98750",
    //   description:
    //     "A pair of well-tailored trousers is a great style investment to a gentleman's wardrobe. These Grey Check Slim Fit trousers from Louis Philippe Sport by Louis Philippe are both smart and comfortable.",
    //   bullet_description: [
    //     "Slim fit",
    //     "100% cotton",
    //     "Ocassion: Casual",
    //     "Trouser Front: Flat Front",
    //     "Pattern: Check",
    //     "Color: Grey",
    //     "Collection: LY Athwork",
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v4",
    // },
    {
        id: 4,
        src: VWDRG1150RG,
        product_path: "VWDRG1150RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#FFFFFF",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v15",
        shoes: 'VWDRG1150RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 5,
        src: AHDRG0845RG,
        product_path: "AHDRG0845RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v15",
        shoes: 'VWDRG1150RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 6,
        src: E6,
        product_path: "VWTSG1553RG_0",
        product_counter_part: "AHKBG0036RG_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],]
    },
    {
        id: 7,
        src: E7,
        product_path: "AHKBG0036RG_0",
        product_counter_part: "VWTSG1553RG_0",
        garment_type: "bottom",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "BALANCE HI RISE LEGGINGS",
        labels: "Van Heusen Woman - AHKBG0036RG",
        description:
            "These full-length high-waisted leggings from our Proactive collection will support you no matter how intense your workout. Designed in a regular fit, they feature a sculpted high waist for a flattering outline. Utility pockets on the side keep your hands free. The premium fabric wicks moisture away and dries quickly to keep you feeling cool during workout.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[81.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],]
    },
    // {
    //   id: 8,
    //   src: LRSHG0131SL,
    //   product_path: "LRSHG0131SL_0",
    //   product_counter_part: "LRSHG0131SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v9",
    //   shoes: 'LYSFG0170SLv9shoes',
    //   productSizeTags: ["38",	"39",	"40",	"41",	"42",	"43",	"44",	"45",	"46",	"47",	"48"],
    //   productSizeValues:[[86.5,70.5],	[91.5,75.5],	[96.5,80.5],	[100.1,84.5],	[103.5,88.5],	[107.1,94.5],	[110.5,100.5],	[113.5,106.5],	[116.5,112.5],	[119.5,117.5],	[122.5,122.5],],
    //   productCounterPartSizeTags: ["26",	"28",	"30",	"32",	"34",	"36",	"38",	"40",	"42",	"44",	"46",	"48",	"50"],
    //   productCounterPartSizeValues:[[68.3,60.5],	[73.3,65.5],	[78.3,70.5],	[83.3,75.5],	[88.3,80.5],	[93.3,85.5],	[98.3,90.5],	[103.3,95.5],	[108.3,100.5],	[113.3,105.5],	[118.3,110.5],	[123.3,115.5],	[128.3,120.5],]
    // },
    // {
    //   id: 9,
    //   src: LYSFG0170SL,
    //   product_path: "LYSFG0170SL_0",
    //   product_counter_part: "LYSFG0170SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v9",
    //   shoes: 'LYSFG0170SLv9shoes',
    //   productSizeTags: ["37",	"38",	"39",	"40",	"41",	"42",	"43",	"44",	"45",	"46",	"47",	"48",	"49",	"50"],
    //   productSizeValues:[[81.5,65.5],	[86.5,70.5],	[91.5,75.5],	[96.5,80.5],	[100.1,84.5],	[103.5,88.5],	[107.1,94.5],	[110.5,100.5],	[113.5,106.5],	[116.5,112.5],	[119.5,117.5],	[122.5,122.5],	[125.5,127.5],	[128.5,132.5],],
    //   productCounterPartSizeTags: ["26",	"28",	"30",	"32",	"34",	"36",	"38",	"40",	"42",	"44",	"46",	"48",	"50"],
    //   productCounterPartSizeValues:[[68.3,60.5],	[73.3,65.5],	[78.3,70.5],	[83.3,75.5],	[88.3,80.5],	[93.3,85.5],	[98.3,90.5],	[103.3,95.5],	[108.3,100.5],	[113.3,105.5],	[118.3,110.5],	[123.3,115.5],	[128.3,120.5],]

    // },
    {
        id: 15,
        src: AHKCG0014RG,
        product_path: "AHKCG0014RG_0",
        product_counter_part: "AHKBG0141RG_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        sharpMaterialsList: ["PT_FABRIC_FRONT_1485263", "PT_FABRIC_FRONT_1504691"],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],]
    },
    {
        id: 16,
        src: AHKBG0141RG,
        product_path: "AHKBG0141RG_0",
        product_counter_part: "AHKCG0014RG_0",
        garment_type: "bottom",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],]
    },
    {
        id: 17,
        src: AHCTG0395RG,
        product_path: "AHCTG0395RG_0",
        product_counter_part: "AHKBG0036RGX_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],]
    },
    {
        id: 18,
        src: AHCTG0238RG,
        product_path: "AHCTG0238RG_0",
        product_counter_part: "AHDNG0177RX_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "27", "28", "29", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [73.8, 64.9], [76.3, 67.4], [78.8, 69.9], [81.3, 72.4], [83.8, 74.9], [86.8, 77.4], [89.8, 79.9], [92.8, 82.4]]
    },
    {
        id: 19,
        src: AHDNG0177RX,
        product_path: "AHDNG0177RX_0",
        product_counter_part: "AHCTG0238RG_0",
        garment_type: "bottom",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["26", "27", "28", "29", "30", "32", "34", "36", "38"],
        productSizeValues: [[71.3, 62.4], [73.8, 64.9], [76.3, 67.4], [78.8, 69.9], [81.3, 72.4], [83.8, 74.9], [86.8, 77.4], [89.8, 79.9], [92.8, 82.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],]
    },
    {
        id: 20,
        src: VWSTG0025RG,
        product_path: "VWSTG0025RG_0",
        product_counter_part: "AHDNG0177RXY_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[80.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "27", "28", "29", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [73.8, 64.9], [76.3, 67.4], [78.8, 69.9], [81.3, 72.4], [83.8, 74.9], [86.8, 77.4], [89.8, 79.9], [92.8, 82.4]],
    },
    {
        id: 21,
        src: VWJSG0019RG,
        product_path: "VWJSG0019RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [100.2, 82.4], [106.2, 88.4], [112.2, 94.4], [117.2, 99.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[76.3, 68.4], [81.3, 72.4], [86.3, 77.4], [91.3, 82.4], [97.3, 88.4], [103.3, 94.4], [108.3, 99.4]],
    },
    {
        id: 22,
        src: AHDRG0889RG,
        product_path: "AHDRG0889RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#00FF00",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v15",
        shoes: 'VWDRG1150RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4]],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 10,
        src: ALKCG0224SG,
        product_path: "ALKCG0224SG_0",
        product_counter_part: "VAPTG0010KPNJ_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v9",
        shoes: 'LYSFG0170SLv9shoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[86.5, 70.5], [91.5, 75.5], [96.5, 80.5], [101.5, 85.5], [109.1, 93.1], [116.7, 100.7], [121.7, 105.7],],
        productCounterPartSizeTags: ["S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5]]
    },
    {
        id: 11,
        src: VAPTG0010KPNJ,
        product_path: "VAPTG0010KPNJ_0",
        product_counter_part: "ALKCG0224SG_0",
        garment_type: "bottom",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v9",
        shoes: 'LYSFG0170SLv9shoes',
        productSizeTags: ["S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[86.5, 70.5], [91.5, 75.5], [96.5, 80.5], [101.5, 85.5], [109.1, 93.1], [116.7, 100.7], [121.7, 105.7],]
    },
    {//need to be calculated size chart
        id: 12,
        src: VASRG0004RG,
        product_path: "VASRG0004RG_0",
        product_counter_part: "ALKCG0224SGX_0",
        garment_type: "bottom",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v9",
        shoes: 'LYSFG0170SLv9shoes',
        productSizeTags: ["28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50"],
        productSizeValues: [[68.3, 60.5], [73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5], [103.3, 95.5], [108.3, 100.5], [113.3, 105.5], [118.3, 110.5], [123.3, 115.5]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[86.5, 70.5], [91.5, 75.5], [96.5, 80.5], [101.5, 85.5], [109.1, 93.1], [116.7, 100.7], [121.7, 105.7],],
    },
    {
        id: 13,
        src: PXSFG0041SS,
        product_path: "PXSFG0041SS_0",
        product_counter_part: "LPTFG0154CR_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v10",
        shoes: 'niOutfit',
        productSizeTags: ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46"],
        productSizeValues: [[84.5, 66.5], [87.5, 69.9], [90.5, 73.5], [93.5, 76.5], [96.5, 80.5], [99.1, 84.1], [101.5, 87.5], [105.5, 91.5], [109.5, 95.5], [114.1, 100.5], [118.5, 105.5],],
        productCounterPartSizeTags: ["28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50"],
        productCounterPartSizeValues: [[68.3, 60.5], [73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5], [103.3, 95.5], [108.3, 100.5], [113.3, 105.5], [118.3, 110.5], [123.3, 115.5]]
    },
    {
        id: 14,
        src: LPTFG0154CR,
        product_path: "LPTFG0154CR_0",
        product_counter_part: "PXSFG0041SS_0",
        garment_type: "bottom",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v7",
        shoes: 'niOutfit',
        productSizeTags: ["28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50"],
        productSizeValues: [[68.3, 60.5], [73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5], [103.3, 95.5], [108.3, 100.5], [113.3, 105.5], [118.3, 110.5], [123.3, 115.5]],
        productCounterPartSizeTags: ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46"],
        productCounterPartSizeValues: [[84.5, 66.5], [87.5, 69.9], [90.5, 73.5], [93.5, 76.5], [96.5, 80.5], [99.1, 84.1], [101.5, 87.5], [105.5, 91.5], [109.5, 95.5], [114.1, 100.5], [118.5, 105.5],]
    },
    // {
    //   id: 8,
    //   src: LRSHG0131SL,
    //   product_path: "LRSHG0131SL_0",
    //   product_counter_part: "LRSHG0131SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v8",
    //   shoes: 'LRSHG0131SLv8shoes'
    // },
    // {
    //   id: 9,
    //   src: LYSFG0170SL,
    //   product_path: "LYSFG0170SL_0",
    //   product_counter_part: "LYSFG0170SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v8",
    //   shoes: 'LRSHG0131SLv8shoes'
    // }
]

export const fabIndiaList =
    [
        {
            id: 1,
            src: OasisnuIndianTop,
            product_path: "mo1NItop_0",
            product_counter_part: "mo1NIbottom_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Orange Printed Chinese Collar Slim Fit Short Kurta",
            labels: "Oasis Kurta - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari short kurta by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Orange"
            ],
            price: "$210",
            brand: "fabindia",
            avatar: "v7",
            shoes: 'niOutfit'
        },
    ];

export const cluster1ProductList =
    [
        // {
        //   id: 19,
        //   src: Milan,
        //   product_path: "kurta",
        //   product_counter_part: "pant",
        //   garment_type: "top",
        //   gender: "male",
        //   environmentImage: "",
        //   name: "NUindian Orange Printed Chinese Collar Slim Fit Short Kurta with Beige Wool Blend Hand Woven Shawl",
        //   labels: "NUindian Orange Printed Chinese Collar Slim Fit Short Kurta with Beige Wool Blend Hand Woven Shawl",
        //   description:
        //     "Strike a balance of trendiness and sophistication with this fabulous short kurta along with a gorgeous two-tone shawl, your ethnic attire will appear great.",
        //   bullet_description: [
        //     "Material: Cotton",
        //     "Style: Fitted",
        //     "Neck: Chinese Collar",
        //     "Sleeves: Full Sleeves",
        //     "Fit: Slim Fit",
        //     "Length: Thigh Length",
        //   ],
        //   price: "$210",
        //   brand: "milan",
        //   avatar: "v6",
        //   shoes: 'mo1'
        // },
        {
            id: 3,
            src: OasisnuIndianTop,
            product_path: "mo1NItop_0",
            product_counter_part: "mo1NIbottom_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Orange Printed Chinese Collar Slim Fit Short Kurta",
            labels: "Oasis Kurta - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari short kurta by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Orange"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 4,
            src: OasisnuIndianBottom,
            product_path: "mo1NIbottom_0",
            product_counter_part: "mo1NItop_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "Oasis Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this trouser by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 5,
            src: OasisM11,
            product_path: "mo1sh_0",
            product_counter_part: "mo1tr_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 50,
            src: OasisM11,
            product_path: "mo1sh_0",
            product_counter_part: "mo1shorts_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 51,
            src: OasisM11,
            product_path: "mo1sh_1",
            product_counter_part: "mo1shorts_1",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 52,
            src: OasisM11,
            product_path: "mo1sh_2",
            product_counter_part: "mo1shorts_2",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 53,
            src: OasisM11,
            product_path: "mo1sh_3",
            product_counter_part: "mo1shorts_3",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 6,
            src: OasisM12,
            product_path: "mo1tr_0",
            product_counter_part: "mo1sh_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouser by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 7,
            src: OasisM21,
            product_path: "mo2ss_0",
            product_counter_part: "mo2tr_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 71,
            src: OasisM21,
            product_path: "mo2ss_1",
            product_counter_part: "mo2tr_1",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 72,
            src: OasisM21,
            product_path: "mo2ss_2",
            product_counter_part: "mo2tr_2",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 73,
            src: OasisM21,
            product_path: "mo2ss_3",
            product_counter_part: "mo2tr_3",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 8,
            src: OasisM22,
            product_path: "mo2tr_0",
            product_counter_part: "mo2ss_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 81,
            src: OasisM22,
            product_path: "mo2tr_1",
            product_counter_part: "mo2ss_1",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 82,
            src: OasisM22,
            product_path: "mo2tr_2",
            product_counter_part: "mo2ss_2",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 83,
            src: OasisM22,
            product_path: "mo2tr_3",
            product_counter_part: "mo2ss_3",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 9,
            src: OasisM31,
            product_path: "mo3sh_0",
            product_counter_part: "mo3tr_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 10,
            src: OasisM32,
            product_path: "mo3tr_0",
            product_counter_part: "mo3sh_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Trouser",
            labels: "OASIS Trouser- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Cotton Trouser by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 101,
            src: OasisM31,
            product_path: "mo3sh_1",
            product_counter_part: "mo3tr_1",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 102,
            src: OasisM31,
            product_path: "mo3sh_2",
            product_counter_part: "mo3tr_2",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 103,
            src: OasisM31,
            product_path: "mo3sh_3",
            product_counter_part: "mo3tr_3",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 104,
            src: OasisM31,
            product_path: "mo3tr_1",
            product_counter_part: "mo3sh_1",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 105,
            src: OasisM31,
            product_path: "mo3tr_2",
            product_counter_part: "mo3sh_2",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 106,
            src: OasisM31,
            product_path: "mo3tr_3",
            product_counter_part: "mo3sh_3",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 11,
            src: ValentinoM1,
            product_path: "mo4oc_0",
            product_counter_part: "mo4sp_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Black Turtle Neck Tshrit",
            labels: "Oasis Tshirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Black Tutrtle Neck Tshrit by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Black"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'mo2'
        },
        {
            id: 12,
            src: ValentinoM2,
            product_path: "mo4sp_0",
            product_counter_part: "mo4oc_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Black Over Coat",
            labels: "Oasis Over Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Black Over Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Black"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'mo2'
        },
        {
            id: 13,
            src: OasisF1,
            product_path: "fo1d_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'fo1'
        },
        {
            id: 130,
            src: OasisF1,
            product_path: "fo1d_1",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v13",
            shoes: 'VWTSG1553RGAHKBG0036RGShoes'
        },
        {
            id: 131,
            src: OasisF1,
            product_path: "fo1d_2",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v13",
            shoes: 'VWTSG1553RGAHKBG0036RGShoes'
        },
        {
            id: 132,
            src: OasisF1,
            product_path: "fo1d_3",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v13",
            shoes: 'VWTSG1553RGAHKBG0036RGShoes'
        },
        {
            id: 14,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 141,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 142,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 143,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 15,
            src: OasisF22,
            product_path: "fo2md_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Off White & Black Bird Print Cotton Midi Fit and Flare Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this off white & black bird print cotton flare Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: White"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 16,
            src: OasisF31,
            product_path: "fo3top_0",
            product_counter_part: "fo3tro_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 160,
            src: OasisF31,
            product_path: "fo3top_1",
            product_counter_part: "fo3tro_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 161,
            src: OasisF31,
            product_path: "fo3top_2",
            product_counter_part: "fo3tro_2",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 162,
            src: OasisF31,
            product_path: "fo3top_3",
            product_counter_part: "fo3tro_3",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 17,
            src: OasisF32,
            product_path: "fo3tro_0",
            product_counter_part: "fo3top_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 170,
            src: OasisF32,
            product_path: "fo3tro_1",
            product_counter_part: "fo3top_1",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 171,
            src: OasisF32,
            product_path: "fo3tro_2",
            product_counter_part: "fo3top_2",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 172,
            src: OasisF32,
            product_path: "fo3tro_3",
            product_counter_part: "fo3top_3",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 18,
            src: ValentinoF1,
            product_path: "fo4pd_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Embroidered Crepe Couture Pink Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this pink dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Pink"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v9",
            shoes: 'fo4'
        }
    ];

export const cluster2ProductList =
    [
        {
            id: 0,
            src: ValentinoF1,
            product_path: "fo1pd_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "cluster2",
            avatar: "v9",
            shoes: 'fo1'
        },
        {
            id: 1,
            src: ValentinoM1,
            product_path: "mo1oc_0",
            product_counter_part: "mo1sp_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "cluster2",
            avatar: "v7",
            shoes: 'mo1'
        },
        {
            id: 2,
            src: ValentinoM2,
            product_path: "mo1sp_0",
            product_counter_part: "mo1oc_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "cluster2",
            avatar: "v7",
            shoes: 'mo1'
        },
    ];

export const adidasProductList = [
    {
        id: 0,
        src: A1,
        product_path: "v3/F214CSIM308_2",
        product_counter_part: "v3/F214CSIM309_2",
        gender: "male",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.env",
        name: "Men Hoodies",
        labels: "Recycled Inspired Hoodie",
        description:
            "Wherever you're headed, wrap yourself in football style. Inspired by the pitch, this adidas hoodie keeps you warm and comfortable with 100% recycled polyester soft fleece.",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$210",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 1,
        src: A3,
        product_path: "v3/F214CSIM308_1",
        product_counter_part: "v3/F214CSIM309_1",
        gender: "male",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Hoodies",
        labels: "Recycled Inspired Hoodie",
        description:
            "Wherever you're headed, wrap yourself in football style. Inspired by the pitch, this adidas hoodie keeps you warm and comfortable with 100% recycled polyester soft fleece.",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 2,
        src: A2,
        product_path: "v3/F214CSIM308_0",
        product_counter_part: "v3/F214CSIM309_0",
        gender: "male",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Hoodies",
        labels: "Recycled Inspired Hoodie",
        description:
            "Wherever you're headed, wrap yourself in football style. Inspired by the pitch, this adidas hoodie keeps you warm and comfortable with 100% recycled polyester soft fleece.",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 3,
        src: B1,
        product_path: "v3/F214CSIM308_2",
        product_counter_part: "v3/F214CSIM309_2",
        gender: "male",
        garment_Type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Joggers & Trackpants",
        labels: "Recycled Inspired Joggers & Trackpants",
        description:
            "A comfy, modern take on the classic Joggers & Trackpants. Sports is your drive, your passion and your inspiration. Why not show it in your downtime, too? Step out in adidas Joggers & Trackpants and let your athlete identity read it loud and clear. Pair it with our Recycled Inspired Hoodie to complete the look. If you decide to stay in and watch the game, it shines just fine on the couch too",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 4,
        src: B3,
        product_path: "v3/F214CSIM308_1",
        product_counter_part: "v3/F214CSIM309_1",
        gender: "male",
        garment_Type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Joggers & Trackpants",
        labels: "Recycled Inspired Joggers & Trackpants",
        description:
            "A comfy, modern take on the classic Joggers & Trackpants. Sports is your drive, your passion and your inspiration. Why not show it in your downtime, too? Step out in adidas Joggers & Trackpants and let your athlete identity read it loud and clear. Pair it with our Recycled Inspired Hoodie to complete the look. If you decide to stay in and watch the game, it shines just fine on the couch too",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 5,
        src: B2,
        product_path: "v3/F214CSIM308_0",
        product_counter_part: "v3/F214CSIM309_0",
        gender: "male",
        garment_Type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Joggers & Trackpants",
        labels: "Recycled Inspired Joggers & Trackpants",
        description:
            "A comfy, modern take on the classic Joggers & Trackpants. Sports is your drive, your passion and your inspiration. Why not show it in your downtime, too? Step out in adidas Joggers & Trackpants and let your athlete identity read it loud and clear. Pair it with our Recycled Inspired Hoodie to complete the look. If you decide to stay in and watch the game, it shines just fine on the couch too",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 6,
        src: C2,
        product_path: "v3/AS222NW248_1",
        product_counter_part: "v3/AS222NW244_1",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women Graphic Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. Graphics on this set shows your authentic adidas spirit. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: Black",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 7,
        src: C1,
        product_path: "v3/AS222NW248_0",
        product_counter_part: "v3/AS222NW244_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Women Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 8,
        src: D2,
        product_path: "v3/AS222NW248_1",
        product_counter_part: "v3/AS222NW244_1",
        gender: "female",
        garment_Type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 9,
        src: D1,
        product_path: "v3/S221CSIW227_2",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "bottom",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women Graphic Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. Graphics on this set shows your authentic adidas spirit. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: Black",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 10,
        src: F1,
        product_path: "v3/S221CSIW227_0",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 11,
        src: F4,
        product_path: "v3/S221CSIW227_1",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 12,
        src: F2,
        product_path: "v3/S221CSIW227_2",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 13,
        src: F5,
        product_path: "v3/S221CSIW227_3",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 14,
        src: F3,
        product_path: "v3/S221CSIW227_4",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
];

export const SANProductList =
    [
        {
            id: 0,
            src: OasisF21,
            product_path: "em_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 1,
            src: OasisF21,
            product_path: "nr_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 2,
            src: OasisF21,
            product_path: "vb1_0",
            product_counter_part: "vt1_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 3,
            src: OasisF21,
            product_path: "vt1_0",
            product_counter_part: "vb1_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 4,
            src: OasisF21,
            product_path: "vt2_0",
            product_counter_part: "vb2_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 5,
            src: OasisF21,
            product_path: "vb2_0",
            product_counter_part: "vt2_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 6,
            src: OasisF21,
            product_path: "vb2_1",
            product_counter_part: "vt2_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 7,
            src: OasisF21,
            product_path: "vt2_1",
            product_counter_part: "vb2_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 8,
            src: OasisF21,
            product_path: "nt_0",
            product_counter_part: "nb_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 9,
            src: OasisF21,
            product_path: "nt_1",
            product_counter_part: "nb_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 10,
            src: OasisF21,
            product_path: "nt_2",
            product_counter_part: "nb_2",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 11,
            src: OasisF21,
            product_path: "nt_3",
            product_counter_part: "nb_3",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 12,
            src: OasisF21,
            product_path: "nb_0",
            product_counter_part: "nt_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 13,
            src: OasisF21,
            product_path: "nb_1",
            product_counter_part: "nt_1",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 14,
            src: OasisF21,
            product_path: "nb_2",
            product_counter_part: "nt_2",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 15,
            src: OasisF21,
            product_path: "nb_3",
            product_counter_part: "nt_3",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
    ];

export const ANANTAProductList =
    [
        {
            id: 0,
            src: OasisF21,
            product_path: "arie102_0",
            product_counter_part: "arie107_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "ANANTA",
            avatar: "v12",
            shoes: 'arie102107'
        },
        {
            id: 1,
            src: OasisF21,
            product_path: "arie107_0",
            product_counter_part: "arie102_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "ANANTA",
            avatar: "v12",
            shoes: 'arie102107'
        },
    ];

export const thessProductList = [
    {
        id: 0,
        src: VWDRG1150RG,
        product_path: "ng_0",
        product_counter_part: "bdng_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 1,
        src: VWDRG1150RG,
        product_path: "bpwf_0",
        product_counter_part: "bdbpwf_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 2,
        src: VWDRG1150RG,
        product_path: "coo_0",
        product_counter_part: "bdcoo_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 3,
        src: VWDRG1150RG,
        product_path: "ddsg_0",
        product_counter_part: "bdddsg_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 4,
        src: VWDRG1150RG,
        product_path: "modt_0",
        product_counter_part: "bdmodt_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
]

const mapStateToProps = ({ avatarReducer = {} }) => ({
    isAnonymous: get(avatarReducer, 'isAnonymous', true),
    oldUserId: get(avatarReducer, 'oldUserId', ''),
    loadedScene: get(avatarReducer, 'loadedScene'),
});
const AdidasProducts = (props) => {
    const { isAnonymous = true, loadedScene } = useSelector(
        mapStateToProps
    );
    const dispatch = useDispatch();
    const [productList, setProductList] = useState([]);
    const history = useHistory();
    const [selectedProduct, setSelectedProduct] = useState({});
    const [showLogin, setShowLogin] = useState(true);
    const selectProduct = product => {
        loadedScene && loadedScene.dispose();
        dispatch(actionsCreator.SET_LOADED_SCENE(null));
        history.push(`${location.pathname}?productId=${product.id}`);
        // history.push()
        setSelectedProduct(product);
    };
    const location = useLocation();
    useEffect(() => {
        setShowLogin(isAnonymous && !props.bypassLogin);
    }, [isAnonymous, props.bypassLogin])

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            const { search } = location;
            const query = new URLSearchParams(search);
            const productId = query.get('productId');
            if (!productId) {
                setSelectedProduct({});
            }
            return unlisten;
        });
    }, [])


    useEffect(() => {
        const { search } = location;
        const query = new URLSearchParams(search);
        let productList = adidasProductList;
        const productId = query.get('productId');
        if (props.showAll) {
            productList = [...adidasProductList, ...abfrlProductList, ...cluster1ProductList].sort((a, b) => {
                if (a.gender === 'female') {
                    return -1;
                };
                return 1;
            }).map((p, index) => {
                return { ...p, id: index }
            });
        } else if (location.pathname.includes('abfrlm') || location.pathname.includes('abfrlf')) {
            const gender = location.pathname.includes('abfrlm') ? 'male' : 'female'
            productList = abfrlProductList.filter(product => product.gender === gender);
        } else if (location.pathname.includes('abfrl')) {
            productList = abfrlProductList;
            //setBabylonViewer(false);
        } else if (location.pathname.includes('oasis')) {
            productList = cluster1ProductList;
        } else if (location.pathname.includes('valentino')) {
            productList = cluster2ProductList;
        } else if (location.pathname.includes('SAN')) {
            productList = SANProductList;
        }
        else if (location.pathname.includes('ANANTA')) {
            productList = ANANTAProductList;
        }
        else if (location.pathname.includes('thess')) {
            productList = thessProductList;
        }
        else if (location.pathname.includes('nicobar')) {
            productList = nicobarProductList;
        }
        else if (location.pathname.includes('ccclub')) {
            productList = ccclubProductList;
        }
        else if (location.pathname.includes('cava')) {
            productList = cavaProductList;
        }
        else if (location.pathname.includes("test")) {
            productList = testProductList;
        }
        else if (location.pathname.includes("beinghumans")) {
            productList = beingHumansProductList;
        }
        else if (location.pathname.includes("beinghuman")) {
            productList = beingHumanProductListQA;
        } else if (location.pathname.includes("BHuman")) {
            productList = beingHumanProductList;
        } else if (location.pathname.includes("burgerbae")) {
            productList = burgerBaeProductList;
        } else if (location.pathname.includes("enamor")) {
            productList = enamorProductList;
        } else if (location.pathname.includes("rarerabbit-demo")) {
            productList = rarerabbitDemoList;
        } else if (location.pathname.includes("rarerabbit")) {
            productList = rareRabbitProductList;
        } else if (location.pathname.includes("rarerism")) {
            productList = rareismProductList;
        }
        else if (location.pathname.includes("lacoste-demo")) {
            productList = lacosteDemoList;
        } else if (location.pathname.includes("dopplr-demo")) {
            productList = lacosteDemoList;
        } else if (location.pathname.includes("fashor")) {
            productList = fashorProductList;
        } else if (location.pathname.includes("superkicks")) {
            productList = superKicksProductList;
        } else if (location.pathname.includes("fef-demo")) {
            productList = fefDemoProductList;
        } else if (location.pathname.includes("fef")) {
            productList = fefProductList;
        }
        if (location.pathname.includes('puma-female')) {
            productList = pumaProductList.filter(product => product.gender === 'female');
        } else if (location.pathname.includes('puma')) {
            productList = pumaProductList;
        }
        if (location.pathname.includes('nicobar-test')) {
            productList = nicobarFastProductList;
        }
        if (location.pathname.includes('iff')) {
            productList = iffProductList;
        }
        if (location.pathname.includes('virgio')) {
            productList = virgioProductList;
        }
        if (location.pathname.includes('forevernew')) {
            productList = forevernewProductList;
        }
        setProductList(productList);
        if (productId) {
            let selectedProduct = productList.find(product => product.id.toString() === productId.toString());
            selectedProduct && setSelectedProduct(selectedProduct);
        }
    }, [])

    // const onLoad = () => {
    //   const frame = document.getElementById("myFrame");
    //   frame.contentWindow.postMessage(
    //     { message: "getAppData", value: selectedProduct },
    //     `${window.location.origin}/dopplr-avatar`
    //   );
    // }
    const onLoginSuccess = (user) => {
        setShowLogin(false);
    }

    if (showLogin) {
        return <Login onSuccess={onLoginSuccess} />
    }

    const isProductSelected = !isEmpty(selectedProduct);
    return (
        <div className="AdidasProducts">
            <div className="Header">
                <div style={{ height: '45px' }}>
                    <img src={Logo} alt="Dopplr" onClick={() => history.push(`${location.pathname}`)} />
                </div>
                <LoginAction className="Dopplr_LoginAction" />
            </div>
            <div className="PageWrapper">
                {isProductSelected ?
                    <div className="SelectedProduct">
                        <div className="SelectedProduct__Image">
                            {(selectedProduct?.src || selectedProduct.productImage) && (
                                <img src={selectedProduct.productImage || selectedProduct.src} alt={get(selectedProduct, 'name', '')} />
                            )}
                        </div>
                        <div className="SelectedProduct__Description">
                            <div className="SelectedProduct__Name">
                                {get(selectedProduct, "name", "") || selectedProduct.productTitle || ""}
                            </div>
                            <div className="SelectedProduct__PriceContainer">
                                <div className="SelectedProduct__Labels">
                                    {get(selectedProduct, "labels", "")}
                                </div>
                                <div className="SelectedProduct__Price">
                                    {get(selectedProduct, "price", "")}
                                </div>
                            </div>
                            <div className="SelectedProduct__Action">
                                <Button
                                    type="black-white"
                                    className="CreateAvatar"
                                    text="Add to Cart"
                                />
                                <Button
                                    type="white-black"
                                    className="CreateAvatar"
                                    text="Buy Now"
                                />
                            </div>
                            {selectedProduct?.description && <div className="SelectedProduct__Description">
                                <div className="SelectedProduct__Label">Description</div>
                                <div className="SelectedProduct__ShortDescription">
                                    {get(selectedProduct, "description", "")}
                                </div>
                                <ul className="SelectedProduct__BulletDescription">
                                    {get(selectedProduct, "bullet_description", []).map((desc, i) => {
                                        return <li key={i}>{desc}</li>;
                                    })}
                                </ul>
                            </div>}
                        </div>
                    </div> :
                    <div className="ProductList">
                        {productList.map((product, i) => {
                            const isSelected = selectedProduct && selectedProduct.id === product.id;
                            return (
                                <div
                                    key={i}
                                    onClick={() => selectProduct(product)}
                                    className={`ProductCard`}
                                >
                                    <div
                                        className={`ProductCard__Image ${isSelected ? "selected" : "overlay"
                                            }`}
                                    >
                                        <img src={product.productImage || product.src} alt={product.product_path} />
                                    </div>
                                    <div
                                        className={`ProductCard__Description ${isSelected ? "selectedText" : ""
                                            }`}
                                    >
                                        {/* {product.name} */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>}
            </div>
            {isProductSelected && <IframeSection productList={productList} selectedProduct={selectedProduct} />}
        </div>
    );
};

export default AdidasProducts;
