import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { proxyAPI, userServiceAPI } from "api";
import { actionsCreator } from "redux/actions/actionsCreator";
import get from 'lodash/get';
import UploadImage from "containers/uploadImage";
import Layout from "components/Layout";
import Header from "components/Header";
import CloseButton from "components/CloseButton";
import CameraIcon from "assets/icons/camera.svg";
import DoppleLogo from "assets/icons/dopplrLogo.png";
import DeleteIcon from "assets/icons/DeleteBin.svg";
import UploadSuccess from "assets/icons/UploadSuccess.gif";
import Button from "components/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Mixpanel from "appAnalytics/mixPanel";
import "./SelfieModule.scss";
import Loader from 'components/Loader';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';

const mapStateToProps = state => {
    const imageUrl = get(state, "avatarReducer.imageUrl", "");
    const selfie = get(state, "avatarReducer.selfie", "");
    return {
        imageUrl,
        selfie
    };
};

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const SelfieModule = (props) => {
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const selfieRef = useRef(null);
    const { imageUrl, selfie } = useSelector(mapStateToProps);
    const dispatch = useDispatch();
    const { token, jobId } = useParams();
    const history = useHistory();
    const query = useQuery();
    useEffect(() => {
        setTokenAndGetDetails()
    }, []);

    const setTokenAndGetDetails = async () => {
        try {
            setErrorMessage("")
            // const response = await proxyAPI.setAuthCookie({ token });
            dispatch(actionsCreator.SET_TOKEN(`Bearer ${token}`))
            if (query.get("success") === "true") {
                setUploadSuccess(true);
            }
            getJobStatus(jobId, {'Authorization': `Bearer ${token}`});
            // if (response.status === 200) {
            // const userResponse = await userServiceAPI.getAvatarData();
            // let imageUrl = get(userResponse.data, 'userProfile.userAvatar.imageUrl', '');
            // dispatch(actionsCreator.SET_AVATAR_DATA(userResponse.data));
            // dispatch(actionsCreator.SET_AVATAR_IMAGE(imageUrl));

            // } else {
            //     console.log("Token expired");
            // }
        } catch (e) {
            console.log(e);
            setErrorMessage("Page that you are trying to access is no longer available.")
        }
    }

    const uploadImage = (openCamera) => {
        if (selfieRef && selfieRef.current) {
            if (openCamera) {
                selfieRef.current.capture = 'user';
                Mixpanel.buttonClicked(EVENT_CATEGORIES.TAKE_PHOTO, EVENT_ACTIONS.SELFIE_CAMERA_OPENED);
            } else {
                selfieRef.current.removeAttribute('capture');
                Mixpanel.buttonClicked(EVENT_CATEGORIES.TAKE_PHOTO, EVENT_ACTIONS.SELFIE_UPLOAD_FROM_DEVICE);
            }
            selfieRef.current.click();
        }
    };

    const onCloseClick = () => {
        dispatch(actionsCreator.SET_AVATAR_IMAGE(""));
        dispatch(actionsCreator.SET_SELFIE_FILE(""));
    }

    const getJobStatus = async (jobId, headers) => {
        try {
            let response = await userServiceAPI.getJobStatus({ jobId }, headers);
            let status = get(response, "data.job.jobState");
            const success = get(response, "data.job.result.success", false);
            if (status === "COMPLETED" && success) {
                setUploadSuccess(true);
            } else {
                setUploadSuccess(false);
            }
            setPageLoader(false);
        } catch (e) {
            console.error(e);
            setErrorMessage("Page that you are trying to access is no longer available.");
            setPageLoader(false);
        }
    };

    const confirmHandler = async () => {
        if (!loader) {
            try {
                const postData = new FormData();
                setLoader(true);
                if (selfie) {
                    postData.append("selfie", selfie);
                    postData.append("jobId", jobId);
                    const response = await userServiceAPI.uploadQRImage(postData);
                    setUploadSuccess(true);
                    history.replace({ search: '?success=true', });
                }
                setLoader(false);
            } catch (e) {
                console.log(e);
                setLoader(false);
            }
        }
    };
    const heading = uploadSuccess ? "Photo uploaded successfully" : "Upload a photo"
    return (
        <Layout style={{ zIndex: 4, background: '#000' }}>
            {/* <Header>
                <CloseButton onClick={props.onClose} />
            </Header> */}
            {pageLoader ? <Loader /> :
                <div className='Dopplr__SelfieModuleContainer'>
                    <img className="Dopplr__SelfieModuleContainer__Logo" src={DoppleLogo} alt="Dopplr" />
                    <div className='Dopplr__SelfieModuleContainer__Heading'>{heading}</div>
                    {uploadSuccess ? <img src={UploadSuccess} style={{ width: 265, paddingTop: '1em' }} />
                        : <>
                            <div className='Dopplr__SelfieModuleContainer__ImagePreview'>
                                {imageUrl ? (
                                    <div className="Dopplr__SelfieModuleContainer__UploadImageContainer__UploadedImage">
                                        <img src={imageUrl} alt="Selfie" className="Dopplr__SelfieModuleContainer__UploadImageContainer__UploadedImage__Image" />
                                        {!loader && <div className={"Dopplr__SelfieModuleContainer__Delete"} onClick={onCloseClick}>
                                            <img src={DeleteIcon} alt="Delete" />
                                        </div>}
                                    </div>
                                ) :
                                    < img src={CameraIcon} alt="camera" />}
                            </div>
                            {loader && <CircularProgress sx={{ color: 'white' }} />}
                            {!loader && <div className='Dopplr__SelfieModuleContainer__Actions'>
                                {imageUrl ? <Button
                                    type="white-black"
                                    className="Dopplr__SelfieModuleContainer__ActionButton"
                                    text="Confirm"
                                    onClick={confirmHandler} />
                                    : <>
                                        <Button
                                            type="white-black"
                                            className="Dopplr__SelfieModuleContainer__ActionButton"
                                            text="Take a selfie"
                                            onClick={() => uploadImage(true)}
                                        />
                                        <Button
                                            type="black-white"
                                            className="Dopplr__SelfieModuleContainer__ActionButton"
                                            text="Upload from device"
                                            onClick={() => uploadImage(false)}
                                        />
                                    </>}
                            </div>}
                            <div className='Dopplr__SelfieModuleContainer__Instructions'>
                                <div className='Dopplr__SelfieModuleContainer__Instructions__Heading'>Instructions</div>
                                <ul className='Dopplr__SelfieModuleContainer__Instructions__List'>
                                    <li className='Dopplr__SelfieModuleContainer__Instructions__Content'>Make sure your picture is well-lit and the light is even on a face and body.</li>
                                    <li className='Dopplr__SelfieModuleContainer__Instructions__Content'>Avoid using low quality, pixelated or blurry images</li>
                                </ul>
                            </div>
                        </>}
                </div>}
            <UploadImage ref={selfieRef} />
        </Layout>
    )
}

export default SelfieModule