import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ACTION_TYPES, MEASUREMENTS_TYPE, MEASUREMENT_SCREEN_TYPE } from 'utils/constants';
import MainMeasurement from './MainMeasurement';
import BrandMeasurement from './BrandMeasurement';
import TuneAvatarMeasurement from './TuneAvatarMeasurement';
import FaceSelection from './FaceSelection';
import { actionsCreator } from 'redux/actions/actionsCreator';
import { toolAPI, userServiceAPI } from 'api';
import MixPanel from "appAnalytics/mixPanel";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "appAnalytics/eventCategories";
import StepProgress from './StepProgress';
import "./Customize.scss";
import { IFRAME_ACTIONS } from 'actions/IframeActions';
import { DEFAULT_AVATARS } from 'configs/RendererConfig';
import BustMeasurement from './BustMeasurement';

const mapStateToProps = state => {
    const imageUrl = get(state, "avatarReducer.imageUrl", "");
    const selfie = get(state, "avatarReducer.selfie", "");
    const isDefaultAvatar = get(state, "avatarReducer.isDefaultAvatar", false);
    const defaultUserProfile = get(state, 'avatarReducer.defaultUserProfile');
    const uploadLoader = get(state, 'avatarReducer.uploadLoader', false);
    const product = get(state, "avatarReducer.product", {});
    const loadedScene = get(state, "avatarReducer.loadedScene");
    const relatedBrands = get(state, "avatarReducer.relatedBrands", {});
    let uid = get(state, 'avatarReducer.uid', '');
    let token = get(state, 'avatarReducer.token', '');
    let userProfile = get(state, "avatarReducer.userProfile", {});
    return {
        imageUrl,
        selfie,
        userProfile,
        isDefaultAvatar,
        defaultUserProfile,
        uploadLoader,
        product,
        loadedScene,
        relatedBrands,
        uid,
        token,
    };
};

const getDefaultScreen = (actionType) => {
    switch (actionType) {
        case ACTION_TYPES.MEASUREMENTS:
            return MEASUREMENT_SCREEN_TYPE.MAIN_MEASUREMENT;
        case ACTION_TYPES.BUST_MEASUREMENT:
            return MEASUREMENT_SCREEN_TYPE.BUST_MEASUREMENT;
        default:
            return MEASUREMENT_SCREEN_TYPE.FACE_SELECTION;
    }
}

const Customize = (props) => {
    const { imageUrl, uploadLoader, userProfile, selfie, isDefaultAvatar, defaultUserProfile, product, loadedScene, relatedBrands, uid, token } = useSelector(mapStateToProps);
    const { brand } = product;
    const { MAIN_MEASUREMENT, BRAND_MEASUREMENT, TUNE_AVATAR_MEASUREMENT, FACE_SELECTION, BUST_MEASUREMENT } = MEASUREMENT_SCREEN_TYPE;
    const { actionType = ACTION_TYPES.NONE } = props;
    const [customisationScreenType, setCustomisationScreen] = useState(getDefaultScreen(actionType));
    const [selfieResponse, setSelfieResponse] = useState(null);
    const [version, setVersion] = useState("temp");
    const [customizeLoading, setCustomizeLoading] = useState(false);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [callUpdateAvatar, setCallUpdateAvatar] = useState(false);
    const [measurementType, setMeasurementType] = useState(MEASUREMENTS_TYPE.DEFAULT);
    const dispatch = useDispatch();
    const { gender, name = "", height, weight, userAvatar = {} } = userProfile;
    const { chest, waist, hip } = userAvatar || {};
    const [measurementPayload, setMeasurementPayload] = useState(null);
    const [predictedMeasurement, setPredictedMeasurement] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const defaultBasicInfo = {
        name: name,
        gender: (product.gender !== 'unisex' ? product.gender : gender) || "male",
    };
    const [basicInfo, setBasicInfo] = useState(defaultBasicInfo);

    const nextHandler = (payload, measurementType = MEASUREMENTS_TYPE.DEFAULT) => {
        if (customisationScreenType === FACE_SELECTION) {
            setCustomisationScreen(MAIN_MEASUREMENT);
        } else {
            setMeasurementType(measurementType);
            setMeasurementPayload(payload);
            if (actionType === ACTION_TYPES.MEASUREMENTS || actionType === ACTION_TYPES.BUST_MEASUREMENT) {
                updateMeasurements({ ...payload, measurementType });
            } else {
                setCallUpdateAvatar(true);
                setCustomizeLoading(true);
            }
        }
    }
    const skipHandler = (measurementType) => {
        setMeasurementType(measurementType);
        switch (measurementType) {
            case MEASUREMENTS_TYPE.BRAND:
                setCustomisationScreen(BRAND_MEASUREMENT);
                break;
            case MEASUREMENTS_TYPE.SHAPE_SELECTION:
                setCustomisationScreen(TUNE_AVATAR_MEASUREMENT);
                break;
            default:
                setCustomisationScreen(MAIN_MEASUREMENT);
                break;
        }
    }

    const backHandler = () => {
        if (customisationScreenType === TUNE_AVATAR_MEASUREMENT || customisationScreenType === BRAND_MEASUREMENT) {
            setCustomisationScreen(MAIN_MEASUREMENT);
        } else if (actionType === ACTION_TYPES.DEFAULT && customisationScreenType === MAIN_MEASUREMENT) {
            setCustomisationScreen(FACE_SELECTION);
            setActiveStepIndex(0);
        } else {
            props.backHandler();
        }
    }


    const setAvatarMeasurements = async (measurements) => {
        const { gender } = basicInfo;
        if (isEmpty(measurements)) {
            measurements = { chest, waist, hip, height, weight, highHip: waist }
        };
        try {
            const payload = {
                ...measurements,
                highHip: measurements.waist,
                gender: gender,
                maleHairId: null,
                femaleHairId: null,
            }
            if (userProfile?.gender !== gender && actionType === ACTION_TYPES.MEASUREMENTS) {
                payload.defaultSelfiePath = DEFAULT_AVATARS[gender][0].value;
                payload.version = 'default';
            }
            MixPanel.buttonClicked(EVENT_CATEGORIES.AVATAAR_CREATION, EVENT_ACTIONS.MEASUREMENTS_SENT, { ...payload, actionType })
            const response = await userServiceAPI.updateUserData({ payload });
            return response;
        } catch (e) {
            // setErrorMessage('Something went wrong. Please try again later.');
        }
    };

    const updateMeasurements = async (payload = measurementPayload) => {
        setCustomizeLoading(true);
        try {

            const avatarResponse = await setAvatarMeasurements(payload);
            if (avatarResponse?.status === 200) {
                let response = await userServiceAPI.getAvatarData();
                let userData = response.data;
                let imageUrl = get(userData, 'userProfile.userAvatar.imageUrl', '');
                loadedScene && loadedScene.dispose();
                dispatch(actionsCreator.SET_LOADED_SCENE(null));
                dispatch(actionsCreator.SET_AVATAR_DATA(userData));
                dispatch(actionsCreator.SET_AVATAR_IMAGE(imageUrl));
                dispatch(actionsCreator.SET_API_IMAGE(imageUrl));
                setMeasurementPayload(null);
                const aid = isEmpty(userData?.userProfile?.userAvatar) ? '' : userData?.userProfile?.id;
                const isAnonymous = !uid || (uid && uid.includes('DopplrGuest'));
                MixPanel.buttonClicked(EVENT_CATEGORIES.AVATAAR_CREATION, EVENT_ACTIONS.AVATAR_SUCCESSFULLY_CREATED, { ...payload, actionType });
                window.parent.postMessage({ message: IFRAME_ACTIONS.SET_USER_DATA, value: { uat: token?.replace(`Bearer `, '') || '', isAnonymous, uid, aid } }, '*');
                props.updateAvatar(userData.userProfile);
            }
            setCustomizeLoading(false);
            setCallUpdateAvatar(false);
        } catch (e) {
            setCustomizeLoading(false);
            console.error(e);
        }
    }

    const getSelfieJobStatus = async jobId => {
        try {
            setSelfieResponse(null);
            let response = await userServiceAPI.getJobStatus({ jobId });
            let status = get(response, "data.job.jobState");
            while (status === "INPROGRESS") {
                const timer = ms => new Promise(res => setTimeout(res, ms));
                await timer(5000);
                response = await userServiceAPI.getJobStatus({ jobId });
                status = get(response, "data.job.jobState");
            };
            setSelfieResponse(response.data);
            //   setSelfieLoading(false);
        } catch (e) {
            console.error(e);
            //   setSelfieLoading(false);
        }
    };

    const onBasicInfoChangeHandler = (e, key, value) => {
        const updatedBasicInfo = { ...basicInfo };
        updatedBasicInfo[key] = value || e.target.value;
        setBasicInfo(updatedBasicInfo);
    };


    const avatarCreationStatus = async () => {
        const selfieStatus = get(selfieResponse, "job.jobState");
        if (actionType === ACTION_TYPES.DEFAULT) {
            dispatch(actionsCreator.SET_INSTRUCTION_LIST([]));
            dispatch(actionsCreator.SET_FIRST_TIME_CREATION(true));
        }
        if (!selfieStatus) {
            setCustomizeLoading(false);
        }
        if (selfieStatus === "COMPLETED" || selfieStatus === 'FAILED') {
            const success = get(selfieResponse, "job.result.success", false);
            if ((selfieStatus === "COMPLETED" && success)) {
                updateMeasurements();
            } else {
                const selfieFailure = get(
                    selfieResponse,
                    "job.result.failureMessage",
                    ""
                );
                const failureMessage = "Something went wrong. Please try again later."
                setErrorMessage('Something went wrong. Please try again later.');
                setCustomizeLoading(false);
                console.error(selfieFailure || failureMessage);
            }
        }
    }

    useEffect(() => {
        if (callUpdateAvatar) {
            if (selfieResponse) {
                avatarCreationStatus();
            }
        }
    }, [selfieResponse, callUpdateAvatar]);

    const renderCustomizationStep = () => {
        switch (customisationScreenType) {
            case FACE_SELECTION: {
                return <FaceSelection
                    backHandler={backHandler}
                    nextHandler={nextHandler}
                    getSelfieJobStatus={getSelfieJobStatus}
                    setCallUpdateAvatar={setCallUpdateAvatar}
                    actionType={props.actionType}
                    setVersion={setVersion}
                    version={version}
                    setLoader={setCustomizeLoading}
                    loader={customizeLoading}
                    setSelfieResponse={setSelfieResponse}
                    setActiveStepIndex={setActiveStepIndex}
                    onBasicInfoChangeHandler={onBasicInfoChangeHandler}
                    basicInfo={basicInfo}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            }
            case MAIN_MEASUREMENT: {
                return <MainMeasurement
                    actionType={props.actionType}
                    measurementType={measurementType}
                    setMeasurementPayload={setMeasurementPayload}
                    setPredictedMeasurement={setPredictedMeasurement}
                    nextHandler={nextHandler}
                    skipHandler={skipHandler}
                    backHandler={backHandler}
                    setMeasurementType={setMeasurementType}
                    setActiveStepIndex={setActiveStepIndex}
                    customizeLoader={customizeLoading}
                />
            }
            case BRAND_MEASUREMENT: {
                return <BrandMeasurement
                    actionType={props.actionType}
                    relatedBrands={relatedBrands}
                    garmentType={product?.garment_type}
                    nextHandler={nextHandler}
                    skipHandler={skipHandler}
                    backHandler={backHandler}
                    gender={basicInfo.gender}
                    loader={customizeLoading}
                    setActiveStepIndex={setActiveStepIndex}
                />
            }
            case TUNE_AVATAR_MEASUREMENT: {
                return <TuneAvatarMeasurement
                    actionType={props.actionType}
                    nextHandler={nextHandler}
                    backHandler={backHandler}
                    gender={basicInfo.gender}
                    measurementPayload={measurementPayload}
                    predictedMeasurement={predictedMeasurement}
                    setMeasurementPayload={setMeasurementPayload}
                    setActiveStepIndex={setActiveStepIndex}
                    loader={customizeLoading}
                />
            }
            case BUST_MEASUREMENT: {
                return <BustMeasurement
                    actionType={props.actionType}
                    nextHandler={nextHandler}
                    backHandler={backHandler}
                    gender={basicInfo.gender}
                    loader={customizeLoading}
                    setActiveStepIndex={setActiveStepIndex}
                />
            }
            default: {
                return <div>Error</div>
            }
        }
    }
    const stepCount = actionType === ACTION_TYPES.DEFAULT ? 3: 3;
    return (
        <div className={`Dopplr_Customize ${customisationScreenType === FACE_SELECTION ? '' : 'background'}`}>
            {(actionType === ACTION_TYPES.DEFAULT || actionType === ACTION_TYPES.BUST_MEASUREMENT) && <StepProgress stepCount={stepCount} activeStepIndex={activeStepIndex} />}
            {renderCustomizationStep()}
        </div >
    )
}

export default Customize;
