import { Color3 } from "@babylonjs/core";
import F1 from 'assets/ModelIcons/F1Old.jpg';
import F2 from 'assets/ModelIcons/F2Old.jpg';
import F3 from 'assets/ModelIcons/F3.jpg';
import M1 from 'assets/ModelIcons/M1.jpg';
import { GENDER } from "utils/constants";

export const RENDERER_CONFIG = {
  HDR_LIGHT_ENV: "/environment/Adidas1_Flip/",
  SHADOW_DARKNESS: 0,                        //Shadow darkness (0.0 = dark shadow, 1.0 = No shadow)
  IS_PLAY_ANIMATIONS_ENABLED: false,
  IS_HDR_ENV_ENABLED: true,
  IS_SPOT_LIGHT_ENABLED: true,
  // ---------------------#####  HEAD  #####----------------------------------------
  // DYNAMIC_HEAD_MODEL_FILE_NAME: "avatarMale.glb",
  DYNAMIC_HEAD_MODEL_FILE_NAME: "default_male.glb",
  DYNAMIC_HEAD_MODEL_FEMALE: 'draco_default_female_1.glb',
  ASSETVIEWER_HEAD_MODEL_FILE_NAME: "draco_avatarMale_PompadourHair2.glb",
  ASSETVIEWER_HEAD_MODEL_MALE_LARGE: "larger_head_4.glb",
  ASSETVIEWER_HEAD_MODEL_FEMALE: 'draco_avatarFemale_LooseHair4.glb',
  DRACO_HEAD_MATERIAL_NAME: "material0",
  HEAD_MATERIAL_NAME: "material3",
  HEAD_TEXTURE_NAME: "GLTF2/Texture/BS_test_01_img1.jpg",
  HAIR_MATERIAL_NAME: "hair_mat",
  DYNAMIC_HAIR_TEXTURE_NAME: "GLTF2/Texture/BS_test_01_img0.png",

  // ---------------------#####  BODY  #####-----------------------------------------
  DYNAMIC_BODY_MODEL_FILE_NAME: "female_avatar_04.gltf",
  UPPER_BODY_MESHES: ["l_hand_geo", "r_hand_geo", "spine_geo"],
  UPPER_BODY_MORPHS: ["upper_l_hand_bs", "upper_r_hand_bs", "upper_spine_bs"],
  MID_BODY_MESHES: ["spine_geo"],
  MID_BODY_MORPHS: ["middle_spine_bs"],
  LOWER_BODY_MESHES: ["l_leg_geo", "r_leg_geo", "spine_geo"],
  LOWER_BODY_MORPHS: ["lower_l_leg_bs", "lower_r_leg_bs", "lower_pine_bs"],
  BODY_MATERIAL_NAME: "body_mat",
  DYNAMIC_BODY_TEXTURE_NAME: "GLTF2/Texture/BS_test_01_img1.jpg",

  // ---------------------#####   CLOTH   #####--------------------------------------------
  DYNAMIC_CLOTH_MODEL_FILE_NAME: "female_grey_dress_01.gltf",
  CLOTH_MESH: ["grey_skirt_geo"],
  UPPER_CLOTH_MORPHS: ["upper_cloth_bs"],
  MID_CLOTH_MORPHS: ["middle_cloth_bs"],
  LOWER_CLOTH_MORPHS: ["lower_cloth_bs"],
  CLOTH_MATERIAL_NAME: "cloth_mat",
  CLOTH_MATERIAL_BASE_COLOR: Color3.FromInts(128, 128, 128),  //set only for grey dress (needs to come from DB)
  DYNAMIC_CLOTH_TEXTURE_NAME: "GLTF2/DressTexture/Grey/Cloth_Diff.jpg",
  DYNAMIC_CLOTH_BUMPS_TEXTURE_NAME: "GLTF2/DressTexture/Grey/Cloth_Nrml.jpg",
  DYNAMIC_CLOTH_ROUGHNESS_TEXTURE_NAME: "GLTF2/DressTexture/Grey/Cloth_Spec.jpg",
  // --------------------##### SCALE-INFLUENCE #####----------------------------------------
  DYNAMIC_UPPER_MORPH_INFLUENCE: 0.0,                        //Influence for morph target (0.0 = no influence, 1.0 = full influence))
  DYNAMIC_MID_MORPH_INFLUENCE: 0.0,
  DYNAMIC_LOWER_MORPH_INFLUENCE: 0.0,
  // ---------------------------------
};

export const CLOTHES = {
  0: {
    DYNAMIC_LOWER_BODY_CLOTH_MODEL_FILE_NAME: "necklace2.glb",
    DYNAMIC_CLOTH_MODEL_FILE_NAME: "female_red_dress_01.gltf",
    DYNAMIC_CLOTH_MESH: ["red_skirt_geo"],
    DYNAMIC_CLOTH_TEXTURE_NAME: "GLTF2/DressTexture/Red/Cloth_Diff.jpg",
    DYNAMIC_CLOTH_BUMPS_TEXTURE_NAME: "GLTF2/DressTexture/Red/Cloth_Nrml.jpg",
    DYNAMIC_CLOTH_ROUGHNESS_TEXTURE_NAME: "GLTF2/DressTexture/Red/Cloth_Spec.jpg",
    DYNAMIC_CLOTH_BASE_COLOR: Color3.FromInts(255, 26, 92),
    DYNAMIC_CLOTH_MAT_TYPE: 'velvet',
    DYNAMIC_CLOTH_ROUGHNESS: 1,
    DYNAMIC_CLOTH_METALLIC: 0,
    DYNAMIC_CLOTH_ENV_INTENSITY: 0.5,
    DYNAMIC_ENV_NAME: "environment/studio_environment.env",
    DYNAMIC_HANDS_POSITION: "down",
  },
  1: {
    DYNAMIC_LOWER_BODY_CLOTH_MODEL_FILE_NAME: "necklace2.glb",
    DYNAMIC_CLOTH_MODEL_FILE_NAME: "female_pink_dress_01.gltf",
    DYNAMIC_CLOTH_MESH: ["pink_skirt_geo"],
    DYNAMIC_CLOTH_TEXTURE_NAME: "GLTF2/DressTexture/LightPink/Cloth_Diff2.jpg",
    DYNAMIC_CLOTH_BUMPS_TEXTURE_NAME: "GLTF2/DressTexture/LightPink/Cloth_Nrml.jpg",
    DYNAMIC_CLOTH_ROUGHNESS_TEXTURE_NAME: "GLTF2/DressTexture/LightPink/Cloth_Spec.jpg",
    DYNAMIC_CLOTH_BASE_COLOR: Color3.FromHexString("#DFB8B6"),
    DYNAMIC_CLOTH_ROUGHNESS: 1,
    DYNAMIC_CLOTH_METALLIC: 0,
    DYNAMIC_CLOTH_ENV_INTENSITY: 0.8,
    DYNAMIC_ENV_NAME: "environment/studio_environment.env",
    DYNAMIC_CLOTH_MAT_TYPE: 'lux-chiffon',
    DYNAMIC_HANDS_POSITION: "raised",
  },
  2: {
    DYNAMIC_LOWER_BODY_CLOTH_MODEL_FILE_NAME: "necklace2.glb",
    DYNAMIC_CLOTH_MODEL_FILE_NAME: "female_grey_dress_01.gltf",
    DYNAMIC_CLOTH_MESH: ["grey_skirt_geo"],
    DYNAMIC_CLOTH_TEXTURE_NAME: "GLTF2/DressTexture/Grey/Cloth_Diff2.jpg",
    DYNAMIC_CLOTH_BUMPS_TEXTURE_NAME: "GLTF2/DressTexture/Grey/Cloth_Nrml.jpg",
    DYNAMIC_CLOTH_ROUGHNESS_TEXTURE_NAME: "GLTF2/DressTexture/Grey/Cloth_Spec.jpg",
    DYNAMIC_CLOTH_BASE_COLOR: Color3.FromInts(128, 129, 125),
    DYNAMIC_CLOTH_ROUGHNESS: 1,
    DYNAMIC_CLOTH_METALLIC: 0,
    DYNAMIC_CLOTH_ENV_INTENSITY: 0.9,
    DYNAMIC_ENV_NAME: "environment/studio_environment.env",
    DYNAMIC_CLOTH_MAT_TYPE: 'matte-satin',
    DYNAMIC_HANDS_POSITION: "raised",
  },
  3: {
    DYNAMIC_CLOTH_MODEL_FILE_NAME: "saree.glb",
    DYNAMIC_LOWER_BODY_CLOTH_MODEL_FILE_NAME: "necklace.glb",
    DYNAMIC_CLOTH_MESH: ["geo_M_saree"],
    DYNAMIC_CLOTH_TEXTURE_NAME: "female_hourglass_dress_02/female_hourglass_dress_02_L/tShirt/textures_fbx/dress_L_female_t-shirt_diffuse.jpg",
    DYNAMIC_CLOTH_BUMPS_TEXTURE_NAME: "female_hourglass_dress_02/female_hourglass_dress_02_L/tShirt/textures_fbx/dress_L_female_t-shirt_normal.jpg",
    DYNAMIC_CLOTH_ROUGHNESS_TEXTURE_NAME: "public/female_hourglass_dress_02/female_hourglass_dress_02_L/tShirt/textures_fbx/dress_L_female_t-shirt_roughness.jpg",
    DYNAMIC_CLOTH_BASE_COLOR: Color3.FromInts(255, 26, 92),
    DYNAMIC_CLOTH_MAT_TYPE: 'velvet',
    DYNAMIC_CLOTH_ROUGHNESS: 1,
    DYNAMIC_CLOTH_METALLIC: 1,
    DYNAMIC_CLOTH_ENV_INTENSITY: 5,
    DYNAMIC_ENV_NAME: "environment/studio_environment.env",
    DYNAMIC_HANDS_POSITION: "down",
    DYNAMIC_BODY_MODEL_FILE_NAME: "body.glb",
    BODY_MATERIAL_NAME: "geo_M_tShirt",
    DYNAMIC_BODY_TEXTURE_NAME: "GLTF2/Texture/BS_test_01_img1.jpg",
    CLOTH_MATERIAL_NAME: "dress_M_female_tshirt",
  }
}

//Measurements
export const MEASUREMENTS = {
  female: {
    default: {
      height: 168,
      weight: 60,
      chest: 96,
      waist: 76,
      hip: 105
    },
    max: {
      height: 168,
      weight: 125,
      chest: 140,
      waist: 122,
      hip: 145
    }
  },
  male: {
    default: {
      height: 179,
      weight: 62,
      chest: 96,
      waist: 76,
      hip: 105
    },
    max: {
      height: 188,
      weight: 215,
      chest: 150,
      waist: 140,
      hip: 140
    }
  }
}

export const BODY_TYPES = {
  RECTANGLE: 'rectangle',
  INV_TRIANGLE: 'inverted_triangle',
  TRIANGLE: 'triangle',
  SPOON: 'spoon',
  HOURGLASS: 'hourglass',
  APPLE: 'apple',
}

export const CUP_TYPES = {
  BC: 'BC',
  CD: 'CD',
  DDD: 'DDD',
}

export const CUP_IDENTIFIER = {
  0: CUP_TYPES.BC,
  1: CUP_TYPES.CD,
  2: CUP_TYPES.DDD,
}

export const SHAPE_TYPES = {
  A: 'A',
  B: 'B',
  C: 'C',
}

export const SHAPE_IDENTIFIER = {
  0: SHAPE_TYPES.A,
  1: SHAPE_TYPES.B,
  2: SHAPE_TYPES.C,
}

export const POSITION_TYPES = {
  A: 'A',
  B: 'B',
  C: 'C',
}

export const POSITION_IDENTIFIER = {
  0: POSITION_TYPES.A,
  1: POSITION_TYPES.B,
  2: POSITION_TYPES.C,
}

export const BODY_IDENTIFIER = {
  0: BODY_TYPES.RECTANGLE,
  1: BODY_TYPES.INV_TRIANGLE,
  2: BODY_TYPES.TRIANGLE,
  3: BODY_TYPES.SPOON,
  4: BODY_TYPES.HOURGLASS,
}

export const DEFAULT_AVATARS = {
  [GENDER.FEMALE]: [
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_female_1.jpg",
      type: 'image',
      value: 'default_female_1'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_female_2.jpg",
      type: 'image',
      value: 'default_female_2'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_female_3.jpg",
      type: 'image',
      value: 'default_female_3'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_female_4.jpg",
      type: 'image',
      value: 'default_female_4'
    }
  ],
  [GENDER.MALE]: [
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_male_1.jpg",
      type: 'image',
      value: 'default_male_1'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_male_2.jpg",
      type: 'image',
      value: 'default_male_2'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_male_3.jpg",
      type: 'image',
      value: 'default_male_3'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_male_4.jpg",
      type: 'image',
      value: 'default_male_4'
    },
    {
      url: "https://public-hs-v1.s3.eu-west-3.amazonaws.com/default/default_male_5.jpg",
      type: 'image',
      value: 'default_male_5'
    },
  ]
}

export const CAMERA_TYPE = {
  BODY: 'Body',
  TOP: 'Top',
  BOTTOM: 'Bottom',
  SHOES: 'Shoes',
  HEATMAP_TOP: 'HeatmapTop',
  HEATMAP_BOTTOM: 'HeatmapBottom',
  WELCOME_SCREEN: 'WelcomeScreen',
  FACE_SELECTION: 'FaceSelection',
  FACE_SELECTION_FEMALE: 'FaceSelectionFemale',
  VIEW_MODE: 'ViewMode',
}

export const CAMERA_MODE = {
  [CAMERA_TYPE.BODY]: {
    alpha: 0.966,
    beta: 1.5395,
    radius: 2.75,
    fov: 0.95,
    target: { x: 0.0, y: 0.6, z: 0.0 },
    type: CAMERA_TYPE.BODY
  },
  [CAMERA_TYPE.VIEW_MODE]: {
    alpha: 0.966,
    beta: 1.5395,
    radius: 2.75,
    fov: 0.75,
    target: { x: 0.0, y: 0.8, z: 0.0 },
    type: CAMERA_TYPE.VIEW_MODE
  },
  [CAMERA_TYPE.WELCOME_SCREEN]: {
    // alpha: 1.128,
    // beta: 1.515,
    alpha: 1.5366,
    beta: 1.5395,
    radius: 2.75,
    fov: 0.95,
    target: { x: 0.0, y: 0.6, z: 0.0 },
    type: CAMERA_TYPE.WELCOME_SCREEN
  },
  [CAMERA_TYPE.TOP]: {
    alpha: 0.903,
    beta: 1.3859,
    radius: 1.3,
    target: { x: -0.008, y: 1.138, z: -0.005 },
    type: CAMERA_TYPE.TOP,
  },
  [CAMERA_TYPE.BOTTOM]: {
    alpha: 2.3325,
    beta: 1.578,
    radius: 1.5391,
    target: { x: 0.001, y: 0.546, z: -0.076 },
    type: CAMERA_TYPE.BOTTOM,
  },
  [CAMERA_TYPE.FACE_SELECTION]: {
    alpha: 1.5366,
    beta: 1.5395,
    radius: 1.8,
    // target: {x: 0.001, y: 1.346, z: -0.076},
    target: { x: 0.001, y: 1.48, z: -0.076 },
    type: CAMERA_TYPE.FACE_SELECTION,
  },
  [CAMERA_TYPE.FACE_SELECTION_FEMALE]: {
    alpha: 1.5366,
    beta: 1.5395,
    radius: 1.6,
    target: { x: 0.001, y: 1.29, z: -0.076 },
    type: CAMERA_TYPE.FACE_SELECTION_FEMALE,
  },
  [CAMERA_TYPE.SHOES]: {
    alpha: 0.2,
    beta: 1.181,
    radius: 1.07,
    fov: 0.8,
    target: { x: 0.00, y: 0.08, z: 0.00 },
    type: CAMERA_TYPE.SHOES,
  },
  [CAMERA_TYPE.HEATMAP_TOP]: {
    alpha: 0.903,
    beta: 1.3859,
    radius: 1.3,
    target: { x: -0.008, y: 1.138, z: -0.005 },
    type: CAMERA_TYPE.HEATMAP_TOP,
  },
  [CAMERA_TYPE.HEATMAP_BOTTOM]: {
    alpha: 2.3325,
    beta: 1.578,
    radius: 1.5391,
    target: { x: 0.001, y: 0.546, z: -0.076 },
    type: CAMERA_TYPE.HEATMAP_BOTTOM,
  },
  enamor: {
    [CAMERA_TYPE.BODY]: {
      alpha: 0.903,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.BODY
    },
    [CAMERA_TYPE.VIEW_MODE]: {
      alpha: 0.903,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.VIEW_MODE
    },
    [CAMERA_TYPE.WELCOME_SCREEN]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.WELCOME_SCREEN
    },
    [CAMERA_TYPE.TOP]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.TOP
    },
    [CAMERA_TYPE.BOTTOM]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.BOTTOM
    },
    [CAMERA_TYPE.FACE_SELECTION]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.FACE_SELECTION
    },
    [CAMERA_TYPE.FACE_SELECTION_FEMALE]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.FACE_SELECTION_FEMALE
    },
    [CAMERA_TYPE.SHOES]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.SHOES
    },
    [CAMERA_TYPE.HEATMAP_TOP]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.HEATMAP_TOP
    },
    [CAMERA_TYPE.HEATMAP_BOTTOM]: {
      alpha: 1,
      beta: 1.3859,
      radius: 1.0,
      target: { x: -0.008, y: 1.28, z: -0.005 },
      type: CAMERA_TYPE.HEATMAP_BOTTOM
    },
  },
};

// export const CAMERA_MODE_ASSET = {
//   Body: {
//     alpha: 1.128,
//     beta: 1.515,
//     radius: 2.75,
//     fov: 0.8,
//     target: { x: 0.0, y: 0.8, z: 0.0 },
//     type: 'Body'
//   },
//   Top: {
//     alpha: 1.5708,
//     beta: 1.2566,
//     // radius: 1.5801,
//     radius: 0.3000,
//     fov: 0.8,
//     target: { x: 0.0, y: 1.16, z: -0.10 },
//     type: 'Top'
//   },
//   Bottom: {
//     alpha: 1.5539,
//     beta: 1.2474,
//     // radius: 1.035,
//     radius: 0.3000,
//     fov: 0.8,
//     target: { x: 0.0, y: 0.65, z: 0.05 },
//     type: 'Bottom'
//   },
//   Foot: {
//     alpha: 1.5783,
//     beta: 1.181,
//     radius: 1.07,
//     fov: 0.8,
//     target: { x: 0.00, y: 0.08, z: 0.00 },
//     type: 'Foot'
//   }
// }

export const CAMERA_MODE_ASSET = {
  Body: {
    alpha: 1.128,
    beta: 1.515,
    radius: 2.75,
    fov: 0.82,
    target: { x: 0.0, y: 0.8, z: 0.0 },
    type: 'Body'
  },
  Top: {
    alpha: 1.5708,
    beta: 1.2566,
    radius: 1.5801,
    fov: 0.8,
    target: { x: 0.0, y: 1.16, z: -0.10 },
    type: 'Top'
  },
  Bottom: {
    alpha: 1.5539,
    beta: 1.2474,
    radius: 1.035,
    fov: 0.8,
    target: { x: 0.0, y: 0.65, z: 0.05 },
    type: 'Bottom'
  },
  Foot: {
    alpha: 1.5783,
    beta: 1.181,
    radius: 1.07,
    fov: 0.8,
    target: { x: 0.00, y: 0.08, z: 0.00 },
    type: 'Foot'
  },
}

export const HAIR_STYLES = {
  'female': {
    'Original': {
      name: 'Original',
      path: '',
      gender: 'female',
      thumbnailImage: '',
    },
    'PonyTailHair': {
      name: 'PonyTailHair',
      path: '/hairs/female/onlyPonyTailHair.glb',
      gender: 'female',
      thumbnailImage: F1,
    },
    'LooseHair': {
      name: 'LooseHair',
      path: '/hairs/female/onlyLooseHair.glb',
      gender: 'female',
      thumbnailImage: F2,
    },
    'BraidedHair': {
      name: 'BraidedHair',
      path: '/hairs/female/onlyBraidedHair.glb',
      gender: 'female',
      thumbnailImage: F3,
    },
  },
  'male': {
    'Original': {
      name: 'Original',
      path: '',
      gender: 'male',
      thumbnailImage: ''
    },
    'PompadourHair': {
      name: 'PompadourHair',
      path: '/hairs/male/onlyPompadourHair2.glb',
      gender: 'male',
      thumbnailImage: M1
    }
  }
}

export const TOOLTIP_TEXT = {
  Personalise: 'Create your twin',
  DefaultPersonalise: 'Create {gender} avatar',
  // Logout: "Click to log out",
  // Login: "Click to Log in",
  CheckFit: "Check product fit",
  // Background: "Click to change background",
  Hairs: "Try different hair styles",
  // Camera: "Change camera mode",
  // AddToCart: "Add items to cart",
}

export const LIGHT_TYPES = {
  KEY_LIGHT: "directionalKeyLight",
  FILL_LIGHT: "directionalFillLight",
  BACK_LIGHT: "directionalBackLight",
  POINT_LIGHT_1: 'pointLight1',
  POINT_LIGHT_2: 'pointLight2',
  POINT_LIGHT_3: 'pointLight3',
  POINT_LIGHT_4: 'pointLight4',
  AMBIENT_LIGHT: "ambientLight",
}


