import React, { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from "react-redux";
import get from 'lodash/get';
import { toolAPI } from "api";
import { TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Button from 'components/Button';
import FloatingButtonBar from 'components/FloatingButtonBar';
import BackButton from 'components/BackButton';
import { ACTION_TYPES, CUSTOMISE_STEPS, HEIGHT_UNIT, MEASUREMENTS_TYPE, MEASUREMENT_SCREEN_TYPE, MenuProps, OTHER_MEASUREMENTS_UNIT, WEIGHT_UNIT, heightFtOptions, heightInchOptions } from 'utils/constants';
import { getUnitMultiplicationFactor } from 'utils';
import BottomBar from 'components/BottomBar/BottomBar';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import MixPanel from 'appAnalytics/mixPanel';

const STEP = CUSTOMISE_STEPS[MEASUREMENT_SCREEN_TYPE.MAIN_MEASUREMENT];

const MEASUREMENT_CONFIG = {
    weight: {
        name: "Weight",
        key: 'weight',
        [WEIGHT_UNIT.KG]: {
            min: 38,
            max: 200,
        },
        [WEIGHT_UNIT.LB]: {
            min: 84,
            max: 441,
        }
    },
    height: {
        name: "Height",
        key: 'height',
        [HEIGHT_UNIT.CM]: {
            min: 121,
            max: 211,
        },
        [HEIGHT_UNIT.IN]: {
            min: 48,
            max: 83,
        }
    },
    hip: {
        name: "Hips",
        key: 'hip',
        [OTHER_MEASUREMENTS_UNIT.CM]: {
            min: 60,
            max: 130,
        },
        [OTHER_MEASUREMENTS_UNIT.IN]: {
            min: 24,
            max: 51,
        }
    },
    waist: {
        name: "Waist",
        key: 'waist',
        [OTHER_MEASUREMENTS_UNIT.CM]: {
            min: 60,
            max: 130,
        },
        [OTHER_MEASUREMENTS_UNIT.IN]: {
            min: 24,
            max: 51,
        }
    },
    chest: {
        name: "Chest",
        key: 'chest',
        [OTHER_MEASUREMENTS_UNIT.CM]: {
            min: 60,
            max: 130,
        },
        [OTHER_MEASUREMENTS_UNIT.IN]: {
            min: 24,
            max: 51,
        }
    }
}
const STEP_LABELS = {
    [STEP.HEIGHT_WEIGHT]: {
        heading: 'Body Details Please!',
        text: "We'll Use It To Tune Your Avatar",
        icon: '',
    },
    [STEP.OTHER_MEASUREMENTS]: {
        heading: 'We Predicted Your Measurements',
        text: "Correct Us If We're Wrong!",
        icon: '',
    }
}

const mapStateToProps = state => {
    const imageUrl = get(state, "avatarReducer.imageUrl", "");
    const selfie = get(state, "avatarReducer.selfie", "");
    const isDefaultAvatar = get(state, "avatarReducer.isDefaultAvatar", false);
    const defaultUserProfile = get(state, 'avatarReducer.defaultUserProfile');
    const uploadLoader = get(state, 'avatarReducer.uploadLoader', false);
    const product = get(state, "avatarReducer.product", {});
    const loadedScene = get(state, "avatarReducer.loadedScene");
    return {
        imageUrl,
        selfie,
        userProfile: get(state, "avatarReducer.userProfile", {}),
        isDefaultAvatar,
        defaultUserProfile,
        uploadLoader,
        product,
        loadedScene
    };
};

const isRatioBasedMeasurementRoute = (params)=>{
    return params.gender.includes("male") && window.location.href.includes("rarerabbit");
}
const getPredictedMeasurementsByRatio = (params) => {
    console.log("I am here");
    const baseheight = 142
    const baseweight = 50
    const basechest = 98.3
    const basewaist = 85.5
    const basehip = 91.7
    const chestHeightConstant = 1.9
    const chestWeightConstant = 1.3
    const waistHeightConstant = 2.7
    const waistWeightConstant = 1.7
    const hipHeightConstant = 0.4
    const hipWeightConstant = 0.9
    const heightDiff = params.height - baseheight;
    const weightDiff = params.weight - baseweight;
    const chest = basechest - chestHeightConstant*(heightDiff)/5.0 + chestWeightConstant*(weightDiff)/2.0
    const waist = basewaist - waistHeightConstant*(heightDiff)/5.0 + waistWeightConstant*(weightDiff)/2.0
    const hip = basehip - hipHeightConstant*(heightDiff)/5.0 + hipWeightConstant*(weightDiff)/2.0
    const response = {
        data:{
            chest:chest,
            waist:waist,
            hip:hip,
    }};
    return response;
}


function MainMeasurement(props) {
    const rootStyles = getComputedStyle(document.body);
    const selectBG = rootStyles.getPropertyValue('--selectBG').trim();
    const [step, setStep] = useState(props.measurementType === MEASUREMENTS_TYPE.SHAPE_SELECTION ? STEP.OTHER_MEASUREMENTS : STEP.HEIGHT_WEIGHT);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    // const { height: { value: heightVal } } = defaultMeasurements;
    // const defaultHeightFt = Math.floor(heightInInches / 12);
    // const defaultHeightInch = Math.round(heightInInches % 12);
    const { imageUrl, uploadLoader, userProfile, selfie, isDefaultAvatar, defaultUserProfile, product, loadedScene } = useSelector(mapStateToProps);
    const { setActiveStepIndex, customizeLoader, actionType = ACTION_TYPES.NONE } = props;
    const { gender, name = "", height, weight, userAvatar = {} } = isDefaultAvatar ? defaultUserProfile : userProfile;
    const basicInfo = {
        name: name,
        gender: (product.gender !== 'unisex' ? product.gender : gender) || "male",
    };
    const { chest, hip, waist } = userAvatar || {};
    const defaultMeasurements = {
        height: {
            ...MEASUREMENT_CONFIG.height,
            unit: HEIGHT_UNIT.CM,
            value: height || 150,
            error: '',
        },
        chest: {
            ...MEASUREMENT_CONFIG.chest,
            unit: OTHER_MEASUREMENTS_UNIT.CM,
            value: chest,
            error: '',
        },
        waist: {
            ...MEASUREMENT_CONFIG.waist,
            unit: OTHER_MEASUREMENTS_UNIT.CM,
            value: waist,
            error: '',
        },
        hip: {
            ...MEASUREMENT_CONFIG.hip,
            unit: OTHER_MEASUREMENTS_UNIT.CM,
            value: hip,
            error: '',
        },
        highHip: {
            ...MEASUREMENT_CONFIG.waist,
            unit: OTHER_MEASUREMENTS_UNIT.CM,
            value: waist,
            error: '',
        },
        weight: {
            ...MEASUREMENT_CONFIG.weight,
            unit: WEIGHT_UNIT.KG,
            value: weight || 38,
            error: '',
        },
    };
    const { height: { value: heightVal } } = defaultMeasurements;
    const heightInInches = heightVal * 0.393701;
    const defaultHeightFt = Math.floor(heightInInches / 12);
    const defaultHeightInch = Math.round(heightInInches % 12);
    const [heightFt, setHeightFt] = useState(defaultHeightFt);
    const [heightInch, setHeightInch] = useState(defaultHeightInch);
    const [measurements, setMeasurements] = useState(defaultMeasurements);

    const onHeightInFtChangeHandler = (value, key, measurementObj) => {
        const { unit, name } = measurementObj;
        const { min, max } = measurementObj[unit];
        let error = '';
        let updatedMeasurements = { ...measurements };
        updatedMeasurements.unit = HEIGHT_UNIT.IN;
        let newHeight = value;
        if (key === 'feet') {
            newHeight = value * 12 + heightInch;
            setHeightFt(value);
        }
        if (key === 'inches') {
            newHeight = heightFt * 12 + value;
            setHeightInch(value);
        }
        if (newHeight > max || newHeight < min) {
            error = `${name} must be between ${min}$in to ${max}in`;
        }
        if (newHeight > max || newHeight < min) {
            error = `${name} must be between ${min}in to ${max}in`;
        }
        onTextFieldChangeHandler(newHeight, 'height', error);
    }

    const getPredictedMeasurements = async () => {
        try {
            let { height: { value: heightVal, unit: heightUnit }, weight: { value: weightVal, unit: weightUnit } } = measurements;
            if (heightUnit === HEIGHT_UNIT.IN) {
                const heightMf = getUnitMultiplicationFactor(heightUnit);
                heightVal *= heightMf;
            }
            if (weightUnit === WEIGHT_UNIT.LB) {
                const weightMf = getUnitMultiplicationFactor(weightUnit);
                weightVal *= weightMf;
            }
            let { height, weight } = userProfile;
            const params = {
                height: Math.round(heightVal),
                weight: Math.round(weightVal),
                gender: basicInfo.gender,
            }
            setLoader(true);
            const response = isRatioBasedMeasurementRoute(params) ? getPredictedMeasurementsByRatio(params): await toolAPI.getPredictedMeasurements(params);
            if (response?.data) {
                let predictedMeasurement = {
                    chest: Math.round(response.data.chest),
                    waist: Math.round(response.data.waist),
                    hip: Math.round(response.data.hip),
                };
                if (!chest || !waist || !hip || height !== heightVal || weight !== weightVal || isDefaultAvatar) {
                    let updatedMeasurements = {
                        ...measurements,
                        chest: {
                            ...measurements.chest,
                            value: predictedMeasurement.chest,
                            unit: OTHER_MEASUREMENTS_UNIT.CM,
                        },
                        waist: {
                            ...measurements.waist,
                            value: predictedMeasurement.waist,
                            unit: OTHER_MEASUREMENTS_UNIT.CM,
                        },
                        hip: {
                            ...measurements.hip,
                            value: predictedMeasurement.hip,
                            unit: OTHER_MEASUREMENTS_UNIT.CM,
                        },
                    }
                    MixPanel.buttonClicked(EVENT_CATEGORIES.HEIGHT_WEIGHT, EVENT_ACTIONS.PREDICTED_MEASUREMENTS, { predictedMeasurement, actionType });
                    setMeasurements(updatedMeasurements);
                }
                props.setPredictedMeasurement(predictedMeasurement);
            }
            MixPanel.buttonClicked(EVENT_CATEGORIES.HEIGHT_WEIGHT, EVENT_ACTIONS.MEASUREMENTS_NEXT, { measurements: {...params, measurementType: EVENT_ACTIONS.CUSTOMER_MEASUREMENTS }, actionType });
            setStep(STEP.OTHER_MEASUREMENTS);
            setLoader(false);
        } catch (e) {
            console.log(e);
            setLoader(false);
        }
    }

    const nextHandler = () => {
        //add validation to move to next step
        if (!customizeLoader) {
            if (step === STEP.HEIGHT_WEIGHT) {
                getPredictedMeasurements();
                setActiveStepIndex(2);
                // props.setMeasurementType(MEASUREMENTS_TYPE.DEFAULT);
            } else {
                //Add validation to go to next step
                let measurementPayload = {};

                Object.keys(measurements).forEach(key => {
                    const { value, unit } = measurements[key];
                    const multiplicationFactor = (unit === OTHER_MEASUREMENTS_UNIT.IN || unit === HEIGHT_UNIT.IN || unit === WEIGHT_UNIT.LB) ? getUnitMultiplicationFactor(unit) : 1;
                    measurementPayload[key] = Math.round(value * multiplicationFactor);
                });
                const payload = { ...measurementPayload };
                props.setMeasurementPayload(payload);
                MixPanel.buttonClicked(EVENT_CATEGORIES.OTHER_MEASUREMENTS, EVENT_ACTIONS.CUSTOMER_MEASUREMENTS, { measurements: { ...measurementPayload, measurementType: EVENT_ACTIONS.CUSTOMER_MEASUREMENTS }, actionType });
                props.nextHandler(payload, MEASUREMENTS_TYPE.DEFAULT);
                setActiveStepIndex(2);
            }
        }
    }

    const onUnitChange = (unit, key) => {
        if (!customizeLoader) {
            let updatedMeasurements = { ...measurements };
            const prevUnit = measurements[key].unit;
            updatedMeasurements[key].unit = unit;
            const multiplicationFactor = getUnitMultiplicationFactor(prevUnit);
            if (unit === HEIGHT_UNIT.IN) {
                const { value } = updatedMeasurements[key];
                const heightInInches = value * multiplicationFactor;
                const heightFt = Math.floor(heightInInches / 12);
                const heightInch = Math.round(heightInInches % 12);
                setHeightFt(heightFt);
                setHeightInch(heightInch);
            }
            let updatedValue = Math.round(multiplicationFactor * updatedMeasurements[key].value);
            onChangeHandler(updatedValue, key, updatedMeasurements[key]);
        }
    }

    const skipHandler = () => {
        if (!customizeLoader) {
            const { height, weight } = measurements;
            const heightUnit = height.unit;
            const weightUnit = weight.unit;
            const heightMF = heightUnit === HEIGHT_UNIT.IN ? getUnitMultiplicationFactor(heightUnit) : 1;
            const weightMF = weightUnit === WEIGHT_UNIT.LB ? getUnitMultiplicationFactor(weightUnit) : 1;
            let measurementPayload = {
                height: Math.round(height.value * heightMF),
                weight: Math.round(weight.value * weightMF),
            }
            props.setMeasurementPayload(measurementPayload)
            if (step === STEP.HEIGHT_WEIGHT) {
                props.skipHandler(MEASUREMENTS_TYPE.BRAND);
                MixPanel.buttonClicked(EVENT_CATEGORIES.HEIGHT_WEIGHT, EVENT_ACTIONS.SKIP, { actionType });
            } else {
                props.skipHandler(MEASUREMENTS_TYPE.SHAPE_SELECTION);
                MixPanel.buttonClicked(EVENT_CATEGORIES.OTHER_MEASUREMENTS, EVENT_ACTIONS.SKIP, { actionType });
                setActiveStepIndex(2);
            }
        }
    }

    const onTextFieldChangeHandler = (val, key, error) => {
        if (!customizeLoader) {
            // setErrorMessage('');
            let updatedMeasurements = { ...measurements };
            updatedMeasurements[key].value = Number(val);
            updatedMeasurements[key].error = error;
            setMeasurements(updatedMeasurements);
        }
    };

    const checkFormValidity = useCallback(() => {
        let isInputValid = false;
        if (step === STEP.HEIGHT_WEIGHT) {
            const { height, weight } = measurements;
            isInputValid = !(height?.error || weight?.error);
        }
        if (step === STEP.OTHER_MEASUREMENTS) {
            const { chest, waist, hip } = measurements;
            isInputValid = !(chest?.error || waist?.error || hip?.error)
        }
        setNextDisabled(!isInputValid);
    }, [measurements, step]);

    const onChangeHandler = (val, key, measurementObj) => {
        const { unit, name } = measurementObj;
        const { min, max } = measurementObj[unit];
        let error = '';
        if (val > max || val < min) {
            error = `${name} must be between ${min}${unit} to ${max}${unit}`;
        }
        onTextFieldChangeHandler(val, key, error);
    }

    const backHandler = () => {
        if (step === STEP.HEIGHT_WEIGHT) {
            MixPanel.buttonClicked(EVENT_CATEGORIES.HEIGHT_WEIGHT, EVENT_ACTIONS.BACK_BUTTON, { actionType });
            props.backHandler();
        } else {
            setStep(STEP.HEIGHT_WEIGHT);
            const updatedMeasurements = { ...measurements };
            updatedMeasurements.chest = defaultMeasurements.chest;
            updatedMeasurements.waist = defaultMeasurements.waist;
            updatedMeasurements.hip = defaultMeasurements.hip;
            MixPanel.buttonClicked(EVENT_CATEGORIES.OTHER_MEASUREMENTS, EVENT_ACTIONS.BACK_BUTTON, { actionType });
            setMeasurements(updatedMeasurements);
        }
    }

    useEffect(() => {
        if (measurements) {
            checkFormValidity(measurements);
        }
    }, [checkFormValidity, measurements])

    const renderMeasurementFields = () => {
        if (step === STEP.HEIGHT_WEIGHT) {
            const { height, weight } = measurements;
            const heightUnit = height.unit;
            const weightUnit = weight.unit;
            return <div style={{ padding: '1em' }}>
                <div className='Measurements__FormInput'>
                    <div className='Measurements__FormLabel'>{height.name}</div>
                    <div className='Measurements__Height'>
                        {heightUnit === HEIGHT_UNIT.IN ? <div style={{ display: 'flex', flex: '1' }}>
                            <FormControl fullWidth>
                                <InputLabel id="label-ft">ft</InputLabel>
                                <Select
                                    labelId="label-ft"
                                    sx={{
                                        borderRadius: '1em',
                                    }}
                                    MenuProps={MenuProps}
                                    value={heightFt}
                                    className="Measurements__Select"
                                    size={'small'}
                                    onChange={(e) => onHeightInFtChangeHandler(e.target.value, 'feet', height)}

                                >
                                    {heightFtOptions.map(option => {
                                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="label-in">in</InputLabel>
                                <Select
                                    labelId='label-in'
                                    MenuProps={MenuProps}
                                    className="Measurements__Select"
                                    value={heightInch}
                                    size={'small'}
                                    onChange={(e) => onHeightInFtChangeHandler(e.target.value, 'inches', height)}
                                    label="in"
                                >
                                    {heightInchOptions.map(option => {
                                        return <MenuItem key={option} value={option}>{option}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div> :
                            <div style={{ display: 'flex', flex: '1' }}>
                                <TextField
                                    label={height.unit}
                                    size="small"
                                    className="Measurements__TextField"
                                    type="number"
                                    sx={{
                                        flex: 1,
                                        '& label.Mui-focused': {
                                            color: '#000',
                                        },
                                        '& .MuiInputBase-root': {
                                            marginRight: '1em',
                                            borderRadius: '1em',
                                        },
                                        '.MuiFormHelperText-root.Mui-error': {
                                            position: 'absolute',
                                            bottom: '-20px'
                                        }
                                    }}
                                    error={!!height.error}
                                    helperText={height.error ? height.error : ''}
                                    value={height.value || ''}
                                    name={height.name}
                                    onChange={(e) => onChangeHandler(e.target.value, 'height', height)}
                                />
                            </div>}
                        <Select
                            MenuProps={MenuProps}
                            className="Measurements__UnitSelect"
                            value={heightUnit}
                            size={'small'}
                            onChange={(e) => onUnitChange(e.target.value, 'height')}
                            sx={{
                                background: selectBG,
                                width: 80,
                            }}
                        >
                            {Object.values(HEIGHT_UNIT).map(value => {
                                return <MenuItem key={value} value={value}>{value}</MenuItem>
                            })}
                        </Select>
                    </div>
                </div>
                <div className='Measurements__FormInput'>
                    <div className='Measurements__FormLabel'>{weight.name}</div>
                    <div style={{
                        display: 'flex',
                        flex: '1'
                    }}>
                        <TextField
                            label={weight.unit}
                            size="small"
                            className='Measurements__TextField'
                            sx={{
                                flex: 1,
                                '& .MuiInputBase-root': {
                                    marginRight: '1em',
                                    borderRadius: '1em',
                                },
                                '.MuiFormHelperText-root.Mui-error': {
                                    position: 'absolute',
                                    bottom: '-20px'
                                }
                            }}
                            error={!!weight.error}
                            helperText={weight?.error || ''}
                            type="number"
                            value={weight?.value || ''}
                            onChange={(e) => onChangeHandler(e.target.value, 'weight', weight)}
                        />
                        <Select
                            MenuProps={MenuProps}
                            className="Measurements__UnitSelect"
                            value={weightUnit}
                            size={'small'}
                            onChange={(e) => onUnitChange(e.target.value, 'weight')}
                            sx={{
                                background: selectBG,
                                width: 80,
                            }}
                        >
                            {Object.values(WEIGHT_UNIT).map((value) => {
                                return <MenuItem key={value} value={value}>{value}</MenuItem>
                            })}
                        </Select>
                    </div>
                </div>
            </div>
        } else {
            const { chest, waist, hip } = measurements;
            return <div style={{ padding: '1em' }}>
                {[chest, waist, hip].map(measurement => {
                    const { unit, value, key, name } = measurement;
                    return <div className='Measurements__FormInput' key={key}>
                        <div style={{
                            display: 'flex',
                            flex: '1'
                        }}>
                            <TextField
                                label={name}
                                size="small"
                                type="number"
                                className='Measurements__TextField'
                                sx={{
                                    flex: 1,
                                    '& .MuiInputBase-root': {
                                        marginRight: '1em',
                                        borderRadius: '1em',
                                    },
                                    '.MuiFormHelperText-root.Mui-error': {
                                        position: 'absolute',
                                        bottom: '-20px'
                                    }
                                }}
                                error={!!measurement.error}
                                helperText={measurement?.error || ''}
                                value={value || ''}
                                onChange={(e) => onChangeHandler(e.target.value, key, measurement)}
                            />
                            <Select
                                MenuProps={MenuProps}
                                className="Measurements__UnitSelect"
                                size={'small'}
                                value={unit}
                                onChange={(e) => onUnitChange(e.target.value, key)}
                                sx={{
                                    background: selectBG,
                                    width: 80,
                                }}
                            >
                                {Object.values(OTHER_MEASUREMENTS_UNIT).map((value) => {
                                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>
                })}
            </div>
        }
    }

    const { heading, text, icon } = STEP_LABELS[step];
    return (<>
        <div style={{ padding: '0.5em 1em', zIndex: 1 }}>
            <BackButton onClick={() => backHandler()} />
        </div>
        <div className='Dopplr_Customize__Title'>
            <div className='Dopplr_Customize__Heading'>{heading}</div>
            <div className='Dopplr_Customize__Text'>{text}</div>
            {icon && <img src={icon} alt="Star Icon" weight={64} height={64} />}
        </div>
        <BottomBar keyName={step} className='Measurements'
            style={{ paddingBottom: '3em' }}>
            {renderMeasurementFields()}
            <FloatingButtonBar style={{ background: 'none' }}>
                {step !== STEP.HEIGHT_WEIGHT && <Button type='white-black' onClick={skipHandler}>
                    Don't Know?
                </Button>}
                <Button type='black-white' onClick={nextHandler} disabled={nextDisabled} isLoading={loader || customizeLoader}>
                    Next
                </Button>
            </FloatingButtonBar>
        </BottomBar>
    </>
    )
}

export default MainMeasurement
