import HalfCard from 'components/HalfCard';
import React from 'react';
import "./Summary.scss";

const Summary = ({ showSummary, setShowSummary, normalFit, bustTypeMessage, placementMessage }) => {
    return (
        <HalfCard
            visible={showSummary}
            key={'summary'}
            onClose={() => setShowSummary(false)}
            showCloseButton={true}
            showHeader={false}
            // title={"Products"}
            whiteBackground={true}
            closeOnBackgroundClick={true}
        >
            <div className='Dopplr_Summary'>
                <div className='Dopplr_Summary__SummaryText'>Your Size is <span className='Dopplr_Summary__SummaryValue'>{normalFit}</span></div>
                <div className='Dopplr_Summary__SummaryValue'>{bustTypeMessage}</div>
                <div className='Dopplr_Summary___SummaryText'>You have <span className='Dopplr_Summary__SummaryValue'>{placementMessage}</span> placement of breasts</div>
                {/* <div className='Dopplr_Summary__SummaryText'>Your bust size is <span className='Dopplr_Summary__SummaryValue'>Settled</span></div> */}
            </div>
        </HalfCard>
    )
}

export default Summary
