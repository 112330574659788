import React from 'react';


const StepProgress = ({ activeStepIndex, stepCount }) => {
    const list = new Array(stepCount).fill().map((_, index) => index);
    return (<div className='Dopplr_StepProgress'>
        {list.map((stepIndex) => {
            const isStepActive = stepIndex <= activeStepIndex;
            return <div className={`Dopplr_StepProgress__Step ${isStepActive ? 'active' : ''}`} key={stepIndex} />
        })}
    </div>
    )
}

export default StepProgress
