import get from 'lodash/get';
import { Vector3, MeshBuilder, VertexBuffer, PBRMetallicRoughnessMaterial, Mesh } from "@babylonjs/core/Legacy/legacy";
import { clamp, getKeyByValue, hardLimitMax, hardLimitMin } from "utils";
import { ANNOTATIONS_TEXT_HEIGHTS, BODY_VERTICES_LIMIT, GARMENT_TYPE, HEATMAP_COLOR_CODES } from './constants';
import { isBottomGarment, isTopGarment, renderWireframeOver } from 'utils/meshUtils';
import { isEmpty } from 'lodash';
import bodyPartsVertices from 'containers/babylon/avatarLogic/BodyPartsVertices.js';
import { getVertexPositionFromID } from './babylonUtils';
const getFitInsight = (bodyPart, value) => {
    if (
        (value >= bodyPart && value - 3 <= bodyPart) ||
        (value <= bodyPart && value + 3 >= bodyPart)
    ) {
        return "normal"
    } else if (value > bodyPart) {
        if (value - bodyPart < 10) {
            return "slightlyloose"
        }
        return "loose";
    } else if (bodyPart - value < 10) {
        return "slightlytight"
    }
    return "tight";
};

export const getGarmentValue = (fitInsights, userData, heatmapSize) => {
    let { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const key = heatmapSize || 'value';
    let chestValueGarment = get(fitInsights, `chest.${key}`);
    let waistValueGarment = get(fitInsights, `waist.${key}`);
    let hipValueGarment = get(fitInsights, `hip.${key}`);
    let highHipValueGarment = get(fitInsights, `highHip.${key}`);
    const result = {
        "chestMsg": getFitInsight(chestValueUser, chestValueGarment),
        "waistMsg": getFitInsight(waistValueUser, waistValueGarment),
        "hipMsg": getFitInsight(hipValueUser, hipValueGarment),
        "highHipMsg": getFitInsight(highHipValueUser, highHipValueGarment),
        "chestValueGarment": chestValueGarment,
        "waistValueGarment": waistValueGarment,
        "hipValueGarment": hipValueGarment,
        "highHipValueGarment": highHipValueGarment
    };
    return result
}
export const getGarmentValues = (fitInsights, userData, heatmapSize) => {
    let { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const key = heatmapSize || 'value';
    let chestValueGarment = get(fitInsights, `chest.${key}`);
    let waistValueGarment = get(fitInsights, `waist.${key}`);
    let hipValueGarment = get(fitInsights, `hip.${key}`);
    let highHipValueGarment = get(fitInsights, `highHip.${key}`);
    return {
        chestMsg: getFitInsight(chestValueUser, chestValueGarment),
        waistMsg: getFitInsight(waistValueUser, waistValueGarment),
        hipMsg: getFitInsight(hipValueUser, hipValueGarment),
        highHipMsg: getFitInsight(highHipValueUser, highHipValueGarment),
        chestValueGarment,
        waistValueGarment,
        hipValueGarment,
        highHipValueGarment
    }
}

export const mixColors = (colorA, colorB, strength) => {
    const r = (colorA[0] * strength) + (colorB[0] * (1 - strength))
    const g = (colorA[1] * strength) + (colorB[1] * (1 - strength))
    const b = (colorA[2] * strength) + (colorB[2] * (1 - strength))
    const a = (colorA[3] * strength) + (colorB[3] * (1 - strength))
    return [r, g, b, a];
};

export const bodyAreasLimits = {
    "Chest": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.2, maxXFactor: 1.2, minYFactor: 1, maxYFactor: 1.1, minZFactor: 1, maxZFactor: 1.2 },
    "Armpits": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1, maxXFactor: 1, minYFactor: 1, maxYFactor: 1, minZFactor: 0.9, maxZFactor: 0.9 },
    "Shoulders": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1, maxXFactor: 1, minYFactor: 1, maxYFactor: 1, minZFactor: 1, maxZFactor: 1 },
    "Waist": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.2 },
    "Torso": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.2, maxXFactor: 1.2, minYFactor: 1, maxYFactor: 1, minZFactor: 0.9, maxZFactor: 1.2 },
    "LoveHandles": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.2 },
    "Bums": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.1 },
    "WaisTorso": { min: new Vector3(0, 0, 0), max: new Vector3(0, 0, 0), minXFactor: 1.1, maxXFactor: 1.1, minYFactor: 1, maxYFactor: 1, minZFactor: 1.1, maxZFactor: 1.1 }
}
export const isVertexWithinBodyArea = (vertex, area) => {
    return (vertex.x > bodyAreasLimits[area].min.x * bodyAreasLimits[area].minXFactor)
        && (vertex.x < bodyAreasLimits[area].max.x * bodyAreasLimits[area].maxXFactor)
        && (vertex.y > bodyAreasLimits[area].min.y * bodyAreasLimits[area].minYFactor)
        && (vertex.y < bodyAreasLimits[area].max.y * bodyAreasLimits[area].maxYFactor)
        && (vertex.z > bodyAreasLimits[area].min.z * bodyAreasLimits[area].minZFactor)
        && (vertex.z < bodyAreasLimits[area].max.z * bodyAreasLimits[area].maxZFactor)
}

export const blendColorsinYAxis = (minY, maxY, vertex, clampedStrength, blendHeightFactor, direction = 0) => { // direction : { -1: negative, +1: positive, 0: both}
    const lowerlimitY = minY + (maxY - minY) * blendHeightFactor;
    const upperlimitY = maxY - (maxY - minY) * blendHeightFactor;

    if ((direction !== -1) && (vertex.y > upperlimitY)) {
        const factor = (maxY - vertex.y) / ((maxY - minY) * blendHeightFactor * 2)
        clampedStrength = (1 - factor);
    }
    else if ((direction !== 1) && (vertex.y < lowerlimitY)) {
        const factor = (vertex.y - minY) / ((maxY - minY) * blendHeightFactor * 2)
        clampedStrength = (1 - factor);
    }
    return clampedStrength;
}

export const blendColorsinXAxis = (minX, maxX, vertex, clampedStrength, blendWidthFactor) => {
    const lowerlimitX = minX + (maxX - minX) * blendWidthFactor
    const upperlimitX = maxX - (maxX - minX) * blendWidthFactor

    if (vertex.x > upperlimitX) {
        const factor = (maxX - vertex.x) / ((maxX - minX) * blendWidthFactor * 2)
        clampedStrength = (1 - factor)
    }
    else if (vertex.x < lowerlimitX) {
        const factor = (vertex.x - minX) / ((maxX - minX) * blendWidthFactor * 2)
        clampedStrength = (1 - factor)
    }
    return clampedStrength
}

export const getFitIndicatorValue = (fitInsights, userData, garmentType, heatmapSize) => {
    const { chestValueGarment, waistValueGarment, hipValueGarment, highHipValueGarment } = getGarmentValues(fitInsights, userData, heatmapSize);
    const { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const chestDifference = clamp(chestValueGarment - chestValueUser, -15, 15);
    const waistDifference = clamp(waistValueGarment - waistValueUser, -15, 15);
    const hipDifference = clamp(hipValueGarment - hipValueUser, -15, 15);
    const highHipDifference = clamp(highHipValueGarment - highHipValueUser, -15, 15);
    const range = 15 * 1.414;
    let fitIndicationValue = ((3 * chestDifference) + waistDifference) / (4 * range);  //euclidean ? (chestDifference + waistDifference) / (euclidean * 2) : 0;
    fitIndicationValue = clamp(fitIndicationValue, -0.9, 0.9);
    if (garmentType === GARMENT_TYPE.BOTTOM) {
        fitIndicationValue = (3 * highHipDifference + hipDifference) / (4 * range);
        fitIndicationValue = clamp(fitIndicationValue, -0.9, 0.9);
    }
    return fitIndicationValue;
}

export const getFitIndicatorValueBH = (fitInsights, userData, garmentType, heatmapSize, plotShare) => {
    const { chestValueGarment, waistValueGarment, hipValueGarment, highHipValueGarment } = getGarmentValues(fitInsights, userData, heatmapSize);
    const { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = userData;
    const chestDifference = chestValueGarment - chestValueUser;
    // const waistDifference = clamp(waistValueGarment - waistValueUser, -15, 15);
    // const hipDifference = clamp(hipValueGarment - hipValueUser, -15, 15);
    const highHipDifference = highHipValueGarment - highHipValueUser;
    let fitIndicationValue = ((chestDifference / 2.54) * plotShare) //euclidean ? (chestDifference + waistDifference) / (euclidean * 2) : 0;
    if (garmentType === GARMENT_TYPE.BOTTOM) {
        fitIndicationValue = (highHipDifference / 2.54) * plotShare;
    }
    return fitIndicationValue;
}

export const getMixedColorForBodyArea = (firstColor, secondColor, area, vertex, strengthFactor) => {
    let minX = bodyAreasLimits[area].min.x * bodyAreasLimits[area].minXFactor;
    let maxX = bodyAreasLimits[area].max.x * bodyAreasLimits[area].maxXFactor;
    let minY = bodyAreasLimits[area].min.y; // * bodyAreasLimits[area].minYFactor
    let maxY = bodyAreasLimits[area].max.y; // * bodyAreasLimits[area].maxYFactor
    let minZ = bodyAreasLimits[area].min.z; // * bodyAreasLimits[area].minZFactor
    let maxZ = bodyAreasLimits[area].max.z * bodyAreasLimits[area].maxZFactor;
    if (area === "WaisTorso") {
        minY = (bodyAreasLimits["Waist"].min.y + bodyAreasLimits["Waist"].min.y) / 2;
        maxY = (bodyAreasLimits["Torso"].max.y + bodyAreasLimits["Waist"].max.y) / 2;
        minX = (bodyAreasLimits["Torso"].min.x + bodyAreasLimits["Waist"].min.x) / 2;
        maxX = (bodyAreasLimits["Torso"].max.x + bodyAreasLimits["Waist"].max.x) / 2;
        minZ = bodyAreasLimits["Torso"].min.z;
        maxZ = bodyAreasLimits["Waist"].max.z;
    }

    let strengthZ = Math.abs((vertex.z - (minZ + maxZ) / 2) / (maxZ - minZ));
    let strengthY = Math.abs((vertex.y - (minY + maxY) / 2) / (maxY - minY));
    let strengthX = Math.abs((vertex.x - (minX + maxX) / 2) / (maxX - minX));

    let clampedStrength = clamp((strengthX + strengthY + strengthZ) / strengthFactor, 0, 1);


    // Blending the colors on the edges to give smooth transition between regions
    const directionY = (area === "Chest") ? -1 : 0;    // direction : { -1: negative, +1: positive, 0: both}

    const clampedStrengthY = blendColorsinYAxis(minY, maxY, vertex, clampedStrength, 0.2, directionY);
    const clampedStrengthX = blendColorsinXAxis(minX, maxX, vertex, clampedStrength, 0.2);
    clampedStrength = Math.max(clampedStrengthX, clampedStrengthY);

    let mixedColor = mixColors(firstColor, secondColor, clampedStrength);

    return mixedColor;
}

export const removeTextsForBodyVisualiser = () => {
    removeText("bodyVisualiserText", "Chest");
    removeText("bodyVisualiserText", "Waist");
    removeText("bodyVisualiserText", "Hip");
    removeText("bodyVisualiserText", "HighHip");
}
export const removeText = (idText, area) => {
    let boxSml = document.getElementById(`boxSml${area}`);
    if (boxSml) {
        boxSml.dispose();
    }

    let heatmapText = document.getElementById(`${idText}${area}`);
    if (heatmapText) {
        heatmapText.parentNode.removeChild(heatmapText);
    }
};

export const showTextsForBodyVisualiser = (scene, tab, userProfile, product) => {
    removeTextsForFabric();
    removeTextsForBodyVisualiser();
    let { gender, unisex } = product;
    const { gender: userGender = 'male' } = userProfile;
    if (unisex?.[userGender]) {
        gender = userGender
    }
    if (!gender || gender === 'unisex') {
        gender = 'male';
    }
    const { chestHeight, waistHeight, hipHeight, highHipHeight } = ANNOTATIONS_TEXT_HEIGHTS[gender];
    const TextObj = {
        "chest": {
            value: chestHeight,
            area: "Chest",
            idText: "bodyVisualiserText",
            message: "Chest",
        },
        "waist": {
            value: waistHeight,
            area: "Waist",
            idText: "bodyVisualiserText",
            message: "Waist",
        },
        "hip": {
            value: hipHeight,
            area: "Hip",
            idText: "bodyVisualiserText",
            message: "Hip",
        },
        "highHip": {
            value: highHipHeight,
            area: "HighHip",
            idText: "bodyVisualiserText",
            message: "Upper Hip",
        }
    }
    if (TextObj[tab]) {
        const { value, area, idText, message } = TextObj[tab];
        addTextForArea(value, area, idText, message, scene);
    }
}


const showTextForHeatmap = (scene, gender, chestMsg, waistMsg, hipMsg, highHipMsg, overallMsg, garmentType) => {
    removeTextsForFabric();
    removeTextsForHeatmap();
    // Generic Area - Chest, Waist Hip, Overall
    const getRefinedMessage = (msg) => {
        let refinedMessage = msg;
        if (msg === "normal") {
            refinedMessage = "Comfortable";
        }
        else if (msg === "tight") {
            refinedMessage = "Tight";
        }
        else if (msg === "slightlytight") {
            refinedMessage = "Skin Fit";
        }
        else if (msg === "loose") {
            refinedMessage = "Loose";
        }
        else if (msg === "slightlyloose") {
            refinedMessage = "Slightly Loose";
        }
        else {
            refinedMessage = "Comfortable";
        }
        return refinedMessage
    }
    const chestFitMsg = chestMsg;
    // const waistFitMsg = getRefinedMessage(waistMsg);
    // const hipFitMsg = getRefinedMessage(hipMsg);
    const highHipFitMsg = highHipMsg;
    // const overallFitMsg = `Overall - ${overallMsg}`;
    const { chestHeight, highHipHeight } = ANNOTATIONS_TEXT_HEIGHTS[gender];
    // const heightForOverall = (gender === "male") ? 1.85 : 1.75;
    const heatmapTextObj = {
        "chest": {
            value: chestHeight,
            area: "Chest",
            idText: "heatMapText",
            message: chestFitMsg,
        },
        // "waist": {
        //   value: waistHeight,
        //   area: "Waist",
        //   idText: "heatMapText",
        //   message: waistFitMsg,
        // },
        // "hip": {
        //   value: hipHeight,
        //   area: "Hip",
        //   idText: "heatMapText",
        //   message: hipFitMsg,
        // },
        "highHip": {
            value: highHipHeight,
            area: "HighHip",
            idText: "heatMapText",
            message: highHipFitMsg,
        }
    }
    const garmentTypeBodyRegionMap = {
        "top": ["chest"],
        "bottom": ["highHip"],
    }
    const garmentTypeBodyRegions = garmentTypeBodyRegionMap[garmentType];
    Object.keys(heatmapTextObj).forEach(key => {
        if (garmentTypeBodyRegions.includes(key)) {
            const { value, area, idText, message } = heatmapTextObj[key]
            addTextForArea(value, area, idText, message, scene);
        }
    })
}

export const removeTextsForHeatmap = () => {
    removeText("heatMapText", "Chest");
    removeText("heatMapText", "Waist");
    removeText("heatMapText", "Hip");
    removeText("heatMapText", "HighHip");
}


export const addTextForArea = (heightFromGround, area, idText, message, loadedScene, className = 'heatmapLabelComponentV2') => {
    let boxSml = document.getElementById(`boxSml${area}`);
    if (boxSml) {
        boxSml.dispose();
    }
    boxSml = MeshBuilder.CreateBox(`boxSml${area}`, { height: 0.001, width: 0.001, depth: 0.001 }, loadedScene);
    boxSml.position = new Vector3(0, heightFromGround, 0);

    let heatmapText = document.getElementById(`heatmapText${area}`);
    if (heatmapText) {
        heatmapText.parentNode.removeChild(heatmapText);
    }
    heatmapText = document.createElement("div");

    heatmapText.id = `${idText}${area}`;
    heatmapText.className = className;
    heatmapText.style.left = (area === "Overall") ? "0px" : "12%";
    // heatmapText.style.fontSize = "0.7em";
    heatmapText.style.padding = "0.8em";
    const messageList = message.split(', ');
    messageList.forEach(message => {
        let heatmapTextLabel = document.createElement("div");
        heatmapTextLabel.className = 'textContainer';
        heatmapTextLabel.style.fontSize = "0.9em";
        // heatmapTextLabel.style.padding = "0.3em";
        heatmapTextLabel.textContent = message;
        heatmapText.appendChild(heatmapTextLabel);
    })
    // heatmapText.innerHTML = message;


    const parent = document.getElementById('dopplr-container');
    parent.appendChild(heatmapText);

    var vertexScreenCoords;
    loadedScene?.onAfterRenderObservable.add(() => {
        vertexScreenCoords = Vector3.Project(
            Vector3.Zero(), boxSml.getWorldMatrix(),
            loadedScene?.getTransformMatrix(),
            loadedScene?.activeCamera?.viewport.toGlobal(loadedScene.getEngine().getRenderWidth(true), loadedScene.getEngine().getRenderHeight(true))
        );

        var canvasZone = loadedScene.getEngine().getRenderingCanvas(),
            ofstX = canvasZone.offsetLeft,
            ofstY = canvasZone.offsetTop;

        heatmapText.style.transform = "translate3d(calc(" + (vertexScreenCoords.x + ofstX) + "px - 50%), calc(" + (vertexScreenCoords.y + ofstY) + "px - 50%), 0px)";
    });
}

export const showOriginalGarment = (scene, gender, product, meshWithMaterialObj) => {
    if (scene) {
        let { unisex, product_path, product_counter_part, avatar } = product;
        if (unisex?.[gender]) {
            product_path = unisex?.[gender].product_path;
            product_counter_part = unisex?.[gender].product_counter_part;
            avatar = unisex?.[gender].avatar;
        }
        const topMeshName = `clothAsset_${product_path}_${avatar}`;
        const bottomMeshName = `clothAsset_${product_counter_part}_${avatar}`;
        const topMeshWithMaterial = (topMeshName && meshWithMaterialObj[topMeshName]) || [];
        const bottomMeshWithMaterial = (bottomMeshName && meshWithMaterialObj[bottomMeshName]) || [];
        [...topMeshWithMaterial, ...bottomMeshWithMaterial].forEach((mesh) => {
            mesh.setEnabled(true)
        });
        const materialsToDispose = scene.materials.filter(material => {
            return material && material.name && material.name.includes("heatMapMat");
        });
        const meshesToRemove = scene.meshes.filter(mesh => {
            return mesh.name.includes('mergedtop') || mesh.name.includes('mergedbottom');
        });
        [...meshesToRemove, ...materialsToDispose].forEach(item => item.dispose());
    }
}

export const showTextsForFabric = (scene, userProfile, product) => {
    removeTextsForFabric();
    let gender = product.gender;
    const { gender: userGender } = userProfile;
    if (gender === "unisex") {
        gender = userGender;
    }
    const { fabricTop, fabricBottom } = ANNOTATIONS_TEXT_HEIGHTS[gender];
    const { fabric = {} } = product;
    const topText = fabric.top || '';
    const bottomText = fabric.bottom || '';
    const TextObj = {
        "fabricTop": {
            value: fabricTop,
            area: "FabricTop",
            idText: "fabricText",
            message: topText || '',
        },
        "fabricBottom": {
            value: fabricBottom,
            area: "FabricBottom",
            idText: "fabricText",
            message: bottomText || '',
        },
    }
    Object.keys(TextObj).forEach(key => {
        if (TextObj[key]?.message) {
            const { value, area, idText, message } = TextObj[key]
            addTextForArea(value, area, idText, message, scene, 'fabricLabelComponentV2');
        }
    })
}

export const showHotspots = (scene, product) => {
    // const TextObj = {
    //     "fabricTop": {
    //         value: fabricTop,
    //         area: "FabricTop",
    //         idText: "fabricText",
    //         message: topText || '',
    //     },
    //     "fabricBottom": {
    //         value: fabricBottom,
    //         area: "FabricBottom",
    //         idText: "fabricText",
    //         message: bottomText || '',
    //     },
    // }
}

export const removeTextsForFabric = () => {
    removeText("fabricText", "FabricTop");
    removeText("fabricText", "FabricBottom");
}

const getPlotIndex = (fitIndicationValue, high, low) => {
    const point = ((fitIndicationValue - low) / (high - low)) * 100;
    if (point <= 33.33333333333333333333) {
        return 0;
    } else if (point <= 66.66666666666666666) {
        return 1;
    } else {
        return 2;
    }
}


const updateColorsBH = (scene, positionsArray, meshForHeatMap, garmentType, userProfileData, heatmapSize, selectedProductPart, avatarAssets) => {

    let colors = []
    const { fitInsights = {}, fit = {} } = avatarAssets;
    const { gender } = userProfileData;
    const plotRange = fit[selectedProductPart]?.plotRange;
    const plotShare = fit[selectedProductPart]?.plotShare;
    let { chestMsg, waistMsg, hipMsg, highHipMsg, chestValueGarment, waistValueGarment, hipValueGarment, highHipValueGarment } = getGarmentValues(fitInsights, get(userProfileData, 'userAvatar', {}), heatmapSize);
    const { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = get(userProfileData, 'userAvatar', {});
    const fitColorMap = {
        "slim": [[240 / 255, 185 / 255, 1 / 255, 1], [234 / 255, 254 / 255, 1 / 255, 1], [164 / 255, 255 / 255, 1 / 255, 1]],
        "regular": [[164 / 255, 255 / 255, 1 / 255, 1], [8 / 255, 255 / 255, 1 / 255, 1], [85 / 255, 176 / 255, 175 / 255, 1]],
        "loose": [[44 / 255, 175 / 255, 115 / 255, 1], [97 / 255, 154 / 255, 207 / 255, 1], [11 / 255, 67 / 255, 245 / 255, 1]]
    }
    const fitIndicationValue = getFitIndicatorValueBH(fitInsights, get(userProfileData, 'userAvatar', {}), garmentType, heatmapSize, plotShare);
    let defaultColor = [1, 1, 1, 1];
    const slimRange = plotRange["slim"];
    const regularRange = slimRange + plotRange["regular"];
    const looseRange = regularRange + plotRange["loose"];
    if (fitIndicationValue <= slimRange) {
        defaultColor = fitColorMap["slim"][getPlotIndex(fitIndicationValue, slimRange, 0)];
        chestMsg = "Skinny Fit";
        highHipMsg = "Skinny Fit";
    } else if (fitIndicationValue <= regularRange) {
        defaultColor = fitColorMap["regular"][getPlotIndex(fitIndicationValue, regularRange, slimRange)];
        chestMsg = "Regular Fit";
        highHipMsg = "Regular Fit";
    } else {
        defaultColor = fitColorMap["loose"][getPlotIndex(fitIndicationValue, looseRange, regularRange)];
        chestMsg = "Loose Fit";
        highHipMsg = "Loose Fit";
    }
    for (var i = 0; i < positionsArray.length; i += 3) {
        let mixedColor = defaultColor;
        colors.push(...mixedColor);
    }
    meshForHeatMap.setVerticesData(VertexBuffer.ColorKind, colors)

    showTextForHeatmap(scene, gender, chestMsg, waistMsg, hipMsg, highHipMsg, "fitScoreText", garmentType)
}


const updateColors = (scene, positionsArray, meshForHeatMap, garmentType, userProfileData, heatmapSize, avatarAssets, props) => {

    let colors = []
    const { fitInsights = {} } = avatarAssets;
    const { gender } = userProfileData;
    const { setNormalisedfitIndicationValueTop, setNormalisedfitIndicationValueBottom, setFitScoreText } = props;
    const { chestMsg, waistMsg, hipMsg, highHipMsg, chestValueGarment, waistValueGarment, hipValueGarment, highHipValueGarment } = getGarmentValues(fitInsights, get(userProfileData, 'userAvatar', {}), heatmapSize);

    const { chest: chestValueUser, waist: waistValueUser, hip: hipValueUser, highHip: highHipValueUser } = get(userProfileData, 'userAvatar', {});
    const chestDifference = clamp(chestValueGarment - chestValueUser, -15, 15);
    const waistDifference = clamp(waistValueGarment - waistValueUser, -15, 15);
    const hipDifference = clamp(hipValueGarment - hipValueUser, -15, 15);
    const highHipDifference = clamp(highHipValueGarment - highHipValueUser, -15, 15);
    const { TIGHT, NORMAL, LOOSE, TOO_TIGHT, TOO_LOOSE } = HEATMAP_COLOR_CODES;
    let chestColor = chestMsg && HEATMAP_COLOR_CODES[chestMsg] ? HEATMAP_COLOR_CODES[chestMsg] : NORMAL;
    let waistColor = waistMsg && HEATMAP_COLOR_CODES[waistMsg] ? HEATMAP_COLOR_CODES[waistMsg] : NORMAL;
    let hipColor = hipMsg && HEATMAP_COLOR_CODES[hipMsg] ? HEATMAP_COLOR_CODES[hipMsg] : NORMAL;
    let highHipColor = highHipMsg && HEATMAP_COLOR_CODES[highHipMsg] ? HEATMAP_COLOR_CODES[highHipMsg] : NORMAL;
    let euclidean = Math.sqrt((chestDifference ** 2) + (waistDifference ** 2));

    // Fit Score Csalculation
    const range = 15 * 1.414;
    let euclideanFit = euclidean / range;
    let fitScore = (1 - euclideanFit) * 100;


    // let fitIndicationValue = ((3 * chestDifference) + waistDifference) / (4 * range);  //euclidean ? (chestDifference + waistDifference) / (euclidean * 2) : 0;
    // fitIndicationValue = clamp(fitIndicationValue, -0.9, 0.9);   
    const fitIndicationValue = getFitIndicatorValue(fitInsights, get(userProfileData, 'userAvatar', {}), garmentType, heatmapSize);
    let defaultColor = (chestDifference + waistDifference < 0)
        ? mixColors(TIGHT, NORMAL, Math.abs(fitIndicationValue))
        : mixColors(LOOSE, NORMAL, Math.abs(fitIndicationValue));

    chestColor = (chestDifference < 0)
        ? mixColors(TOO_TIGHT, chestColor, Math.abs(chestDifference) / range)
        : mixColors(TOO_LOOSE, chestColor, Math.abs(chestDifference) / range);
    waistColor = (waistDifference < 0)
        ? mixColors(TOO_TIGHT, waistColor, Math.abs(waistDifference) / range)
        : mixColors(TOO_LOOSE, waistColor, Math.abs(waistDifference) / range);


    if (isBottomGarment(garmentType)) {
        euclidean = Math.sqrt((highHipDifference ** 2) + (hipDifference ** 2));
        // fitIndicationValue = (3 * highHipDifference + hipDifference) / (4 * range);
        // fitIndicationValue = clamp(fitIndicationValue, -0.9, 0.9);
        defaultColor = (highHipDifference + hipDifference < 0)
            ? mixColors(TIGHT, NORMAL, Math.abs(fitIndicationValue))
            : mixColors(LOOSE, NORMAL, Math.abs(fitIndicationValue));
        hipColor = (hipDifference < 0)
            ? mixColors(TOO_TIGHT, hipColor, Math.abs(hipDifference) / range)
            : mixColors(TOO_LOOSE, hipColor, Math.abs(hipDifference) / range);
        highHipColor = (hipDifference < 0)
            ? mixColors(TOO_TIGHT, hipColor, Math.abs(highHipDifference) / range)
            : mixColors(TOO_LOOSE, hipColor, Math.abs(highHipDifference) / range);
    }
    // Fit Indication Parameter is [tight -> -1 to +1 <- loose], normalising to [tight -> 0 to 1 <- loose]
    const normalisedFitIndicationValue = (fitIndicationValue + 1) / 2;
    if (isTopGarment(garmentType)) {
        setNormalisedfitIndicationValueTop && setNormalisedfitIndicationValueTop(normalisedFitIndicationValue * 100);
    }
    if (isBottomGarment(garmentType)) {
        setNormalisedfitIndicationValueBottom && setNormalisedfitIndicationValueBottom(normalisedFitIndicationValue * 100);
    }
    console.log("fitIndicationValue ->", normalisedFitIndicationValue, garmentType);
    let fitScoreText = "Great Fit"
    if (fitScore >= 60 && fitScore < 80) {
        fitScoreText = normalisedFitIndicationValue > 0.5 ? "Slightly Loose Fit" : "Slightly Tight Fit";
    } else if (fitScore < 60) {
        fitScoreText = "Bad Fit"
    }

    // Show Overall score based of garment type

    setFitScoreText(fitScoreText);
    console.log("fitScore ---> ", fitScore);


    for (var i = 0; i < positionsArray.length; i += 3) {
        let vertexPosition = Vector3.FromArray(positionsArray, i);
        let mixedColor = defaultColor;

        if (isVertexWithinBodyArea(vertexPosition, "Chest")) {
            mixedColor = getMixedColorForBodyArea(defaultColor, chestColor, "Chest", vertexPosition, 1.5)
        }
        else if (isVertexWithinBodyArea(vertexPosition, "Armpits")) {
            mixedColor = getMixedColorForBodyArea(defaultColor, chestColor, "Armpits", vertexPosition, 1.5)
        }
        else if (isVertexWithinBodyArea(vertexPosition, "Shoulders")) {
            mixedColor = getMixedColorForBodyArea(defaultColor, chestColor, "Shoulders", vertexPosition, 1.5)
        }

        else if (isVertexWithinBodyArea(vertexPosition, "Waist") || isVertexWithinBodyArea(vertexPosition, "Torso")) {
            let secondColor = (isTopGarment(garmentType)) ? waistColor : highHipColor;
            mixedColor = getMixedColorForBodyArea(defaultColor, waistColor, "Torso", vertexPosition, 1.5)
            if (isVertexWithinBodyArea(vertexPosition, "Waist") && isVertexWithinBodyArea(vertexPosition, "Torso")) {
                mixedColor = mixColors(defaultColor, secondColor, 0.5) // getMixedColorForBodyArea(defaultColor, waistColor, "WaisTorso", vertexPosition, 1.5)
            }
            else if (isVertexWithinBodyArea(vertexPosition, "Waist")) {
                mixedColor = getMixedColorForBodyArea(defaultColor, secondColor, "Waist", vertexPosition, 1.5)
            }
            else if (isVertexWithinBodyArea(vertexPosition, "Torso")) {
                mixedColor = getMixedColorForBodyArea(defaultColor, waistColor, "Torso", vertexPosition, 1.5)
            }
        }
        // else if (isVertexWithinBodyArea(vertexPosition, "Waist")) {
        //   mixedColor = getMixedColorForBodyArea(defaultColor, waistColor, "Waist", vertexPosition, 1.5)
        // }
        // else if (isVertexWithinBodyArea(vertexPosition, "Torso")) {
        //   mixedColor = getMixedColorForBodyArea(defaultColor, waistColor, "Torso", vertexPosition, 1.5)
        // }
        else if (isVertexWithinBodyArea(vertexPosition, "LoveHandles")) {
            mixedColor = getMixedColorForBodyArea(defaultColor, waistColor, "LoveHandles", vertexPosition, 1.5)
        }
        else if (isVertexWithinBodyArea(vertexPosition, "Bums")) {
            if (isBottomGarment(garmentType)) {
                mixedColor = getMixedColorForBodyArea(defaultColor, hipColor, "Bums", vertexPosition, 1.5)
            } else {
                mixedColor = defaultColor;
            }
        }
        else {
            mixedColor = defaultColor // mixColors(HEATMAP_COLOR_CODES.normal, HEATMAP_COLOR_CODES.normal, 0)
        }
        colors.push(...mixedColor);
    }
    meshForHeatMap.setVerticesData(VertexBuffer.ColorKind, colors)

    showTextForHeatmap(scene, gender, chestMsg, waistMsg, hipMsg, highHipMsg, fitScoreText, garmentType)
}

const getConcernedBodyAreasForHeatMap = () => {
    const { Shoulders, Armpits, Waist, LoveHandles, Bums, Chest, Torso } = bodyPartsVertices;
    const defaultBodyAreas = [Chest, Shoulders, Armpits, Waist, LoveHandles, Torso, Bums];
    return defaultBodyAreas;
    // const { RECTANGLE, INV_TRIANGLE, TRIANGLE, APPLE, HOURGLASS } = BODY_TYPES;
    // const concernedBodyAreas = {
    //   [RECTANGLE]: [Shoulders, Armpits, Waist, LoveHandles, Bums],
    //   [INV_TRIANGLE]: [Chest, Shoulders, Armpits, Waist, Bums],
    //   [TRIANGLE]: [Waist, LoveHandles, Bums],
    //   [APPLE]: [Waist, LoveHandles, Bums],
    //   [HOURGLASS]: [Chest, Shoulders, Armpits, Waist, LoveHandles, Bums],
    // };
}


export const applyHeatMap = (scene, userProfile, product, heatmapSize, selectedProductPart, meshWithMaterialObj, timerId, avatarAssets, props) => {
    // clearTimeout(heatmapTimerRef.current);
    let { unisex, product_path, product_counter_part, avatar } = product;
    const { gender } = userProfile;
    if (unisex?.[gender]) {
        product_path = unisex?.[gender].product_path;
        product_counter_part = unisex?.[gender].product_counter_part;
        avatar = unisex?.[gender].avatar;
    }
    let productId = selectedProductPart;
    let otherGarmentType = product.garment_type === GARMENT_TYPE.TOP ? GARMENT_TYPE.BOTTOM : GARMENT_TYPE.TOP;
    let garmentType = product_path === selectedProductPart ? product.garment_type : otherGarmentType;
    // productId = product.garment_type === 'bottom' ? product.product_path : product.product_counter_part;
    const mergedName = `merged${garmentType}_${heatmapSize}`;
    let heatMapMat = scene.getMaterialByName(`heatMapMat_${heatmapSize}`);
    if (!heatMapMat) {
        heatMapMat = new PBRMetallicRoughnessMaterial(`heatMapMat_${heatmapSize}`, scene);
        heatMapMat.metallic = 0;
        heatMapMat.roughness = 1.0;
    }
    let clothClone = [];
    let meshName = `clothAsset_${productId}_${avatar}`;
    let positions = [];
    const meshWithMaterial = (meshName && meshWithMaterialObj[meshName]) || [];
    let mergedMeshes = scene.getMeshByName(mergedName);
    if (!mergedMeshes) {
        if (meshWithMaterial) {
            meshWithMaterial.forEach((mesh) => {
                let meshClone = mesh.clone()
                clothClone.push(meshClone)
                // mesh.setEnabled(false)
            })
        }
        if (!isEmpty(clothClone)) {
            mergedMeshes = Mesh.MergeMeshes(clothClone, true, true, false, true);
            mergedMeshes.name = mergedName;
            mergedMeshes.material = heatMapMat;
            mergedMeshes.renderingGroupId = 1;
        }
    }
    if (mergedMeshes) {
        positions = mergedMeshes.getVerticesData(VertexBuffer.PositionKind);
    }

    let concernedBodyAreas = getConcernedBodyAreasForHeatMap();

    // Step 5 - For each body area, get bounding box (using vertices IDs)
    for (let concernedBodyArea of concernedBodyAreas) {
        const { MIN_VALUE, MAX_VALUE } = Number;
        let minVertices = [MAX_VALUE, MAX_VALUE, MAX_VALUE]
        let maxVertices = [MIN_VALUE, MIN_VALUE, MIN_VALUE]
        for (let i = 0; i < concernedBodyArea.length; i += 1) {
            const vertexID = concernedBodyArea[i]
            const vertex = getVertexPositionFromID(vertexID, positions);
            minVertices[0] = Math.min(minVertices[0], vertex.x);
            minVertices[1] = Math.min(minVertices[1], vertex.y);
            minVertices[2] = Math.min(minVertices[2], vertex.z);
            maxVertices[0] = Math.max(maxVertices[0], vertex.x);
            maxVertices[1] = Math.max(maxVertices[1], vertex.y);
            maxVertices[2] = Math.max(maxVertices[2], vertex.z);
        }
        const key = getKeyByValue(bodyPartsVertices, concernedBodyArea)
        minVertices.forEach((value, index) => {
            minVertices[index] = hardLimitMin(value, BODY_VERTICES_LIMIT[gender][key].min[index]);
        });
        maxVertices.forEach((value, index) => {
            maxVertices[index] = hardLimitMax(value, BODY_VERTICES_LIMIT[gender][key].max[index]);
        });
        bodyAreasLimits[key].min = new Vector3(...minVertices);
        bodyAreasLimits[key].max = new Vector3(...maxVertices);
    }
    if (!isEmpty(positions) && !isEmpty(mergedMeshes)) {
        if (product.brand === "beinghuman" || product.brand === "beinghumans" || product.brand === "rarerabbit" || product.brand === "rarerism" || product.brand === "fashor" || product.brand === "superkicks" || product.brand === "fef" || product.brand === "burgerbae") {
            updateColorsBH(scene, positions, mergedMeshes, garmentType, userProfile, heatmapSize, selectedProductPart, avatarAssets, props);
        } else {
            updateColors(scene, positions, mergedMeshes, garmentType, userProfile, heatmapSize, avatarAssets, props);
        }
    }
    // Step 7 - Hide the original garment
    const meshesToDisable = scene.meshes.filter(mesh => {
        return (mesh.name.includes(`merged${garmentType}`));
    });
    meshesToDisable.forEach(mesh => mesh.setEnabled(false));
    scene.meshes.forEach(mesh => {
        if (mesh.name === mergedName) {
            mesh.setEnabled(true);
        }
    })
    timerId = setTimeout(() => {
        [...meshWithMaterial].forEach((mesh) => {
            mesh.setEnabled(false);
        });
        props.setHeatmapLoading(false);
    }, 200);
    // Step 8 - Render the wireframe over the merged mesh
    renderWireframeOver(mergedMeshes);
}